import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  font-size: 40px;
`;

const Detail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-top:-35vh;
`;

const Unauthorized = () => <div> <Wrap>Permission Denied!</Wrap> <Detail>Please add the user to a  group of Manager or Admin first</Detail> </div>;

export default Unauthorized;
