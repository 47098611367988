import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { subdivision } from "../../../api/endpoints";
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";
import Hoas from "../../../components/Hoas";
import HoaSearch from "../../HoaSearch";
import { useSnackbar } from 'notistack';

const { hoasGetAll } = subdivision;

const ListWrapper = styled.div`
  flex: 1;
  position: relative;
`;

const SearchWrapper = ListWrapper;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const HoaInfo = ({ subdivisionId, subdivisionState }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setItems([]);
    if (!subdivisionId) {
      return;
    }

    setLoading(true);
    try {
      const request = hoasGetAll(subdivisionId);
      (async () => {
        try {
          const response = await request;
          setItems(response.items);
        }
        catch (e) {
          enqueueSnackbar('Could not load Linked HOAs', { variant: 'error' })
        }
        setLoading(false);
      })();
      return () => { request.cancel(); setLoading(false) };
    }
    catch (e) {
      console.log(`Error : ${e}`);
      setLoading(false)
    }
  }, [subdivisionId]);

  const onAddHandler = (hoa) => {
    setItems([...items, hoa]);
  }

  const onDisconnect = (hoaId) => {
    setItems(items.filter(({ recordId }) => recordId !== hoaId));
  }

  return <Wrapper>
    <ListWrapper>
      {loading && <LoadingOverlay />}
      <Hoas items={items} ofSubdivision={subdivisionId} onDisconnect={onDisconnect} />
    </ListWrapper>
    <SearchWrapper>
      <HoaSearch
        defaultState={subdivisionState}
        forSubdivision={subdivisionId}
        onAdd={onAddHandler}
        hoasAttached={items}
      />
    </SearchWrapper>
  </Wrapper>;
}

export default HoaInfo;
