import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { trimStart } from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Contacts from "./Tabs/Contacts";
import HoaDetails from "./Tabs/HoaDetails";
import ManagementCompany from "./Tabs/ManagementCompany";
import OrderingInfo from "./Tabs/OrderingInfo";
import SubdivisionInfo from "./Tabs/SubdivisionInfo";
//latest add
import DocumentInfo from "./Tabs/DocumentInfo";
import CreateHoa from "./Tabs/CreateHoa";

const TabPanelWrapper = styled.div`
  height: 100%;
`;

const StyledBox = styled(Box)`
  height: 100%;
`;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <TabPanelWrapper role="tabpanel" hidden={value !== index} {...other}>
      <StyledBox>{children}</StyledBox>
    </TabPanelWrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;
const WrapperInternal = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
`;

const tabNames = [
  "hoa-details",
  "management-company",
  "subdivision-info",
  "contacts",
  "ordering-info",
  "documents",
  "review",
  "create-hoa",
];

const Content = ({
  hoa,
  location,
  onChangeHasChanges,
  onDelete,
  onUpdate,
  show,
  showFunction,
  ItemsList,
  isReview,
  totalItems,
  subdivisionMapping,
  isStandaloneCreate
}) => {
  const [tabNum, setTabNum] = useState(0);
  const [hasChangesMgmtCompany, setHasChangesMgmtCompany] = useState(false);
  const [hasChangesHoa, setHasChangesHoa] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  //latest add
  const [showTab, setShowTab] = useState();

  useEffect(() => {
    setShowTab(show);
    if (!show) {
      history.push(`${location.pathname}${location.search}#${tabNames[7]}`);
    } else {
      if (!subdivisionMapping) {
        history.push(`${location.pathname}${location.search}#${tabNames[0]}`);
      }
      else {
        history.push(`${location.pathname}${location.search}#subdivision-info`);
      }
    }
  }, [show]);

  useEffect(() => {
    onChangeHasChanges(hasChangesMgmtCompany || hasChangesHoa);
  }, [hasChangesMgmtCompany, hasChangesHoa]);

  useEffect(() => {
    if (!subdivisionMapping) {
      history.push(`${location.pathname}${location.search}#${tabNames[0]}`);
    }
    else {
      history.push(`${location.pathname}${location.search}#subdivision-info`);
    }
  }, [hasChangesHoa]);

  useEffect(() => {
    const tabName = trimStart(location.hash, "#");
    const tabIndex = tabNames.indexOf(tabName);
    setTabNum(tabIndex === -1 ? 0 : tabIndex);
  }, [location.hash]);

  const handleChange = (event, newValue) => {
    if (!hasChangesMgmtCompany && !hasChangesHoa) {
      history.push(
        `${location.pathname}${location.search}#${tabNames[newValue]}`
      );
    } else {
      enqueueSnackbar("You need to save or cancel the changes first.", {
        variant: "error",
      });
    }
  };

  const handleChangePrimaryContactHoa = (contactId) => {
    onUpdate({
      ...hoa,
      primaryContactId: contactId,
    });
  };

  const onUpdateMgmtCompany = (mgmtCompanyRecordId) => {
    onUpdate({
      ...hoa,
      mgmtCompanyId: mgmtCompanyRecordId,
    });
  };

  if (!hoa && !isStandaloneCreate) {
    return <>Please select an HOA {totalItems ? `out of the ${totalItems} HOAs found` : ''} </>;
  }
  if(isStandaloneCreate){
    return <></>;
  }
  return (
    <div class="wid_dep">
      <Wrapper>
        <WrapperInternal>
          <AppBar position="static">
            {tabNum == 0 || tabNum == 7 ? '' : <span style={{ color: "black", marginTop: -12, marginBottom: 10 }}>HOA Name: {hoa.name}</span>}
            <div class="tab_sty">
              <Tabs value={tabNum} onChange={handleChange}>
                {/* <Tab label="Hoa Details" />
              <Tab label="Management Company" />
              <Tab label="Subdivision Info" />
              <Tab label="Contacts" />
              <Tab label="Ordering Info" /> */}

                {/* latest add */}
                {showTab ? <Tab label="Hoa Details" /> : null}
                {showTab ? <Tab label="Management Company" /> : null}
                {showTab ? <Tab label="Subdivision Info" /> : null}
                {showTab ? <Tab label="Contacts" /> : null}
                {showTab ? <Tab label="Ordering Info" /> : null}
                {showTab ? <Tab label="Documents" /> : null}
                {showTab && isReview ? <Tab label="Review" /> : null}
                {!showTab ? <Tab disabled={true} label="Create HOA" /> : null}

              </Tabs>
            </div>
          </AppBar>
          <ContentWrapper>
            <TabPanel
              style={{ display: showTab ? null : "none" }}
              value={tabNum}
              index={0}
            >
              <HoaDetails
                {...hoa}
                onChangeHasChanges={setHasChangesHoa}
                onDelete={onDelete}
                onUpdate={onUpdate}
              />
            </TabPanel>
            <TabPanel
              style={{ display: showTab ? null : "none" }}
              value={tabNum}
              index={1}
            >
              <ManagementCompany
                hoaRecordId={hoa.recordId}
                mgmtCompanyRecordId={hoa.mgmtCompanyId}
                onChangeHasChanges={setHasChangesMgmtCompany}
                onUpdate={onUpdateMgmtCompany}
              />
            </TabPanel>
            <TabPanel
              style={{ display: showTab ? null : "none" }}
              value={tabNum}
              index={2}
            >
              <SubdivisionInfo
                hoaRecordId={hoa.recordId}
                hoaState={hoa.state}
              />
            </TabPanel>
            <TabPanel
              style={{ display: showTab ? null : "none" }}
              value={tabNum}
              index={3}
            >
              <Contacts
                hoaPrimaryContactId={hoa.primaryContactId}
                hoaRecordId={hoa.recordId}
                mgmtCompanyRecordId={hoa.mgmtCompanyId}
                onChangePrimaryHoa={handleChangePrimaryContactHoa}
              />
            </TabPanel>
            <TabPanel
              style={{ display: showTab ? null : "none" }}
              value={tabNum}
              index={4}
            >
              <OrderingInfo
                hoaRecordId={hoa.recordId}
                mgmtCompanyRecordId={hoa.mgmtCompanyId}
              />
            </TabPanel>
            <TabPanel
              style={{ display: showTab ? null : "none" }}
              value={tabNum}
              index={5}
            >
              <DocumentInfo hoaRecordId={hoa.recordId} />
            </TabPanel>
            <TabPanel
              style={{ display: !isReview ? "none" : null }}
              value={tabNum}
              index={6}
            >
              <CreateHoa
                {...hoa}
                onChangeHasChanges={setHasChangesHoa}
                onDelete={onDelete}
                onUpdate={onUpdate}
                changeShow={(res) => showFunction(res)}
                hoaItemsList={(res) => ItemsList(res)}
              />
            </TabPanel>
            <TabPanel
              style={{ display: showTab ? "none" : null }}
              value={tabNum}
              index={7}
            >
              <CreateHoa
                {...hoa}
                onChangeHasChanges={setHasChangesHoa}
                onDelete={onDelete}
                onUpdate={onUpdate}
                changeShow={(res) => showFunction(res)}
                hoaItemsList={(res) => ItemsList(res)}
              />
            </TabPanel>
          </ContentWrapper>
        </WrapperInternal>
      </Wrapper>
    </div>
  );
};

export default withRouter(Content);
