import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@material-ui/core';
import { useSnackbar } from "notistack";
import React, { useState } from 'react';
import { del as delSub } from "../api/endpoints/subdivision";
import LoadingOverlay from "./Loading/LoadingOverlay";

const SubDeleteDialog = ({ recordId, name, onClose, open }) => {
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    setText(event.target.value);
  }

  const handleOk = async () => {
    setLoading(true);

    try {
      await delSub(recordId);
      enqueueSnackbar('The Subdivision has been deleted', { variant: 'success' });
      setText('');
      onClose(true);
    } catch (e) {
      const message = e.response.data.message;
      enqueueSnackbar(`Error while deleting the Subdivision: ${message ?? 'Unknown error'}`, { variant: 'error' });
    }

    setLoading(false);
  }

  const handleCancel = () => {
    onClose();
  }

  const onDialogClose = () => {
    if (loading) {
      enqueueSnackbar('Wait the action to be completed', { variant: 'warning' });
    } else {
      onClose();
    }
  }

  return (
    <Dialog onClose={onDialogClose} open={open}>
      {loading && <LoadingOverlay />}
      <DialogTitle>Confirm the action</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Deleting Subdivision cannot be undone!<br />
          To confirm deletion, enter the next text in the input field. <br />
          <em>delete</em>
        </DialogContentText>
        <TextField onChange={handleChange} value={text} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button color="secondary" disabled={text !== 'delete'} onClick={handleOk}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
}

export default SubDeleteDialog;
