import React from 'react';
import { hoa } from "../../api/endpoints";
import Autocomplete from "../../components/Autocomplete";

const { getHoas, get: getHoa } = hoa;

const SelectorHoa = ({ className, disabled = false, onChange, value, type }) => {
  const autocompleteGetter = ({ text, value }) => {
    let request;
    let result;
    if (typeof text !== 'undefined') {
      request = getHoas({ filter: { name: text } });
      result = request.then(({ items }) => items.map(item => ({ label: item.name, value: item.recordId })));
    } else {
      request = getHoa(value);
      result = request.then((item) => [{ label: item.name, value: item.recordId }]);
    }

    const { cancel } = request;
    result.cancel = cancel;

    return result;
  }

  return <Autocomplete
    className={className}
    disabled={disabled}
    getter={autocompleteGetter}
    label={`${type} HOA`}
    onChange={onChange}
    value={value}
  />
}

export default SelectorHoa;
