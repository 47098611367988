import React, { useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const Item = ({ id, isSelected, name, address, state, city, onClick }) => {
  // const [secondary, setSecondary] = useState();
  // const useEffect = (() => {
  let Secondary = address ? address.toUpperCase() : '';
  Secondary += city ? ` ${city.toUpperCase()}` : '';
  Secondary += state ? ` ${state.toUpperCase()}` : '';
  // }, []);

  return (
    <div class="min_li">
      <ListItem button selected={isSelected} key={id} onClick={onClick}>
        <ListItemText primary={name.toUpperCase()} secondary={Secondary} />
      </ListItem>
    </div>
  );
}

export default Item;
