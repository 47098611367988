import { Button, Icon } from '@material-ui/core';
import React, { useState } from 'react';
import styled from "styled-components";
import OrderingInfoDialog from "../containers/OrderingInfoDialog";

const Wrapper = styled.div`
  display: flex;
`;

const OrderingInfoAddEditButton = ({ entity, linkedRecordId, type, onAdd, onEdit }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (entity) => {
    if (entity) {
      onAdd && onAdd(entity);
      onEdit && onEdit(entity);
    }
    setOpen(false);
  };

  const buttonContent = entity
    ? <Icon>create</Icon>
    : <><Icon>add_circle</Icon>Add</>;

  return (
    <Wrapper>
      <Button color="default" onClick={handleClickOpen}>{buttonContent}</Button>
      <OrderingInfoDialog entity={entity} linkedRecordId={linkedRecordId} onClose={handleClose} open={open} type={type} />
    </Wrapper>
  );
}

export default OrderingInfoAddEditButton;
