import { trimStart } from 'lodash';
import qs from 'qs';
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import config from "../../config";
import { STATES, FIPS, ROUTES } from "../../constants";
import Button from "@material-ui/core/Button";

const { autoLoadingHoasOnLoad } = config;
const { SUB_MASTER } = ROUTES;
const STATE_ANY = 'ANY';
const STATE_UNKNOWN = 'UNKNOWN';

const Filter = ({ location, onSubmit, totalItems }) => {
  const history = useHistory();

  const [fips, setFips] = useState('');
  const [initialized, setInitialized] = useState(false);
  const [name, setName] = useState('');
  const [state, setState] = useState('');

  useEffect(() => {
    const searchParameters = qs.parse(trimStart(location.search, '?'));
    syncStateWithProps();
    if (
      !initialized
      && Object.keys(searchParameters).length === 0
      && !autoLoadingHoasOnLoad
    ) {
      return;
    }

    onSubmit(searchParameters);
  }, [location.search]);

  useEffect(() => {
    setInitialized(true);
  }, []);

  const syncStateWithProps = () => {
    const {
      name: initialName,
      state: initialState,
      fips: initialFips,
    } = qs.parse(trimStart(location.search, '?'));

    // setAddress(initialAddress);
    setName(initialName === undefined ? '' : initialName);
    setState(initialState === '' ? STATE_UNKNOWN : (initialState ? initialState : STATE_ANY));
    setFips(initialFips);
  }

  const handleFipsChange = event => {
    setFips(event.target.value);
  }
  const handleNameChange = event => {
    setName(event.target.value);
  }
  const handleStateChange = event => {
    setState(event.target.value);
  }
  const handleKeyUp = event => {
    if (event.key === 'Enter') {
      handleOnClick();
    }
  }

  const handleOnClick = () => {
    let stateFilter;
    switch (state) {
      case STATE_ANY:
        stateFilter = undefined;
        break;
      case STATE_UNKNOWN:
        stateFilter = '';
        break;
      default:
        stateFilter = state;
    }

    const stringParameters = qs.stringify({
      name: name || undefined,
      state: stateFilter,
      fips: fips || undefined,
    });

    history.push(`${SUB_MASTER}?${stringParameters}${location.hash}`);
  }
  const CountyList = Object.keys(FIPS).map(function (key) {
    if (state === FIPS[key].state)
      return <MenuItem key={key} value={key}>{FIPS[key].county}</MenuItem>
  });

  return <div>
    <div class="wht_box" style={{ paddingBottom: totalItems ? '10px' : 20 }}>
      <h4 class="hd_box">Subdivision Master</h4>
      <div class="form_item_fr">
        <div class="mb-2">
          <TextField size="small" fullWidth label="Subdivision Name" variant="outlined" value={name} onChange={handleNameChange} onKeyUp={handleKeyUp} />
        </div>
      </div>
      <div class="form_item_fr flex">
        <div class="btn-group dropup tw">
          <FormControl variant="outlined" size="small">
            <InputLabel>State</InputLabel>
            <Select
              value={state}
              onChange={handleStateChange}
            >
              <MenuItem value={STATE_ANY}>
                <em>-State-</em>
              </MenuItem>
              <MenuItem value={STATE_UNKNOWN}>
                <em>-UNKNOWN-</em>
              </MenuItem>
              {STATES.map(({ code, label }) => <MenuItem key={code} value={code}>{code}</MenuItem>)}
            </Select>
          </FormControl>
        </div>
        <div class="form_item_fr trd">
          {/* <TextField size="small" label="FIPS Code" variant="outlined" value={fips} onChange={handleFipsChange} onKeyUp={handleKeyUp} /> */}
          <FormControl variant="outlined" size="small">
            <InputLabel>County</InputLabel>
            <Select
              value={fips}
              onChange={handleFipsChange}
            >
              <MenuItem value={''}>
                <em>-County-</em>
              </MenuItem>
              {state === STATE_ANY ?
                <MenuItem value={''}>
                  <em>Select A State First</em>
                </MenuItem>
                : <></>
              }
              {CountyList}
            </Select>
          </FormControl>
        </div>
      </div>
      <div class="btn_box">
        <Button variant="contained" color="primary" onClick={handleOnClick}>
          Search
        </Button>
      </div>
      {totalItems ? <div className="form_item_fr">
        <div className="mb-2" style={{ padding: 0, color: '#2c4773', margin: 0, textAlign: 'center' }}>
          {totalItems} results found
        </div>
      </div> : ''}
    </div>
  </div>
}

export default withRouter(Filter);
