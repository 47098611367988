import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ROUTES } from "../../constants";
import logo from '../../../src/images/logo.png';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../index.css';

const { HOA_MASTER, SUB_MASTER, MANAGEMENT, HOAID } = ROUTES;

const Wrapper = styled.div`
  width:75% !important;
`;


const Menu = () => {
  const [hoaActive, setHoaActive] = useState('active');
  const [subActive, setSubActive] = useState('');
  const [mgmtActive, setMgmtActive] = useState('');
  const [hoaIdActive, setHoaIdActive] = useState('');

  function changeActiveToHoa() {
    setHoaActive('active');
    setSubActive('');
    setMgmtActive('');
    setHoaIdActive('');
  }
  function changeActiveToSub() {
    setHoaActive('');
    setSubActive('active');
    setMgmtActive('');
    setHoaIdActive('');
  }
  function changeActiveToMgmt() {
    setHoaActive('');
    setSubActive('');
    setMgmtActive('active');
    setHoaIdActive('');
  }
  function changeActiveToHoaId() {
    setHoaActive('');
    setSubActive('');
    setMgmtActive('');
    setHoaIdActive('active');
  }
  return (
    <Wrapper>
      <div className="bg_blue">
        <div className="container">
          <div className="row hd">
            <div className="col-md-3">
              <Link to={HOA_MASTER}>
                <div className="logo_hd">
                  <img src={logo} alt="logo" />
                </div>
              </Link>
            </div>
            <>
              <div className="col-md-9 hd_l">
                <div className="hd_menu">
                  <ul>
                    <li><Link to={HOA_MASTER} onClick={changeActiveToHoa} class={hoaActive}>HOA Master</Link></li>
                    <li><Link to={SUB_MASTER} onClick={changeActiveToSub} class={subActive}>Subdivision Master</Link></li>
                    <li><Link to={MANAGEMENT} onClick={changeActiveToMgmt} class={mgmtActive}>Management Companies</Link></li>
                    <li><Link to={HOAID} onClick={changeActiveToHoaId} class={hoaIdActive}>HOAID APIs</Link></li>
                  </ul>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </Wrapper>

  );
}


export default Menu;
