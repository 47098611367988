import { Button, Icon } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from 'react';
import styled from "styled-components";
import { hoa } from "../../api";
import LoadingOverlay from "../Loading/LoadingOverlay";

const { downloadDocument } = hoa;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ViewDocButtons = ({ OnView, documentId }) => {
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  // const handleOnDisconnect = async () => {
  //   setLoading(true);

  //   try {
  //     await deleteDocument(documentId);
  //     onDisconnect();
  //   } catch (e) {
  //     console.error(e);
  //     const message = e.response.data.message;
  //     enqueueSnackbar(`Error while deleting the document: ${ message ?? 'Unknown error' }`, { variant: 'error' });
  //   }

  //   setLoading(false);
  // }

  const handleOnViewDocument = async () => {
    setLoading(true);

    try {
      const documentData = await downloadDocument(documentId);
      const downloadedFile = JSON.parse(documentData)
      
      try {
        var a = document.createElement("a");
        a.download = downloadedFile.fileName;
        a.href = downloadedFile.link;
        a.click(); 
      } catch (error) {
        enqueueSnackbar('Error while downloading the file', { variant: 'error' });
      }

    } catch (e) {
      console.error(e);
      const message = e.response.data.message;
      enqueueSnackbar(`Error while deleting the document: ${message ?? 'Unknown error'}`, { variant: 'error' });
    }

    setLoading(false);
  }

  return <Wrapper>
    {loading && <LoadingOverlay />}
    <Button color="primary" onClick={handleOnViewDocument}>
      <Icon>download</Icon>
    </Button>
  </Wrapper>
}

export default ViewDocButtons;
