import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../utils/auth';

const LogoutCallback = () => {
  const { logoutCallback } = useAuth();

  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    logoutCallback();

    const interval = setInterval(() => setSeconds((sec) => sec - 1), 1000);

    return () => {
      clearInterval(interval);
    }
  }, []);

  if (seconds <= 0) {
    return <Redirect to='/' />;
  }

  return <div>You successfully logged out, redirect in {seconds}...</div>;
}

export default LogoutCallback;
