import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { hoa, mgmtCompany } from "../api/endpoints";
import ContactAddEditButton from "../components/ContactAddEditButton";
import ContactDeleteButton from "../components/ContactDeleteButton";
import LoadingOverlay from "../components/Loading/LoadingOverlay";
import Overlay from "../components/Overlay";
import { CONTACT_TYPES } from "../constants";
import GridCellExpand from "../components/GridCellExpand";
import PropTypes from 'prop-types';

const { get: getHoa, getContacts: hoaGetContacts, patch: hoaPatch } = hoa;
const { get: getMgmtCompany, getContacts: mgmtCompanyGetContacts, patch: mgmtCompanyPatch } = mgmtCompany;

const Wrapper = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionCell = styled.div`
  display: flex;
  flex-direction: row;
`;

const IsPrimaryCell = styled.div`
  position: relative;
  width: 100%;
`;

const Contacts = ({ className, onChangePrimary, primaryContactId, recordId, type }) => {
  function renderCellExpand(params) {
    return (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
  }
  renderCellExpand.propTypes = {
    colDef: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
  };
  const columns = [
    {
      field: 'isPrimary',
      headerName: 'Primary',
      renderCell: ({ row }) => {
        let content;
        if (row.recordId === primaryContactId) {
          content = 'Yes';
        } else {
          content = <Button onClick={setPrimary(row.recordId)} variant="outlined">Set</Button>;
        }
        return <IsPrimaryCell>
          {loadingPrimary && <LoadingOverlay />}
          {content}
        </IsPrimaryCell>
      },
      filterable: false,
      width: 130,
    },
    {
      field: 'name',
      headerName: 'Contact Name',
      width: 180,
      filterable: false,
      renderCell: renderCellExpand,
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 180,
      filterable: false,
      renderCell: renderCellExpand,
    },
    {
      field: 'phonePrimary',
      headerName: 'Primary Phone',
      filterable: false,
      width: 150,
    },
    {
      field: 'phoneSecondary',
      headerName: 'Secondary Phone',
      filterable: false,
      width: 150,
    },
    {
      field: 'fax',
      headerName: 'Fax',
      filterable: false,
      width: 150,
    },
    {
      field: 'address',
      headerName: 'Mailing address',
      width: 150,
      filterable: false,
      renderCell: renderCellExpand,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
      filterable: false,
      renderCell: renderCellExpand,
    },
    {
      field: 'website',
      headerName: 'Website',
      width: 150,
      filterable: false,
      renderCell: renderCellExpand,
    },
    {
      field: 'action',
      headerName: 'Action',
      renderCell: ({ row }) => <ActionCell>
        <ContactAddEditButton entity={row} linkedRecordId={recordId} onEdit={onEdit} type={type} />
        <ContactDeleteButton
          contactId={row.recordId}
          linkedRecordId={recordId}
          onDelete={onDelete(row.recordId, recordId)}
          type={type}
        />
      </ActionCell>,
      filterable: false,
      width: 150,
    },
  ];

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPrimary, setLoadingPrimary] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!recordId || !type) {
      setItems([]);
      return;
    }

    setLoading(true);
    setItems([]);
    let request;
    if (type === CONTACT_TYPES.hoa) {
      request = hoaGetContacts(recordId);
    } else {
      request = mgmtCompanyGetContacts(recordId);
    }
    (async () => {
      try {
        const response = await request;
        setItems(response.items);
        setLoading(false);
      }
      catch (e) {
        setLoading(false);
      }
    })();
    return () => { request.cancel(); setLoading(false) };
  }, [recordId, type]);

  useEffect(() => {
    if (items.length !== 1) {
      return;
    }

    (async () => {
      setLoadingPrimary(true);
      let linkedEntity;
      try {
        if (type === CONTACT_TYPES.hoa) {
          linkedEntity = await hoaPatch(recordId, { primaryContactId: items[0].recordId });
        } else {
          linkedEntity = await mgmtCompanyPatch(recordId, { primaryContactId: items[0].recordId });
        }
        if (linkedEntity.primaryContactId === items[0].recordId) {
          onChangePrimary(items[0].recordId);
        }
      }
      catch (e) { }
      setLoadingPrimary(false);
    })();

  }, [items])

  const setPrimary = contactId => async () => {
    try {
      setLoadingPrimary(true);

      let linkedEntity;
      if (type === CONTACT_TYPES.hoa) {
        linkedEntity = await hoaPatch(recordId, { primaryContactId: contactId });
      } else {
        linkedEntity = await mgmtCompanyPatch(recordId, { primaryContactId: contactId });
      }
      if (linkedEntity.primaryContactId === contactId) {
        onChangePrimary(contactId);
      }
      enqueueSnackbar('The primary contact has been changed.', { variant: 'success' });

      setLoadingPrimary(false);
    } catch (e) {
      setLoadingPrimary(false);
      console.error(e);
      const message = e.response.data.message;
      enqueueSnackbar(`Error while setting the contact to primary: ${message ?? 'Unknown error'}`, { variant: 'error' });
    }
  }

  const onDelete = (contactId, linkedRecordId) => () => {
    setItems(items.filter((item) => item.recordId !== contactId));

  }

  const onAdd = async (entity) => {
    setItems([...items, entity]);

    // If it's a first contact created it should be primary, so we're fetching a fresh data from the backend.
    if (items.length === 0) {
      setLoadingPrimary(true);
      if (type === CONTACT_TYPES.hoa) {
        const { primaryContactId: updatedPrimaryContactId } = await getHoa(recordId);
        onChangePrimary(updatedPrimaryContactId);
      } else {
        const { primaryContactId: updatedPrimaryContactId } = await getMgmtCompany(recordId);
        onChangePrimary(updatedPrimaryContactId);
      }
      setLoadingPrimary(false);
    }
  }

  const onEdit = (entity) => {
    setItems(items.map(item => {
      if (entity.recordId === item.recordId) {
        return entity;
      }
      return item;
    }));
  }

  return <Wrapper className={className}>
    <div class="set4_tab">
      {loading && <LoadingOverlay />}
      {!recordId && <Overlay text={`The ${type === CONTACT_TYPES.hoa ? 'HOA' : 'Management Company'} is not set.`} />}
      <div class="wte_down">
        <HeaderWrapper>
          <h3 class="hd_tg">{type === CONTACT_TYPES.hoa ? 'HOA' : 'Management Company'} Contacts</h3>
          <ContactAddEditButton onAdd={onAdd} linkedRecordId={recordId} type={type} />
        </HeaderWrapper>
        <div style={{ height: '400px', width: '100%' }}>
          {/* items.map - brute fix to keep the ID for the DataGrid component. */}
          <DataGrid rows={items.map((record) => ({ id: record.recordId, ...record }))} columns={columns} rowsPerPageOptions={[]} />
        </div>
      </div>
    </div>
  </Wrapper>;
}

export default Contacts;
