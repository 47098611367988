import React from 'react';
import styled from 'styled-components';
import Header from './Header';

const MainWrap = styled.section`
  display: flex;
  flex-direction: column;
  height: auto; 
  margin: 0;
`;

const Main = styled.main`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Layout = ({ children }) => (
  <MainWrap>
    <Header />
    <Main>{ children }</Main>
  </MainWrap>
);

export default Layout;
