import jwt from 'jsonwebtoken';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import { getTokensByAccessCode, getTokensByRefreshToken } from "../api/endpoints/auth";
import config from '../config';
import {
  LOCAL_STORAGE_KEY_AUTH_TOKEN_ACCESS,
  LOCAL_STORAGE_KEY_AUTH_TOKEN_ID,
  LOCAL_STORAGE_KEY_AUTH_TOKEN_REFRESH,
  LOCAL_STORAGE_KEY_AUTH_TOKEN_REFRESH_EXPIRES_AT,
  LOCAL_STORAGE_KEY_REDIRECT_PATH_AFTER_LOGIN,
} from '../constants';

const {
  auth: {
    authCallbackUri,
    baseUrl,
    clientId,
    logoutCallbackUri,
    refreshTokenLifetime,
    refreshTokensBeforeExpiration,
  }
} = config;

const AuthContext = React.createContext();

const useAuth = () => useContext(AuthContext);

const LOG_OUT_IN_ADVANCE_BEFORE_TOKEN_EXPIRED = 15;
const WARNING_IN_ADVANCE_BEFORE_TOKEN_EXPIRED = 60 * 15;

const AuthProvider = ({ children }) => {
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [initialization, setInitialization] = useState(true);
  const [initialRefreshingToken, setInitialRefreshingToken] = useState(false);
  const [userData, setUserData] = useState({});
  const [tokenAccess, setTokenAccess] = useState();
  const [tokenExpiresAt, setTokenExpiresAt] = useState();
  const [tokenId, setTokenId] = useState();
  const [tokenRefresh, setTokenRefresh] = useState();
  const [tokenRefreshExpiresAt, setTokenRefreshExpiresAt] = useState();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!tokenRefreshExpiresAt) {
      return;
    }
    const currentTime = Math.floor(new Date() / 1000);
    const tokenExpiresIn = tokenRefreshExpiresAt - currentTime > 0 ? tokenRefreshExpiresAt - currentTime : 0;

    let timeoutIdWarning;
    // We should not show a warning if we're logging out immediately after it.
    if (tokenExpiresIn - LOG_OUT_IN_ADVANCE_BEFORE_TOKEN_EXPIRED > 0) {
      timeoutIdWarning = setTimeout(() => {
        enqueueSnackbar(`Your authorization will expire in about ${Math.floor(tokenExpiresIn / 60)} minutes. Please save your current state and Log Out and Log In again.`, { variant: 'warning' });
      }, (tokenExpiresIn - WARNING_IN_ADVANCE_BEFORE_TOKEN_EXPIRED) * 1000);
    }
    const timeoutIdLoggingOut = setTimeout(() => {
      enqueueSnackbar(`Your authorization has been expired. You will be logged out in 5 seconds.`, { variant: 'warning' });
      setTimeout(logout, 5 * 1000);
    }, (tokenExpiresIn - LOG_OUT_IN_ADVANCE_BEFORE_TOKEN_EXPIRED) * 1000);

    return () => {
      timeoutIdWarning && clearTimeout(timeoutIdWarning);
      clearTimeout(timeoutIdLoggingOut);
    }
  }, [tokenRefreshExpiresAt]);

  useEffect(() => {
    if (!tokenAccess || !tokenId) {
      setTokenExpiresAt(null);
      return;
    }

    const tokenAccessPayload = jwt.decode(tokenAccess);
    const tokenIdPayload = jwt.decode(tokenId);
    const minExpirationTime = Math.min(tokenAccessPayload.exp, tokenIdPayload.exp);
    setTokenExpiresAt(minExpirationTime);
  }, [tokenAccess, tokenId])

  useEffect(() => {
    if (!tokenExpiresAt) {
      return;
    }
    let runRefreshProcedureIn = tokenExpiresAt - Math.round(new Date() / 1000) - refreshTokensBeforeExpiration;

    // It's possible on initialization, in this case we should show we're still loading.
    if (runRefreshProcedureIn < 0) {
      runRefreshProcedureIn = 0;
      setInitialRefreshingToken(true);
    } else {
      setInitialRefreshingToken(false);
    }

    const timeoutId = setTimeout(refreshTokensByRefreshToken, runRefreshProcedureIn * 1000);

    return () => clearTimeout(timeoutId);
  }, [tokenExpiresAt]);

  useEffect(() => {
    (async () => {
      if (tokenId) {
        const jwtPayload = await jwt.decode(tokenId);
        setUserData({
          // @todo Handle a case with multiple groups.
          group: jwtPayload['cognito:groups'] ? jwtPayload['cognito:groups'][0] : '',
          username: jwtPayload['cognito:username'],
        });
        setIsAuthenticated(true);
        setInitialization(false);
      } else {
        setUserData({});
        setIsAuthenticated(false);
      }
    })();
  }, [tokenId]);

  const init = () => {
    const storedAuthTokenAccess = localStorage.getItem(LOCAL_STORAGE_KEY_AUTH_TOKEN_ACCESS);
    const storedAuthTokenId = localStorage.getItem(LOCAL_STORAGE_KEY_AUTH_TOKEN_ID);
    const storedAuthTokenRefresh = localStorage.getItem(LOCAL_STORAGE_KEY_AUTH_TOKEN_REFRESH);
    const storedAuthTokenRefreshExpiresAt = Number(localStorage.getItem(LOCAL_STORAGE_KEY_AUTH_TOKEN_REFRESH_EXPIRES_AT));
    if (storedAuthTokenAccess) {
      setTokenAccess(storedAuthTokenAccess);
      setTokenId(storedAuthTokenId);
      setTokenRefresh(storedAuthTokenRefresh);
      setTokenRefreshExpiresAt(storedAuthTokenRefreshExpiresAt);
    } else {
      setInitialization(false);
    }
  }

  const login = async (redirect = '/') => {
    localStorage.setItem(LOCAL_STORAGE_KEY_REDIRECT_PATH_AFTER_LOGIN, redirect);
    window.location.href = `${baseUrl}/login?client_id=${clientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${authCallbackUri}`;
  }

  const logout = async () => {
    setTimeout(() => window.location.href = `${baseUrl}/logout?client_id=${clientId}&logout_uri=${logoutCallbackUri}`, 0);
  };

  const clearStateAndStorage = () => {
    setTokenAccess(null);
    localStorage.removeItem(LOCAL_STORAGE_KEY_AUTH_TOKEN_ACCESS);
    setTokenId(null);
    localStorage.removeItem(LOCAL_STORAGE_KEY_AUTH_TOKEN_ID);
    setTokenRefresh(null);
    localStorage.removeItem(LOCAL_STORAGE_KEY_AUTH_TOKEN_REFRESH);
    localStorage.removeItem(LOCAL_STORAGE_KEY_AUTH_TOKEN_REFRESH_EXPIRES_AT);
    setTokenRefreshExpiresAt(null);
  }

  const logoutCallback = () => {
    clearStateAndStorage();
  }

  const refreshTokensByRefreshToken = async () => {
    try {
      const {
        accessToken: newAccessToken,
        idToken: newIdToken,
      } = await getTokensByRefreshToken(tokenRefresh);

      setTokenAccess(newAccessToken);
      localStorage.setItem(LOCAL_STORAGE_KEY_AUTH_TOKEN_ACCESS, newAccessToken);
      setTokenId(newIdToken);
      localStorage.setItem(LOCAL_STORAGE_KEY_AUTH_TOKEN_ID, newIdToken);
    } catch (e) {
      clearStateAndStorage();
      console.error({ e });
      enqueueSnackbar(`Error while refreshing your authorization.`, { variant: 'error' });
    }
  }

  const authCallback = async (code) => {
    try {
      const {
        accessToken: newAccessToken,
        idToken: newIdToken,
        refreshToken: newRefreshToken,
      } = await getTokensByAccessCode(code);

      setTokenAccess(newAccessToken);
      localStorage.setItem(LOCAL_STORAGE_KEY_AUTH_TOKEN_ACCESS, newAccessToken);
      setTokenId(newIdToken);
      localStorage.setItem(LOCAL_STORAGE_KEY_AUTH_TOKEN_ID, newIdToken);
      setTokenRefresh(newRefreshToken);
      localStorage.setItem(LOCAL_STORAGE_KEY_AUTH_TOKEN_REFRESH, newRefreshToken);
      const newTokenRefreshExpiresAt = Math.round(new Date() / 1000) + refreshTokenLifetime;
      localStorage.setItem(LOCAL_STORAGE_KEY_AUTH_TOKEN_REFRESH_EXPIRES_AT, String(newTokenRefreshExpiresAt));
      setTokenRefreshExpiresAt(newTokenRefreshExpiresAt);
    } catch (e) {
      clearStateAndStorage();
      console.error({ e });
      enqueueSnackbar(`Error while authorization.`, { variant: 'error' });
    }

    let redirect = localStorage.getItem(LOCAL_STORAGE_KEY_REDIRECT_PATH_AFTER_LOGIN);
    localStorage.removeItem(LOCAL_STORAGE_KEY_REDIRECT_PATH_AFTER_LOGIN);
    if (!redirect) {
      redirect = '/';
    }
    setTimeout(() => history.push(redirect), 0);
  }

  return (
    <AuthContext.Provider
      value={{
        init,
        isAuthenticated,
        loading: initialization || initialRefreshingToken,
        login,
        logout,
        logoutCallback,
        userData,
        tokenAccess,
        tokenId,
        authCallback,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuth };
