import { useSnackbar } from "notistack";
import { trimStart } from 'lodash';
import qs from 'qs';
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from '@material-ui/core/Checkbox';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import config from "../../config";
import { STATES, ROUTES } from "../../constants";
import Button from "@material-ui/core/Button";

const { autoLoadingHoasOnLoad } = config;
const { HOA_MASTER } = ROUTES;
const STATE_ANY = 'ANY';
const STATE_UNKNOWN = 'UNKNOWN';

const Filter = ({ location, onSubmit, hasChanges, totalItems, onChangeMode, subMapping }) => {
  const history = useHistory();

  const [address, setAddress] = useState('');
  const [initialized, setInitialized] = useState(false);
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [subdivisionMapping, setSubdivisionMapping] = useState(subMapping);

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    const searchParameters = qs.parse(trimStart(location.search, '?'));
    syncStateWithProps();

    if (
      !initialized
      && Object.keys(searchParameters).length === 0
      && !autoLoadingHoasOnLoad
    ) {
      return;
    }

    onSubmit(searchParameters);
  }, [location.search]);

  useEffect(() => {
    setInitialized(true);
  }, []);

  const syncStateWithProps = () => {
    const {
      address: initialAddress,
      name: initialName,
      city: initialCity,
      state: initialState,
      zip: initialZip,
    } = qs.parse(trimStart(location.search, '?'));

    setAddress(initialAddress === undefined ? '' : initialAddress);
    setName(initialName === undefined ? '' : initialName);
    setCity(initialCity === undefined ? undefined : initialCity);
    setState(initialState === '' ? STATE_UNKNOWN : (initialState ? initialState : STATE_ANY));
    setZip(initialZip === undefined ? '' : initialZip);
  }

  const handleAddressChange = event => {
    setAddress(event.target.value);
  }
  const handleNameChange = event => {
    setName(event.target.value);
  }
  const handleCityChange = event => {
    setCity(event.target.value);
  }
  const handleStateChange = event => {
    setState(event.target.value);
  }
  const handleZipChange = event => {
    setZip(event.target.value);
  }
  const handleKeyUp = event => {
    if (event.key === 'Enter') {
      handleOnClick();
    }
  }

  const handleOnClick = () => {
    if (!hasChanges) {
      let stateFilter;
      switch (state) {
        case STATE_ANY:
          stateFilter = undefined;
          break;
        case STATE_UNKNOWN:
          stateFilter = '';
          break;
        default:
          stateFilter = state;
      }

      const stringParameters = qs.stringify({
        address: address || undefined,
        name: name || undefined,
        city: city || undefined,
        state: stateFilter,
        zip: zip || undefined,
      });
      if (!subdivisionMapping) {
        history.push(`${HOA_MASTER}?${stringParameters}${location.hash}`);
      } else {
        history.push(`${HOA_MASTER}?${stringParameters}#subdivision-info`);
      }
    }
    else {
      enqueueSnackbar('You need to save or cancel the changes first.', { variant: 'error' });
    }
  }

  return <div>
    <div className="wht_box" style={{ paddingBottom: totalItems ? '10px' : 20 }}>
      <h4 className="hd_box">Search HOA Master</h4>
      <div className="form_item_fr">
        <div className="mb-2">
          <TextField size="small" fullWidth label="Name" variant="outlined" value={name} onChange={handleNameChange} onKeyUp={handleKeyUp} />
        </div>
      </div>
      <div className="form_item_fr">
        <div className="form-group">
          <TextField size="small" fullWidth label="Address" variant="outlined" value={address} onChange={handleAddressChange} onKeyUp={handleKeyUp} />
        </div>
      </div>

      <div className="form_item_fr">
        <div className="form-group">
          <TextField size="small" label="City" variant="outlined" value={city} onChange={handleCityChange} onKeyUp={handleKeyUp} />
        </div>
      </div>
      <div className="form_item_fr flex">
        <div className="btn-group dropup tw">
          <FormControl variant="outlined" size="small">
            <InputLabel>State</InputLabel>
            <Select
              value={state}
              onChange={handleStateChange}
            >
              <MenuItem value={STATE_ANY}>
                <em>-State-</em>
              </MenuItem>
              <MenuItem value={STATE_UNKNOWN}>
                <em>-UNKNOWN-</em>
              </MenuItem>
              {STATES.map(({ code, label }) => <MenuItem key={code} value={code}>{code}</MenuItem>)}
            </Select>
          </FormControl>
        </div>
        <div className="form_item_fr trd">
          <TextField size="small" label="Zip" variant="outlined" value={zip} onChange={handleZipChange} onKeyUp={handleKeyUp} />
        </div>
      </div>
      <div className="form_item_fr">
        <div className="mb-2">
          <FormControlLabel control={<Checkbox size="small" fullWidth checked={subdivisionMapping} value={true} onChange={(e) => { setSubdivisionMapping(!subdivisionMapping); onChangeMode(!subdivisionMapping) }} />} label='Subdivision Mapping Mode' />
        </div>
      </div>
      <div className="btn_box">
        <Button disabled={hasChanges} variant="contained" color="primary" onClick={handleOnClick}>
          Search
        </Button>
      </div>
      {totalItems ? <div className="form_item_fr">
        <div className="mb-2" style={{ padding: 0, color: '#2c4773', margin: 0, textAlign: 'center' }}>
          {totalItems} results found
        </div>
      </div> : ''}
    </div>
  </div>
}

export default withRouter(Filter);
