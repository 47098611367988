import { Button, Icon } from '@material-ui/core';
import React, { useState } from 'react';
import styled from "styled-components";
import SubdivisionCreateDialog from "../containers/SubdivisionCreateDialog";

const Wrapper = styled.div`
`;

const SubdivisionAddButton = ({ hoaId, onAdd }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (entity) => {
    if (entity) {
      onAdd && onAdd(entity);
    }
    setOpen(false);
  };

  return (
    <Wrapper>
      <Button color="default" onClick={handleClickOpen}><Icon>add_circle</Icon> Add</Button>
      <SubdivisionCreateDialog hoaId={hoaId} onClose={handleClose} open={open} />
    </Wrapper>
  );
}

export default SubdivisionAddButton;
