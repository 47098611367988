import React, { useEffect } from 'react';
import { useAuth } from '../utils/auth';
import Loading from './Loading';

const Logout = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
  }, []);

  return <Loading />
}

export default Logout;
