import React from 'react';
import { useAuth } from "../../utils/auth";
import { Link } from "react-router-dom";
import styled from "styled-components";
import hd_on_oof from '../../../src/images/hd_on_oof.png';
import hd_prof from '../../../src/images/hd_prof.png';
import '../../index.css';

const Wrapper = styled.div`
  width:40%;
  text-align:right;
`;

const NameWrapper = styled.span`
  // padding: 0 20px;
`;

const ProfileBadge = () => {
  const { userData } = useAuth();

  return (
    <Wrapper>
      <div className="bg_blue">
        <div className="container">
          <div className="left_menu_it">
            <ul className="itm_icon">
              <li>
                <NameWrapper>
                  <img src={hd_prof} alt="hd_prof" /> {userData.username} ({userData.group})
                </NameWrapper>
              </li>
              <li>
                <Link to='/logout'>
                  {/* Log out */}
                  <img src={hd_on_oof} alt="hd_on_oof" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Wrapper>
  );

}

export default ProfileBadge;
