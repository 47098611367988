import React from 'react';
import styled from 'styled-components';
import ProfileBadge from "../UserProfile/ProfileBadge";
import Menu from "./Menu";

const Wrapper = styled.div`
  display: flex !important;
  justify-content: space-between !important;  
`;

const Header = () => {
  return <Wrapper>
    <Menu />
    <ProfileBadge />
  </Wrapper>
}

export default Header;
