import { useSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import SubdivisionList from "../../components/SubdivisionList";
import LoadingOverlay from "../../components/Loading/LoadingOverlay";
import { ROUTES } from "../../constants";
import Content from "./Content";
import Filter from "./Filter";
import { subdivision } from "../../api/endpoints";
import Unauthorized from '../../routes/Unauthorized';
import { useAuth } from '../../utils/auth'
import CreateSubdivision from "./Tabs/CreateSubdivision";

//latest add
import { Button } from "@material-ui/core";
import { Pagination } from "@mui/material";
const { getSubdivisions } = subdivision;

const { SUB_MASTER } = ROUTES;

const Wrapper = styled.div`
  display: flex !important;
  height: 100% !important;
  background-color: #F0F7FF !important;
  padding: 22px 0px !important;
`;
const LeftBar = styled.div`
  flex-basis: 30% !important;
  width:30%;
  display: flex !important;
  flex-direction: column !important;
  // overflow: hidden !important;
  position: relative !important;
`;
const RightBar = styled.div`
  flex-basis: 70% !important;
  overflow: auto;
  width:70%;
  background-color: rgb(235, 243, 252) !important;
`;

const StyledList = styled(SubdivisionList)`
  overflow-y: auto !important;
`;

const SubMaster = ({ location, match }) => {
  const { userData } = useAuth();
  const [hasChanges, setHasChanges] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [selectedItemId, setSelectedItemId] = useState();
  const [totalResults, setTotalResults] = useState();

  const [showCreateSubdivision, setShowCreateSubdivision] = useState(true);
  const [disableCreateSubdivisionButton, setDisableCreateSubdivisionButton] = useState(true)
  const [leftSidebar, setLeftSidebar] = useState(true)
  const [standaloneCreate, setStandaloneCreate] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();


  useEffect(() => {
    setInitialized(true);
  }, [])

  useEffect(() => {
    if (!initialized) {
      return;
    }
    if (!filter.name && !filter.state && !filter.fips) {
      setItems([]);
      return;
    }
    setLoading(true);
    const request = getSubdivisions({ filter });
    try {
      (async () => {
        try {
          const subdivisions = await request;
          setPages(subdivisions?.meta?.totalItems / 20);
          setTotalResults(subdivisions?.meta?.totalItems);
          setItems(subdivisions.items);
        }
        catch (e) {
          enqueueSnackbar("Could not load subdivisions! Please try again or narrow the search. ", { variant: 'error' })
        }
        setLoading(false);
      })();
    }
    catch (e) {
      console.log(`Error : ${e}`);
      setLoading(false)
    }
    return () => { request.cancel(); setLoading(false); }
  }, [filter]);

  useEffect(() => {
    if (items.length == 1 && selectedItemId == null) {
      setSelectedItemId(items[0].recordId)
      onSelect(items[0].recordId);
    }
    if (!selectedItemId) {
      return;
    }
    if (!items.find(({ recordId }) => recordId === selectedItemId)) {
      enqueueSnackbar(`Cannot find the Subdivision ${selectedItemId} amongst the currently filtered records.`, { variant: 'warning' });
    }
  }, [items]);

  useEffect(() => {
    setSelectedItemId(match.params.recordId ? Number(match.params.recordId) : null);
  }, [match.params.recordId])

  const onChangeFilter = (filter) => {
    setSelectedItemId(null)
    if (!showCreateSubdivision) {
      setShowCreateSubdivision(true)
    }
    if (!hasChanges) {
      setFilter(filter);
      setPage(1);
      setDisableCreateSubdivisionButton(false)
      setStandaloneCreate(false)
    } else {
      enqueueSnackbar('You need to save or cancel the changes first.', { variant: 'error' });
    }
  }

  const onSelect = (id) => {
    if (!hasChanges || !selectedItemId) {
      history.push(`${SUB_MASTER}/${id}${location.search}${location.hash}`);
    } else {
      enqueueSnackbar('You need to save or cancel the changes first.', { variant: 'error' });
    }
  }

  const onDelete = () => {
    history.push(`${SUB_MASTER}${location.search}${location.hash}`);
    setItems(items.filter((item) => item.recordId !== selectedItemId));
  }

  // @todo Use redux for it here instead.
  const onUpdate = (updatedData) => {
    setItems(items.map((item) => {
      if (item.recordId === selectedItemId) {
        return updatedData;
      }
      return item;
    }));
  }

  const handleCreateSubdivision = () => {
    if (showCreateSubdivision) {
      setShowCreateSubdivision(false)
    } else {
      setShowCreateSubdivision(true)
    }
    if (selectedItemId == null) {
      setStandaloneCreate(!standaloneCreate)
    }
  }
  const changeOffset = (event, value) => {
    setFilter({ ...filter, offset: (value - 1) * 20 })
    setPage(value);
    history.push(`${SUB_MASTER}/${location.search}${location.hash}`);
  }
  const handleViewSidebar = () => {
    setLeftSidebar(!leftSidebar);
  };
  useEffect(() => {
    setShowCreateSubdivision(showCreateSubdivision)
  }, [showCreateSubdivision])

  if (!userData.group) {
    return <Unauthorized />
  }
  return (
    <Wrapper>
      <LeftBar className={leftSidebar ? 'sidebar open' : 'sidebar'}>
        {loading && <LoadingOverlay />}
        <Filter onSubmit={onChangeFilter} totalItems={totalResults} />
        <div class="cre_btn">
          <Button disabled={disableCreateSubdivisionButton} variant="contained" color="primary" onClick={handleCreateSubdivision} >{showCreateSubdivision ? 'Create new Subdivision' : 'Back'}</Button>
        </div>
        {showCreateSubdivision ?
          <div class="sec_filt">
            <StyledList
              items={items.map(i => ({ recordId: i.recordId, name: i.name, state: i.state, fips: i.fips }))}
              selectedItemId={selectedItemId}
              onSelect={onSelect}
            />
          </div> : null}
        <Pagination style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 10 }} color='standard' onChange={changeOffset} count={Math.ceil(pages)} page={page} />
        <button onClick={handleViewSidebar} className={leftSidebar ? "sidebar-toggle open" : "sidebar-toggle"}>
          <i className={leftSidebar ? "arrow left" : 'arrow right'}></i>
        </button>
      </LeftBar>
      <RightBar className={leftSidebar ? 'content open' : 'content'}>
        <Content
          subdivision={items.find(({ recordId }) => recordId === selectedItemId)}
          onChangeHasChanges={setHasChanges}
          onDelete={onDelete}
          onUpdate={onUpdate}
          ItemsList={res => items.unshift(res)}
          show={showCreateSubdivision}
          showFunction={res => setShowCreateSubdivision(res)}
          totalItems={totalResults}
          isStandaloneCreate={standaloneCreate}
        />
        {standaloneCreate ?
          <div class="wid_dep">
            <h4 className="hd_box">Create Subdivision</h4>
            <CreateSubdivision
              standalone
              onChangeHasChanges={setHasChanges}
              onDelete={onDelete}
              onUpdate={onUpdate}
              changeShow={(res) => handleCreateSubdivision(res)}
              subdivisionItemsList={res => {
                items.unshift(res)
              }}
            />
          </div> : ''}
      </RightBar>
    </Wrapper>
  );
}

export default withRouter(SubMaster);
