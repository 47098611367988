import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { subdivision } from '../api';
import LoadingOverlay from "../components/Loading/LoadingOverlay";
import PopoverMouseOver from "../components/PopoverMouseOver";

const { hoasGetAll } = subdivision;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const SubdivisionOtherHoas = ({ subdivisionId }) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (!subdivisionId) {
      return;
    }

    setLoading(true);

    const request = hoasGetAll(subdivisionId);
    (async () => {
      try {
        const response = await request;
        setItems(response.items);
        setLoading(false);
      }
      catch (e) {
        setLoading(false);
      }
    })();

    return () => { request.cancel(); setLoading(false) };
  }, [subdivisionId]);

  const content = () => <div>
    {items.map(({ name }) => <span>{name} <br /></span>)}
  </div>;

  return <Wrapper>
    {loading ? <LoadingOverlay /> : <PopoverMouseOver content={content()} trigger={items.length} />}
  </Wrapper>;
}

export default SubdivisionOtherHoas;
