import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { trimStart } from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Contacts from "./Tabs/Contacts";
import CompanyDetails from "./Tabs/CompanyDetails";
import OrderingInfo from "./Tabs/OrderingInfo";
//latest add
import HoaInfo from "./Tabs/HoaInfo";
import CreateCompany from "./Tabs/CreateCompany";

const TabPanelWrapper = styled.div`
  height: 100%;
`;

const StyledBox = styled(Box)`
  height: 100%;
`;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <TabPanelWrapper role="tabpanel" hidden={value !== index} {...other}>
      <StyledBox>{children}</StyledBox>
    </TabPanelWrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;
const WrapperInternal = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
`;

const tabNames = [
  "company-details",
  "contacts",
  "ordering-info",
  "hoa-info",
  "create-company",
];

const Content = ({
  company,
  location,
  onChangeHasChanges,
  onDelete,
  onUpdate,
  show,
  showFunction,
  ItemsList,
  isReview,
  totalItems,
  isStandaloneCreate,
}) => {
  const [tabNum, setTabNum] = useState(0);
  const [hasChangesMgmtCompany, setHasChangesMgmtCompany] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const [showTab, setShowTab] = useState();

  useEffect(() => {
    setShowTab(show);
    if (!show) {
      history.push(`${location.pathname}${location.search}#${tabNames[4]}`);
    } else {
      history.push(`${location.pathname}${location.search}#${tabNames[0]}`);
    }
  }, [show]);

  useEffect(() => {
    onChangeHasChanges(hasChangesMgmtCompany);
  }, [hasChangesMgmtCompany]);

  useEffect(() => {
    history.push(`${location.pathname}${location.search}#${tabNames[0]}`);
  }, [hasChangesMgmtCompany]);

  useEffect(() => {
    const tabName = trimStart(location.hash, "#");
    const tabIndex = tabNames.indexOf(tabName);
    setTabNum(tabIndex === -1 ? 0 : tabIndex);
  }, [location.hash]);

  const handleChange = (event, newValue) => {
    if (!hasChangesMgmtCompany) {
      history.push(
        `${location.pathname}${location.search}#${tabNames[newValue]}`
      );
    } else {
      enqueueSnackbar("You need to save or cancel the changes first.", {
        variant: "error",
      });
    }
  };

  if (!company && !isStandaloneCreate) {
    return <>Please select a Company {totalItems ? `out of the ${totalItems} Companies found` : ''}</>;
  }
  if(isStandaloneCreate){
    return <></>;
  }
  return (
    <div class="wid_dep">
      <Wrapper>
        <WrapperInternal>
          <AppBar position="static">
            {tabNum == 0 || tabNum == 4 ? '' : <span style={{ color: "black", marginTop: -12, marginBottom: 10 }}>Company Name: {company.name}</span>}

            <div class="tab_sty">
              <Tabs value={tabNum} onChange={handleChange}>
                {showTab ? <Tab label="Company Details" /> : null}
                {showTab ? <Tab label="Contacts" /> : null}
                {showTab ? <Tab label="Ordering Info" /> : null}
                {showTab ? <Tab label="HOA Info" /> : null}
                {!showTab ? <Tab disabled={true} label="Create Management Company" /> : null}
              </Tabs>
            </div>
          </AppBar>
          <ContentWrapper>
            <TabPanel
              style={{ display: showTab ? null : "none" }}
              value={tabNum}
              index={0}
            >
              <CompanyDetails
                {...company}
                mgmtCompanyRecordId={company?.recordId}
                onChangeHasChanges={setHasChangesMgmtCompany}
                onDelete={onDelete}
                onUpdate={onUpdate}
              />
            </TabPanel>
            <TabPanel
              style={{ display: showTab ? null : "none" }}
              value={tabNum}
              index={1}
            >
              <Contacts
                mgmtCompanyRecordId={company?.recordId}
              />
            </TabPanel>
            <TabPanel
              style={{ display: showTab ? null : "none" }}
              value={tabNum}
              index={2}
            >
              <OrderingInfo
                mgmtCompanyRecordId={company?.recordId}
              />
            </TabPanel>
            <TabPanel style={{ display: showTab ? null : 'none' }} value={tabNum} index={3}>
              <HoaInfo managementId={company?.recordId} companyState={company?.state} />
            </TabPanel>
            <TabPanel
              style={{ display: showTab ? "none" : null }}
              value={tabNum}
              index={4}
            >
              <CreateCompany
                {...company}
                onChangeHasChanges={setHasChangesMgmtCompany}
                onDelete={onDelete}
                onUpdate={onUpdate}
                changeShow={(res) => showFunction(res)}
                companyItemsList={(res) => ItemsList(res)}
              />
            </TabPanel>
          </ContentWrapper>
        </WrapperInternal>
      </Wrapper>
    </div>
  );
};

export default withRouter(Content);
