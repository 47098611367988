import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { mgmtCompany } from "../../../api/endpoints";
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";
import Hoas from "../../../components/Hoas";
import HoaSearchManagement from "../../HoaSearchManagement";

const { hoasGetAll } = mgmtCompany;

const ListWrapper = styled.div`
  flex: 1;
  position: relative;
`;

const SearchWrapper = ListWrapper;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const HoaInfo = ({ managementId, companyState }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setItems([]);
    if (!managementId) {
      return;
    }

    setLoading(true);
    try {
      const request = hoasGetAll(managementId);
      (async () => {
        const response = await request;
        setItems(response.items);
        setLoading(false);
      })();
      return () => { request.cancel(); setLoading(false) };
    }
    catch (e) {
      console.log(`Error : ${e}`);
      setLoading(false)
    }
  }, [managementId]);

  const onAddHandler = (hoa) => {
    setItems([...items, hoa]);
  }

  const onDisconnect = (hoaId) => {
    setItems(items.filter(({ recordId }) => recordId !== hoaId));
  }

  return <Wrapper>
    <ListWrapper>
      {loading && <LoadingOverlay />}
      <Hoas items={items}
        ofCompany={managementId} onDisconnect={onDisconnect} />
    </ListWrapper>
    <SearchWrapper>
      <HoaSearchManagement
        defaultState={companyState}
        forCompany={managementId}
        onAdd={onAddHandler}
        hoasAttached={items}
      />
    </SearchWrapper>
  </Wrapper>;
}

export default HoaInfo;
