import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { hoa, mgmtCompany } from "../api";
import InputPhoneNumber from "../components/InputPhoneNumber";
import LoadingOverlay from "../components/Loading/LoadingOverlay";
import {
  ORDERING_INFO_DELIVERY_TYPE_DEFAULT,
  ORDERING_INFO_DELIVERY_TYPES,
  ORDERING_INFO_PROCESS_METHODS,
  ORDERING_INFO_TYPES,
  REGEXP_EMAIL,
  REGEXP_PHONE_NUMBER,
  REGEXP_WEBSITE,
} from "../constants";

const { orderingInfoCreate: hoaOrderingInfoCreate, orderingInfoPatch: hoaOrderingInfoPatch } = hoa;
const { orderingInfoCreate: mgmtCompanyOrderingInfoCreate, orderingInfoPatch: mgmtCompanyOrderingInfoPatch } = mgmtCompany;

const DEFAULT_NAME = 'Main Office';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTextField = styled(TextField)`
  flex-basis: ${props => props.width / 2}%
`;

const StyledFormControl = styled(FormControl)`
  flex-basis: ${props => props.width / 2}%;
`;

const OrderingInfoDialog = ({ entity = {}, linkedRecordId, onClose, open, type }) => {
  const [address, setAddress] = useState('')
  const [deliveryType, setDeliveryType] = useState('')
  const [email, setEmail] = useState('')
  const [fax, setFax] = useState('')
  const [name, setName] = useState('')
  const [phonePrimary, setPhonePrimary] = useState('')
  const [phoneSecondary, setPhoneSecondary] = useState('')
  const [processMethod, setProcessMethod] = useState('')
  const [website, setWebsite] = useState('')
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [hasChanges, setHasChanges] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const correspondingFieldIsFilled =
      Boolean((processMethod === ORDERING_INFO_PROCESS_METHODS.Email && email))
      || Boolean((processMethod === ORDERING_INFO_PROCESS_METHODS.Fax && fax))
      || Boolean((processMethod === ORDERING_INFO_PROCESS_METHODS.Mail && address))
      || Boolean((processMethod === ORDERING_INFO_PROCESS_METHODS.Phone && phonePrimary))
      || Boolean((processMethod === ORDERING_INFO_PROCESS_METHODS.Website && website));

    const phoneFormatRegex = new RegExp(REGEXP_PHONE_NUMBER);
    const phonePrimaryValid = !phonePrimary || Boolean(phonePrimary.match(phoneFormatRegex));
    const phoneSecondaryValid = !phoneSecondary || Boolean(phoneSecondary.match(phoneFormatRegex));
    const faxValid = !fax || Boolean(fax.match(phoneFormatRegex));

    const emailFormatRegex = new RegExp(REGEXP_EMAIL);
    const emailValid = !email || Boolean(email.match(emailFormatRegex));

    const websiteFormatRegex = new RegExp(REGEXP_WEBSITE);
    const websiteValid = !website || Boolean(website.match(websiteFormatRegex));

    setIsValid(
      processMethod
      && deliveryType
      && correspondingFieldIsFilled
      && faxValid
      && phonePrimaryValid
      && phoneSecondaryValid
      && emailValid
      && websiteValid
    );
  }, [
    address,
    deliveryType,
    email,
    fax,
    phonePrimary,
    phoneSecondary,
    processMethod,
    website,
  ]);

  useEffect(() => {
    resetFromProps();
  }, [
    entity.address,
    entity.deliveryType,
    entity.email,
    entity.fax,
    entity.name,
    entity.phonePrimary,
    entity.phoneSecondary,
    entity.processMethod,
    entity.recordId,
    entity.website,
  ]);

  useEffect(() => {
    const anythingIsChanged =
      (address !== entity.address && (address !== '' || entity.address !== null))
      || (deliveryType !== entity.deliveryType && (deliveryType !== '' || entity.deliveryType !== null))
      || (email !== entity.email && (email !== '' || entity.email !== null))
      || (fax !== entity.fax && (fax !== '' || entity.fax !== null))
      || (name !== entity.name && (name !== '' || entity.name !== null))
      || (phonePrimary !== entity.phonePrimary && (phonePrimary !== '' || entity.phonePrimary !== null))
      || (phoneSecondary !== entity.phoneSecondary && (phoneSecondary !== '' || entity.phoneSecondary !== null))
      || (processMethod !== entity.processMethod && (processMethod !== '' || entity.processMethod !== null))
      || (website !== entity.website && (website !== '' || entity.website !== null));
    setHasChanges(anythingIsChanged);
  }, [
    address,
    deliveryType,
    email,
    fax,
    name,
    phonePrimary,
    phoneSecondary,
    processMethod,
    website,
    entity,
  ]);

  const onChangeAddress = (event) => {
    setAddress(event.target.value.toUpperCase());
  }

  const onChangeDeliveryType = (event) => {
    setDeliveryType(event.target.value);
  }

  const onChangeEmail = (event) => {
    setEmail(event.target.value.toUpperCase());
  }

  const onChangeFax = (event) => {
    setFax(event.target.value);
  }

  const onChangeName = (event) => {
    setName(event.target.value.toUpperCase());
  }

  const onChangePhonePrimary = (event) => {
    setPhonePrimary(event.target.value);
  }

  const onChangePhoneSecondary = (event) => {
    setPhoneSecondary(event.target.value);
  }

  const onChangeProcessMethod = (event) => {
    setProcessMethod(event.target.value);
  }

  const onChangeWebsite = (event) => {
    setWebsite(event.target.value.toUpperCase());
  }

  const resetFromProps = () => {
    setAddress(entity.address || '');
    setDeliveryType(entity.deliveryType || ORDERING_INFO_DELIVERY_TYPE_DEFAULT);
    setEmail(entity.email || '');
    setFax(entity.fax || '');
    setName(entity.name || '');
    setPhonePrimary(entity.phonePrimary || '');
    setPhoneSecondary(entity.phoneSecondary || '');
    setProcessMethod(entity.processMethod || '');
    setWebsite(entity.website || '');
  }

  const handleSave = async () => {
    setLoading(true);

    if (!name) {
      setName(DEFAULT_NAME);
    }
    if (phoneSecondary != '0000000000' && phonePrimary != '0000000000' && fax != '0000000000') {
      const orderingInfoPayload = {
        address,
        deliveryType,
        email: email || null,
        fax: fax || null,
        name: name || DEFAULT_NAME,
        phonePrimary: phonePrimary || null,
        phoneSecondary: phoneSecondary || null,
        processMethod,
        website: website || null,
      };

      try {
        let orderingInfo;
        let successMessage;
        if (entity.recordId) {
          if (type === ORDERING_INFO_TYPES.hoa) {
            orderingInfo = await hoaOrderingInfoPatch(linkedRecordId, entity.recordId, orderingInfoPayload);
          } else {
            orderingInfo = await mgmtCompanyOrderingInfoPatch(linkedRecordId, entity.recordId, orderingInfoPayload);
          }

          successMessage = 'The Ordering Info has been updated';
          resetFromProps();
        } else {
          if (type === ORDERING_INFO_TYPES.hoa) {
            orderingInfo = await hoaOrderingInfoCreate(linkedRecordId, orderingInfoPayload);
          } else {
            orderingInfo = await mgmtCompanyOrderingInfoCreate(linkedRecordId, orderingInfoPayload);
          }
          successMessage = 'The Ordering Info has been created';
          resetFromProps();
        }
        enqueueSnackbar(successMessage, { variant: 'success' });
        resetFromProps();
        onClose(orderingInfo);
      } catch (e) {
        console.error(e);
        const message = e.response.data.message;
        enqueueSnackbar(`Error while creating the Ordering Info: ${message ?? 'Unknown error'}`, { variant: 'error' });
      }
    }
    else {
      enqueueSnackbar('Contact number cannot be 000-000-0000', { variant: 'error' })
    }
    setLoading(false);
  }

  const handleCancel = () => {
    resetFromProps();
    onClose();
  }

  const onDialogClose = () => {
    if (loading) {
      enqueueSnackbar('Wait the action to be completed', { variant: 'warning' });
    } else {
      onClose();
    }
  }

  return (
    <Dialog onClose={onDialogClose} open={open} maxWidth='md'>
      {loading && <LoadingOverlay />}
      <DialogTitle>{entity.recordId ? 'Update' : 'Create'} Ordering Info</DialogTitle>
      <DialogContent>
        <Row>
          <StyledTextField className='OrderInfo-Form' label="Contact Name" onChange={onChangeName} placeholder={DEFAULT_NAME} value={name} />
          <StyledFormControl className='OrderInfo-Form' size="small" >
            <InputLabel>Delivery Type</InputLabel>
            <Select
              value={deliveryType}
              onChange={onChangeDeliveryType}
            >
              {Object.keys(ORDERING_INFO_DELIVERY_TYPES).map(deliveryType => <MenuItem key={deliveryType} value={deliveryType}>{deliveryType}</MenuItem>)}
            </Select>
          </StyledFormControl>
        </Row>
        <Row>
          <StyledFormControl className='OrderInfo-Form' size="small" >
            <InputLabel>Process Method</InputLabel>
            <Select
              value={processMethod}
              onChange={onChangeProcessMethod}
            >
              {Object.keys(ORDERING_INFO_PROCESS_METHODS).map(processMethodOption => <MenuItem key={processMethodOption} value={processMethodOption}>{processMethodOption}</MenuItem>)}
            </Select>
          </StyledFormControl>
          <TextField className='OrderInfo-Form' disabled={true} label="Ordering Info Type" value={type === ORDERING_INFO_TYPES.hoa ? 'HOA' : 'Management Company'} />
        </Row>
        <Row>
          <TextField
            className='OrderInfo-Form'
            InputProps={{
              inputComponent: InputPhoneNumber,
            }}
            label="Primary Phone"
            onChange={onChangePhonePrimary}
            value={phonePrimary}
          />
          <TextField
            className='OrderInfo-Form'
            InputProps={{
              inputComponent: InputPhoneNumber,
            }}
            label="Secondary Phone"
            onChange={onChangePhoneSecondary}
            value={phoneSecondary}
          />
        </Row>
        <Row>
          <TextField className='OrderInfo-Form' label="Email" onChange={onChangeEmail} value={email} />
          <TextField
            className='OrderInfo-Form'
            InputProps={{
              inputComponent: InputPhoneNumber,
            }}
            label="Fax"
            onChange={onChangeFax}
            value={fax}
          />
        </Row>
        <Row>
          <TextField className='OrderInfo-Form' label="Address" onChange={onChangeAddress} value={address} />
          <TextField className='OrderInfo-Form' label="Website" onChange={onChangeWebsite} value={website} />
        </Row>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button disabled={!isValid || !hasChanges} onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

export default OrderingInfoDialog;
