import { Button, FormControl, TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { subdivisionCreateStandalone } from "../../../api/endpoints/hoa";
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";
import { STATES, FIPS } from "../../../constants";


const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTextField = styled(TextField)`
  flex-basis: ${props => props.width}%
`;

const StyledFormControl = styled(FormControl)`
  flex-basis: ${props => props.width}%;
`;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
`;

const STATE_UNKNOWN = 'NA';
const STATE_ANY = 'ANY';

const CreateSubdivision = ({
  code,
  isValid,
  recordId,
  onChangeHasChanges,
  changeShow,
  subdivisionItemsList,
  onDelete,
  onUpdate,
  ...props
}) => {
  const [county, setCounty] = useState('');
  const [name, setName] = useState('');
  const [state, setState] = useState('');
  const [fips, setFips] = useState('');


  const [hasChanges, setHasChanges] = useState(true);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    resetFromProps();
  }, [
    props.county,
    props.name,
    props.state,
    props.fips,
    recordId,
  ]);

  const resetFromProps = () => {
    setCounty('');
    setName('');
    setState('');
    setFips('');
  }

  const onChangeName = event => {
    setName(event.target.value.toUpperCase());
  }

  const onChangeFips = event => {
    setFips(event.target.value);
  }

  const onChangeState = event => {
    setState(event.target.value);
    setFips('');
    if (event.target.value === 'NA') {
      setFips('99999')
    }
  }

  const CountyList = Object.keys(FIPS).map(function (key) {
    if (state === FIPS[key].state)
      return <MenuItem key={key} value={key}>{FIPS[key].county}</MenuItem>
  });

  const onSubmit = async () => {
    setLoading(true);
    try {
      const subdivision = await subdivisionCreateStandalone(
        {
          fips,
          name,
        },
      );
      enqueueSnackbar('The Subdivision has been created', { variant: 'success' });
      subdivisionItemsList(subdivision)
      resetFromProps();
      changeShow(true)
    } catch (e) {
      console.error(e);
      const message = e.response.data.message;
      enqueueSnackbar(`Error while creating the Subdivision: ${message ?? 'Unknown error'}`, { variant: 'error' });
    }
    setLoading(false);
  }

  const onCancelClick = () => {
    changeShow(true)
    onChangeHasChanges(false)
    resetFromProps()
  }

  return <Wrapper>
    {loading && <LoadingOverlay />}
    <div class="set1_tab">
      <Row>
        <div class="wor mt-3">
          <div class="form_group lf">
            <StyledTextField width={85} size="small" label="Name" value={name || ''} onChange={onChangeName} />
          </div>
          <div class="form_group rg">
            <StyledTextField width={15} size="small" label="Fips Code" value={fips || ''} />
          </div>
        </div>
      </Row>

      <Row>
        <div class="wor sec mt-3">
          <div class="form_group lf">
            <StyledFormControl width={15} size="small">
              <InputLabel>State</InputLabel>
              <Select
                value={state}
                onChange={onChangeState}
              >
                <MenuItem value={STATE_UNKNOWN}>-UNKNOWN-</MenuItem>
                {STATES.map(({ code, label }) => <MenuItem key={code} value={code}>{code}</MenuItem>)}
              </Select>
            </StyledFormControl>
          </div>
          <div class="form_group rg">
            <StyledFormControl size="small">
              <InputLabel>County</InputLabel>
              <Select
                value={fips}
                onChange={onChangeFips}
              >
                <MenuItem value={''}>
                  <em>-County-</em>
                </MenuItem>
                {state === STATE_ANY ?
                  <MenuItem value={''}>
                    <em>Select A State First</em>
                  </MenuItem>
                  : <></>
                }
                {CountyList}
              </Select>
            </StyledFormControl>
          </div>
        </div>
      </Row>
    </div>

    <div class="set2_tab">
      <Row>
        <div class="btn_box rg">
          <Button variant="contained" color="primary" disabled={!hasChanges} onClick={onSubmit}>Create</Button>
          <Button color="secondary" disabled={!hasChanges} onClick={onCancelClick}>Cancel</Button>
        </div>
      </Row>
    </div>
  </Wrapper>;
}

export default CreateSubdivision;
