import config from '../../config';
import { LOCAL_STORAGE_KEY_AUTH_TOKEN_ACCESS } from "../../constants";

const { backend: { apiV5: { baseUrl } } } = config;

const apiV5 = () => {
  const authToken = localStorage.getItem(LOCAL_STORAGE_KEY_AUTH_TOKEN_ACCESS);
  //const authToken = 'eyJraWQiOiJtTzNOTXVneTN5U1lRMWo1RDR3Q2llREU2RElUdUpGR24zNmd1VmhOTEJrPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI5NjJhN2ZmMS0yNjE3LTQ3N2QtYTEzNC1jN2NlYjBmMzNmY2UiLCJjb2duaXRvOmdyb3VwcyI6WyJtYW5hZ2VyIl0sImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX3lHZnpRMklaaiIsInZlcnNpb24iOjIsImNsaWVudF9pZCI6IjcwNGNmbjN2aGwxMGhpYXVkODEya3A0M2RuIiwiZXZlbnRfaWQiOiI3MWU1ZTg5NS05OWVjLTQ5YmItOWQ1Yy1iMTViZmQxNTAyY2QiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImF3cy5jb2duaXRvLnNpZ25pbi51c2VyLmFkbWluIHBob25lIG9wZW5pZCBwcm9maWxlIGVtYWlsIiwiYXV0aF90aW1lIjoxNjM2MDI2MzUxLCJleHAiOjE2MzYyMDcxODIsImlhdCI6MTYzNjE5OTk4MiwianRpIjoiYmViNWRmMDAtZTc4Ny00ZTU4LTk0YjAtMGM4NmMzZTgzNzM2IiwidXNlcm5hbWUiOiJrc2hpdGlqIn0.J7UlRzg8VMElsbebAcuCNUr87omL2VVcXV-GpSf-Z2YTJXtOKi3HRhiPIBojyUQ5aS4eyuCAV9WB4jfGb1QALM937orf-fK_NgRZsaYvjDXYlEfG3uksi58XnXb7Of0Wp5PmhKb3IV81AiUOUbsoTl3y-OMd3kAbSm-LFYY0VSfg-EvIOGSq-yRGXocb3jVvnPVONxhnNshVKdz315RNiqJpJwqu-e5eG9rEne7RXE15g1O5Q6F6D2q6EnHx_o_iYSocryt8y_z_v_05Igy5WctE9QaGK-M8DITAIKCMpCRsZbP3JzchxRkfC2QqR7fRWGwhrbF2szYO1nYNpH70Cg';
  return {
    apiUrl: baseUrl,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    timeout: 100000,
  };
}

export default apiV5;
