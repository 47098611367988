import React from 'react';
import NumberFormat from 'react-number-format';

const InputPhoneNumber = ({ inputRef, onChange, ...props }) => {
  return (
    <NumberFormat
      {...props}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format={'###-###-####'}
    />
  );
}

export default InputPhoneNumber;
