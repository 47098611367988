import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const Item = ({ id, isSelected, name, state, fips, onClick }) => {
  const secondaryText = "State: " + state + "\tFIPS Code: " + fips;
  return (
    <div class="min_li">
      <ListItem button selected={isSelected} key={id} onClick={onClick}>
        <ListItemText primary={name} secondary={secondaryText} />
      </ListItem>
    </div>
  );
}

export default Item;
