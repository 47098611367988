import axios, { CancelToken } from 'axios';
import qs from 'qs';
import {
  HEADERS_DELETE,
  HEADERS_GET,
  HEADERS_PATCH,
  HEADERS_POST,
  HEADERS_PUT,
  TIMEOUT,
} from './constants';
import { encode } from './encode';

export function get(apiPath, { apiUrl, timeout = TIMEOUT, headers = {} } = {}, parameters) {
  const source = CancelToken.source();

  const query = parameters ? qs.stringify(parameters) : '';

  const url = new URL(`${apiPath}${query && `?${query}`}`, apiUrl);

  const request = axios
    .get(url, {
      headers: { ...HEADERS_GET, ...headers },
      timeout,
      cancelToken: source.token,
    })
    .then(response => response.data);
  request.cancel = () => source.cancel();
  return request;
}

export function patch(apiPath, { apiUrl, timeout = TIMEOUT, headers = {}, body = {} } = {}, patchParams) {
  const source = CancelToken.source();

  const url = new URL(apiPath, apiUrl);

  const request = axios
    .patch(url, { ...body, ...patchParams }, {
      headers: { ...HEADERS_PATCH, ...headers },
      timeout,
      transformRequest: [data => encode(data)],
      cancelToken: source.token,
    })
    .then(response => response.data);
  request.cancel = () => source.cancel();
  return request;
}

export function post(apiPath, { apiUrl, timeout = TIMEOUT, headers = {}, body = {} } = {}, postParams) {
  const source = CancelToken.source();

  const url = new URL(apiPath, apiUrl);

  const request = axios
    .post(url, { ...body, ...postParams }, {
      headers: { ...HEADERS_POST, ...headers },
      timeout,
      transformRequest: [data => encode(data)],
      cancelToken: source.token,
    })
    .then(response => response.data);
  request.cancel = () => source.cancel();
  return request;
}

export function put(apiPath, { apiUrl, timeout = TIMEOUT, headers = {}, body = {} } = {}, putParams = {}) {
  const source = CancelToken.source();

  const url = new URL(apiPath, apiUrl);

  const request = axios
    .put(url, { ...body, ...putParams }, {
      headers: { ...HEADERS_PUT, ...headers },
      timeout,
      transformRequest: [data => encode(data)],
      cancelToken: source.token,
    })
    .then(response => response.data);
  request.cancel = () => source.cancel();
  return request;
}

export function del(apiPath, { apiUrl, timeout = TIMEOUT, headers = {} } = {}, deleteParams = undefined) {
  const source = CancelToken.source();

  const url = new URL(apiPath, apiUrl);

  const config = {
    headers: { ...HEADERS_DELETE, ...headers },
    timeout,
    transformRequest: [data => encode(data)],
    cancelToken: source.token,
  };

  if (deleteParams) {
    config.data = deleteParams;
  }

  const request = axios.delete(url, config).then(response => response.data);
  request.cancel = () => source.cancel();
  return request;
}
