import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { hoa, document } from "../../../api/endpoints";
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";
import Document from "../../../components/Document";
import { useSnackbar } from "notistack";
import axios from "axios";
import {
  VALID_DOCUMENT_TYPES,
  MAXIMUM_UPLOAD_SIZE,
} from "../../../constants/index";

import { DOCUMENTTYPE } from "../../../constants";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import moment from "moment";

const { listDocument, createDocument, uploadDocument, downloadDocument } = hoa;
const { getPreSignedUrl } = document;

const ListWrapper = styled.div`
  //   flex: 1;
  position: relative;
  height: 400px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DocumentInfo = ({ hoaRecordId }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [documentName, setName] = useState("");
  const [type, setType] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [isUploading, setIsUploading] = useState(false);
  const [isNewFileAdded, setIsNewFileAdded] = useState(false);
  const inputFile = useRef(null);
  const referenceIdn = hoaRecordId;
  const isDeleted = false;
  const uploadDate = moment().format("MM-DD-YYYY");
  const [selectedFile, setSelectedFile] = useState("");
  const [activeUploadButton, setActiveUploadButton] = useState(false);
  const [fileData, setFileData] = useState();

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const onChangeName = (event) => {
    setName(event.target.value);
  };

  const handleSave = async () => {
    if (type == "" || type == undefined || type == null) {
      enqueueSnackbar(`Type cannot be empty.`, { variant: "error" });
      return;
    }

    setIsUploading(true);

    let name;
    let nameLength = documentName.replace(/ /g, "").length;
    if (nameLength == 0) {
      let originalName = selectedFile;
      let nameFileArray = originalName.split(".");
      name = nameFileArray[0];
    }

    const formData = {
      documentName: nameLength == 0 ? name : documentName,
      type,
      referenceIdn,
      originalFileName: selectedFile,
      isDeleted,
      uploadDate,
    };

    try {
      let orderingInfo;
      let successMessage;
      const data = {
        fileName: selectedFile,
      };
      let presignedUrlResponse = await getPreSignedUrl(data);
      let uploadUrl = presignedUrlResponse.url;

      let loadFile = fileData.target.files[0];
      try {
        setActiveUploadButton(false);
        await axios.put(uploadUrl, loadFile).then(function (response) {
          console.log("response", response);
          setFileData(null);
          resetFromProps()
          inputFile.current.value = null;
        });
      } catch (error) {
        console.log(error);
        enqueueSnackbar("error while uploading file", { variant: "error" });
        setActiveUploadButton(false);
        setIsUploading(false);
        resetFromProps()
        inputFile.current.value = null;
        setFileData(null);
        return;
      }

      setActiveUploadButton(false);
      setIsUploading(true);
      
      orderingInfo = await createDocument(hoaRecordId, formData);
      successMessage = "File uploaded successfully";
      
      enqueueSnackbar(successMessage, { variant: "success" });
      resetFromProps()
      
      inputFile.current.value = null; //clearing the previous record

    } catch (e) {
      console.error(e);
      resetFromProps()
      enqueueSnackbar("error while uploading file", { variant: "error" });
    }

    setIsUploading(false);
    if (!isNewFileAdded) {
      setIsNewFileAdded(true);
    } else {
      setIsNewFileAdded(false);
    }
  };

  const changeHandler = async (event) => {
    
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0].name);
    } else {
      return;
    }

    if (event.target.files[0].size > MAXIMUM_UPLOAD_SIZE.SIZE) {
      setActiveUploadButton(false);
      enqueueSnackbar("File size exceeds the limit of 125 MB", {
        variant: "error",
      });
      return;
    }

    let fileExt = event.target.files[0].name.split(".");

    if (!VALID_DOCUMENT_TYPES.includes(fileExt[fileExt.length - 1])) {
      setActiveUploadButton(false);
      enqueueSnackbar("Invalid document type", { variant: "error" });
      return;
    }

    setFileData(event);
    setActiveUploadButton(true);
  };

  const resetFromProps = () => {
    setName("");
    setType("");
    setSelectedFile("");   
  };

  useEffect(() => {
    resetFromProps();
    setItems([]);

    if (!hoaRecordId) {
      return;
    }

    setTimeout(() => {
      setLoading(true);
    
      const request = listDocument(hoaRecordId);
      (async () => {
        try {
          const response = await request;
          setItems(response);
          setLoading(false);  
        } catch (error) {
          setItems(null);
          setLoading(false)
        }    
      })();
      
      return () => {
        request.cancel();
        setLoading(false);
      };
    
    }, 1000);

    
    
  }, [hoaRecordId, isNewFileAdded]);

  const onDisconnect = (id) => {
    setItems(items.filter(({ documentId }) => documentId !== id));
  };

  const OnView = (id) => {
    setItems(items.filter(({ documentId }) => documentId !== id));
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  return (
    <Wrapper>
      {isUploading && <LoadingOverlay />}
      <ListWrapper>
        {loading && <LoadingOverlay />}
        <Document
          items={items}
          ofHoa={hoaRecordId}
          onDisconnect={onDisconnect}
          OnView={OnView}
        />
      </ListWrapper>

      <div class="set4_tab">
        <HeaderWrapper>
          <h3 class="hd_tg">Upload Documents</h3>
        </HeaderWrapper>
        <div class="set_itm">
          <div style={{ height: "15%" }}>
            <div class="wor trd mt-3">
              <div class="form_group itm mt-1">
                <div>What do you want to call this document?</div>
                <TextField
                  style={{ width: "50%" }}
                  size="small"
                  value={documentName}
                  onChange={onChangeName}
                  variant="outlined"
                />
              </div>
              <div class="form_group itm mt-1">
                <div>What type of document is this?</div>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ width: "50%" }}
                >
                  <InputLabel>Please select</InputLabel>
                  <Select value={type} onChange={handleTypeChange}>
                    {DOCUMENTTYPE.map(({ text }) => (
                      <MenuItem key={text} value={text}>
                        {text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <input
              ref={inputFile}
              type="file"
              name="file"
              onChange={changeHandler}
              style={{ display: "none" }}
            />
            <div class="btn_box rg">
            <InputLabel style= {{marginTop: '20px', color: 'black', float: 'left'}} >{selectedFile}</InputLabel>
              <Button
                style={{ width: "auto" }}
                variant="contained"
                color="primary"
                onClick={onButtonClick}
              >
                Select a file
              </Button>
              <Button
                disabled={!activeUploadButton}
                style={{ width: "auto" }}
                variant="contained"
                color="primary"
                onClick={handleSave}
              >
                Upload File
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default DocumentInfo;
