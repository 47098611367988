import React, { useEffect, useState, Fragment } from 'react';
import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete as MaterialAutocomplete } from '@material-ui/lab';

const Autocomplete = ({ className, disabled = false, getter, label, onChange, value }) => {
  const [options, setOptions] = useState([]);
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!text && disabled) {
      return;
    }

    // @todo Use throttling/debounce here.
    setLoading(true);
    const request = getter({ text });
    (async () => {
      try {
        setOptions(await request);
        setLoading(false);
      }
      catch (e) {
        setLoading(false);
      }
    })();

    return () => { request.cancel(); setLoading(false) };

  }, [text, disabled]);

  useEffect(() => {
    if (!value) {
      setText('');
      return;
    }

    const found = options.find(({ value: optionValue }) => String(value) === String(optionValue));
    if (found) {
      setText(found.label);
      return;
    }

    setLoading(true);
    setText('');
    try {
      const request = getter({ value });
      (async () => {
        try {
          const response = await request;
          const [{ label }] = response;
          setText(label);
          setLoading(false);
        }
        catch (e) {
          setLoading(false);
        }
      })();
      return () => { request.cancel(); setLoading(false) };
    }
    catch (e) {
      setLoading(false);
      return;
    }
  }, [value]);

  const onChangeValue = (entity) => {
    if (entity) {
      onChange(entity.value);
    } else {
      onChange(null);
    }
  }

  const renderOption = ({ label, subLabel }) => {
    if (subLabel) {
      return <div>
        <div><strong>{label}</strong></div>
        <div>{subLabel}</div>
      </div>;
    }

    return label;
  }

  return (
    <MaterialAutocomplete
      className={className}
      getOptionLabel={(option) => option.label}
      options={options}
      loading={loading}
      disabled={disabled}
      onChange={(_, value) => onChangeValue(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          onChange={(e) => setText(e.target.value)}
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
      renderOption={renderOption}
      // @todo Fix warning here.
      value={{ value, label: text }}
    />
  );
}

export default Autocomplete;
