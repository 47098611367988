import { useSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { hoa, mgmtCompany } from "../../../api";
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";
import MgmtCompany from "../../../components/MgmtCompany";
import MgmtCompanyAssignment from "../../MgmtCompanyAssignment";
import NewMgmtCompany from "../../../components/NewMgmtCompany";

const { get: getMgmtCompany, patch: patchMgmtCompany, newManagementCompany: newManagementCompany } = mgmtCompany;
const { patch: patchHoa } = hoa;

const StyledMgmtCompany = styled(MgmtCompany)`
  padding-bottom: 30px;
`;

const StyledNewMgmtCompany = styled(NewMgmtCompany)`
  padding-bottom: 30px;
`;

const Wrapper = styled.div`
  position: relative;
`;

const ManagementCompany = ({ hoaRecordId, mgmtCompanyRecordId, onChangeHasChanges, onUpdate }) => {
  const [loadingEntity, setLoadingEntity] = useState(false);
  const [loadingChangeEntity, setLoadingChangeEntity] = useState(false);
  const [entity, setEntity] = useState({});
  const [hasChangesId, setHasChangesId] = useState(false);
  const [hasChangesEntity, setHasChangesEntity] = useState(false);

  useEffect(() => {
    onChangeHasChanges(hasChangesId || hasChangesEntity);
  }, [hasChangesId, hasChangesEntity]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!mgmtCompanyRecordId) {
      setEntity({});
      return;
    }

    setLoadingEntity(true);
    const request = getMgmtCompany(mgmtCompanyRecordId);

    (async () => {
      try {
        const data = await request;
        setLoadingEntity(false);
        setEntity(data);
      } catch (e) {
        enqueueSnackbar('Could not load selected Company!', { variant: 'error' })
      }
    })();

    return () => { request.cancel(); setLoadingEntity(false) };
  }, [mgmtCompanyRecordId])

  const onConnectionSave = async (recordId) => {
    setLoadingChangeEntity(true);
    try {
      await patchHoa(hoaRecordId, { mgmtCompanyId: recordId });
      onUpdate(recordId);
    }
    catch (e) {
      enqueueSnackbar('Unable to save connection try again! ', { variant: 'warning' });
    }
    setLoadingChangeEntity(false);
  }

  const onSave = async (data) => {
    setLoadingEntity(true);
    try {
      setEntity(await patchMgmtCompany(mgmtCompanyRecordId, data));
      enqueueSnackbar('The Management Company has been updated', { variant: 'success' });
    } catch (e) {
      const message = e.response.data.message;
      enqueueSnackbar(`Cannot update the Management Company: ${message ?? 'Unknown error'}`, { variant: 'error' });
    }
    setLoadingEntity(false);
  }

  const onCreateNew = async (data) => {
    setLoadingEntity(true);
    try {
      let response;

      response = JSON.parse(await newManagementCompany(data));

      setEntity(response);

      setLoadingChangeEntity(true);
      await patchHoa(hoaRecordId, { mgmtCompanyId: response.recordId });
      onUpdate(response.recordId);
      setLoadingChangeEntity(false);

      enqueueSnackbar('The Management Company has been created.', { variant: 'success' });
    } catch (e) {
      const message = e.response.data.message;
      enqueueSnackbar(`Cannot create the Management Company: ${message ?? 'Unknown error'}`, { variant: 'error' });
    }
    setLoadingEntity(false);
  }

  return <Wrapper>
    {(loadingEntity || loadingChangeEntity) && <LoadingOverlay />}
    <MgmtCompanyAssignment
      disabled={hasChangesEntity}
      hoaRecordId={hoaRecordId}
      mgmtCompanyRecordId={mgmtCompanyRecordId}
      onChangeHasChanges={setHasChangesId}
      onSave={onConnectionSave}
    />
    {/* <StyledMgmtCompany {...entity} onChangeHasChanges={setHasChangesEntity} onSave={onSave} /> */}
    {Boolean(!mgmtCompanyRecordId) || <StyledMgmtCompany {...entity} onChangeHasChanges={setHasChangesEntity} onSave={onSave} />}
    {Boolean(mgmtCompanyRecordId) || <StyledNewMgmtCompany {...entity} onChangeHasChanges={setHasChangesEntity} onCreateNew={onCreateNew} />}
    {/* {Boolean(!hasChangesEntity) || <label>You cannot change a Management Company when you have changes in the current Management Company.</label>} */}
  </Wrapper>;
}

export default ManagementCompany;
