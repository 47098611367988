import { get as requestGet } from '../base/request';
import options from '../servers/apiV5';

export const propertySearch = (
    {
        filter: {
            parcel,
            address,
            city,
            state,
            zip,
        } = {}
    } = {}
) =>
    requestGet('propertySearch', options(), {
        parcel_id: parcel ?? undefined,
        street_address: address,
        city: city,
        state: state,
        zip: zip,
    });
export const hasHOA = (
    {
        selected: {
            id,
            parcel_id,
            address,
            unit,
            city,
            state,
            zip_5,
            subdivision,
            fipscd,
            building_sqrft,
            latitude,
            longitude,
            assesed_val,
            address_dummy,
        } = {}
    } = {}
) =>
    requestGet('hasHOA', options(), {
        skipFeeEstimation: false,
        id: id ?? undefined,
        street_address: address,
        city: city,
        state: state,
        zip: zip_5,
        parcel_id: parcel_id ?? undefined,
        subdivision: subdivision ?? undefined,
        fips: fipscd ?? undefined,
        unit: unit ?? undefined,
        building_sqrft: building_sqrft ?? undefined,
        address_dummy: address_dummy ?? undefined,
        latitude: latitude ?? undefined,
        longitude: longitude ?? undefined,
        assessed_value: assesed_val ?? undefined,
    });