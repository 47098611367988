import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import ReactJson from "react-json-view";
import LoadingOverlay from "../../components/Loading/LoadingOverlay";
import { hoaid } from "../../api/endpoints";
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import Results from "./Results";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-left: 10px
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
const { hasHOA: getHasHOA } = hoaid;

const Content = ({ properties, location }) => {
  const [loading, setLoading] = useState(false);
  const [hasHOAloading, setHasHOALoading] = useState(false);
  const [multipleResults, setmultipleResults] = useState(false)
  const [selectedProperty, setSelectedProperty] = useState(0)
  const [hasHOA, setHasHOA] = useState();
  const [resolveHOA, setResolveHOA] = useState();
  const [estimatedFee, setEstimatedFee] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const handleChange = (event) => {
    console.log(typeof event.target.value)
    setSelectedProperty(Number(event.target.value));
  };
  useEffect(() => {
    setHasHOA();
    setEstimatedFee();
    setResolveHOA();
    if (properties) {
      if (properties.results.length == 1) {
        const selected = properties.results[0]
        getAPIResults(selected);
        setmultipleResults(false);
      }
      else {
        setmultipleResults(true)
      }
    }
  }, [properties])
  const getAPIResults = async (selected) => {
    setHasHOALoading(true)
    const request = getHasHOA({ selected });
    (async () => {
      try {
        const results = await request;
        setHasHOA(results?.hasHOA);
        setResolveHOA(results?.resolveHOA);
        setEstimatedFee(results?.estimatedFee);
        setHasHOALoading(false);
      }
      catch (e) {
        enqueueSnackbar(`Could not load hasHOA results. ${e}`, { variant: 'warning' });
        setHasHOALoading(false)
      }
    })();
    return () => { request.cancel(); setHasHOALoading(false); }
  }
  const onSubmit = () => {
    getAPIResults(properties.results[selectedProperty]);
    // setmultipleResults(false) Uncomment to remove the options once a option is selected
  }
  const getLabel = (index, item) => {
    return `${index}. ${item.id} : ${item.address} ${item.unit ?? ''}`
  }
  if (!properties) {
    return <>Please search for an address.</>;
  }

  return (
    <div className="wid_dep">
      <Wrapper>
        {loading && <LoadingOverlay />}
        <ReactJson name={'Property Search Results'} src={properties} theme="bright:inverted" displayDataTypes={false} displayObjectSize={false} />
        {multipleResults ?
          <FormControl>
            <FormLabel style={{ marginTop: 20 }} id="demo-radio-buttons-group-label">Select a Property to Continue:</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={selectedProperty}
              name="radio-buttons-group"
              onChange={handleChange}
            >
              {properties.results.map((item, index) => (
                <FormControlLabel key={index} value={index} control={<Radio />} label={getLabel(index, item)} />
              ))}
            </RadioGroup>
            <Row style={{ marginTop: -20 }}>
              <div class="btn_box rg">
                <Button variant="contained" color="primary" onClick={onSubmit}>Continue</Button>
              </div>
            </Row>
          </FormControl>
          : ''
        }
        <Results hasHOA={hasHOA} resolveHOA={resolveHOA} estimatedFee={estimatedFee} loading={hasHOAloading} />
      </Wrapper>
    </div>
  );
};

export default withRouter(Content);
