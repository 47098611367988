import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from '@material-ui/core';
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { hoa } from "../api";
import LoadingOverlay from "../components/Loading/LoadingOverlay";
import { FIPS, STATES } from '../constants';

const { subdivisionCreate } = hoa;
const STATE_UNKNOWN = 'NA';
const STATE_ANY = 'ANY';
const StyledTextField = styled(TextField)`
  flex-basis: ${props => props.width}%
`;
const StyledFormControl = styled(FormControl)`
  flex-basis: ${props => props.width}%;
`;

const SubdivisionCreateDialog = ({ hoaId, onClose, open }) => {
  const [fips, setFips] = useState('')
  const [name, setName] = useState('')
  const [state, setState] = useState('')
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setIsValid(
      Boolean(fips)
      && Boolean(name)
      && Boolean(state)
    );
  }, [
    fips,
    name,
    state,
  ]);

  const onChangeFips = event => {
    setFips(event.target.value);
  }

  const onChangeState = event => {
    setState(event.target.value);
    setFips('');
    if (event.target.value === 'NA') {
      setFips('99999')
    }
  }

  const CountyList = Object.keys(FIPS).map(function (key) {
    if (state === FIPS[key].state)
      return <MenuItem key={key} value={key}>{FIPS[key].county}</MenuItem>
  });

  const resetProps = () => {
    setName('');
    setFips('')
    setState('')
  }

  const onChangeName = (event) => {
    setName(event.target.value.toUpperCase());
  }

  const handleSave = async () => {
    setLoading(true);

    try {
      const subdivision = await subdivisionCreate(
        hoaId,
        {
          fips,
          name,
        },
      );
      enqueueSnackbar('The Subdivision has been created and connected', { variant: 'success' });
      resetProps();
      onClose(subdivision);
    } catch (e) {
      console.error(e);
      const message = e.response.data.message;
      enqueueSnackbar(`Error while creating the Subdivision: ${message ?? 'Unknown error'}`, { variant: 'error' });
    }

    setLoading(false);
  }

  const handleCancel = () => {
    onClose();
  }

  const onDialogClose = () => {
    if (loading) {
      enqueueSnackbar('Wait the action to be completed', { variant: 'warning' });
    } else {
      onClose();
    }
  }

  return (
    <Dialog onClose={onDialogClose} open={open}>
      {loading && <LoadingOverlay />}
      <div class="h2_popup">
        <DialogTitle>Create and connect Subdivision</DialogTitle>
        <DialogContent>
          <StyledTextField label="Subdivision Name" onChange={onChangeName} value={name} width="50" />
          <TextField label="FIPS" value={fips} disabled />
          <StyledFormControl size="small">
            <InputLabel>State</InputLabel>
            <Select
              value={state}
              onChange={onChangeState}
            >
              <MenuItem value={STATE_UNKNOWN}>-UNKNOWN-</MenuItem>
              {STATES.map(({ code, label }) => <MenuItem key={code} value={code}>{code}</MenuItem>)}
            </Select>
          </StyledFormControl>
          <StyledFormControl size="small">
            <InputLabel>County</InputLabel>
            <Select
              value={fips}
              onChange={onChangeFips}
            >
              <MenuItem value={''}>
                <em>-County-</em>
              </MenuItem>
              {state === STATE_ANY ?
                <MenuItem value={''}>
                  <em>Select A State First</em>
                </MenuItem>
                : <></>
              }
              {CountyList}
            </Select>
          </StyledFormControl>
        </DialogContent>
        <div class="popup_btn">
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button disabled={!isValid} onClick={handleSave}>Save</Button>
          </DialogActions>
        </div>
      </div>
    </Dialog>
  );
}

export default SubdivisionCreateDialog;
