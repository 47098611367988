import { Button, Icon } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import Overlay from "../../components/Overlay";
import SelectorMgmtCompany from "../SelectorMgmtCompany";

const Row = styled.div`
  display: flex;
`;

const StyledButtonLink = styled(Button)`
  flex-basis: 20%;
`;

const StyledSelectorMgmtCompany = styled(SelectorMgmtCompany)`
  flex-basis: 80%;
`;

const Wrapper = styled.div`
  position: relative;
`;

const MgmtCompanyAssignment = ({ disabled, mgmtCompanyRecordId, onChangeHasChanges, onSave }) => {
  const [recordId, setRecordId] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    onChangeHasChanges && onChangeHasChanges(hasChanges);
  }, [hasChanges]);

  useEffect(() => {
    setRecordId(mgmtCompanyRecordId);
  }, [mgmtCompanyRecordId]);

  useEffect(() => {
    setHasChanges(mgmtCompanyRecordId !== recordId);
  }, [mgmtCompanyRecordId, recordId])

  const onCancel = () => {
    setRecordId(mgmtCompanyRecordId);
  }

  const connect = () => {
    onSave(recordId);
  }

  const disconnect = () => {
    onSave(null);
  }

  return <Wrapper>
    <div class="set1_tab">
      {disabled && <Overlay />}
      <Row>
        <div class="wor mt-3">
          <div class="form_group lf ls">
            <StyledSelectorMgmtCompany onChange={setRecordId} value={recordId} />
          </div>
          <div class="form_group rg">
            <div class="btn_box rg">
              <StyledButtonLink
                disabled={!hasChanges}
                onClick={connect}
                color="primary"
                variant="contained"
              >{mgmtCompanyRecordId ? 'Reconnect' : 'Connect'}</StyledButtonLink>
              <StyledButtonLink
                disabled={!hasChanges}
                onClick={onCancel}
                color="secondary"
              >Cancel</StyledButtonLink>
            </div>
          </div>
        </div>
      </Row>
      <div class="btn_box dis">
        <Button disabled={!mgmtCompanyRecordId} onClick={disconnect} color="secondary">Disconnect<Icon>delete</Icon></Button>
      </div>
    </div>
  </Wrapper>;
}

export default MgmtCompanyAssignment;
