import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useSnackbar } from "notistack";
import React, { useState } from 'react';
import { hoa } from "../api";
import LoadingOverlay from "../components/Loading/LoadingOverlay";
import CreateHoa from '../containers/HoaMaster/Tabs/CreateHoa'
const { get: getHoaById, subdivisionConnect } = hoa;

const HoaCreateDialog = ({ subdivisionId, onClose, open }) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleSave = async (hoaId) => {
    setLoading(true);
    try {
      const hoa = await getHoaById(hoaId)
      const subdivision = await subdivisionConnect(hoaId, subdivisionId);
      enqueueSnackbar('The Hoa has been created and connected', { variant: 'success' });
      onClose(hoa);
    } catch (e) {
      console.error(e);
      const message = e.response.data.message;
      enqueueSnackbar(`Error while creating the Hoa: ${message ?? 'Unknown error'}`, { variant: 'error' });
    }
    setLoading(false);
  }

  const handleCancel = async (hoaId) => {
    if (hoaId) {
      await handleSave(hoaId);
    }
    onClose();
  }

  const onDialogClose = () => {
    if (loading) {
      enqueueSnackbar('Wait the action to be completed', { variant: 'warning' });
    } else {
      onClose();
    }
  }
  const onChangeHasChanges = () => {

  }
  return (
    <Dialog onClose={onDialogClose} open={open} maxWidth='md'>
      {loading && <LoadingOverlay />}
      <DialogTitle>Create Hoa</DialogTitle>
      <DialogContent>
        <CreateHoa onChangeHasChanges={onChangeHasChanges} isSubdivision={handleCancel}></CreateHoa>
      </DialogContent>
    </Dialog>
  );
}

export default HoaCreateDialog;
