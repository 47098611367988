import { Button, Icon } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from 'react';
import styled from "styled-components";
import { hoa } from "../../api";
import LoadingOverlay from "../Loading/LoadingOverlay";

const { deleteDocument } = hoa;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ActionButtons = ({ onDisconnect, documentId }) => {
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleOnDisconnect = async () => {
    setLoading(true);

    try {
      await deleteDocument(documentId);
      enqueueSnackbar("Document deleted successfully.", { variant: "success" });
      onDisconnect();
    } catch (e) {
      console.error(e);
      const message = e.response.data.message;
      enqueueSnackbar(`Error while deleting the document: ${ message ?? 'Unknown error' }`, { variant: 'error' });
    }

    setLoading(false);
  }

  return <Wrapper>
    {loading && <LoadingOverlay />}
    <Button color="secondary" onClick={handleOnDisconnect}>
    <Icon>delete</Icon>
    </Button>
  </Wrapper>
}

export default ActionButtons;
