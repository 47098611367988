import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { subdivision } from "../../../api/endpoints";
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";
import KnownProps from "../../../components/KnownProps";
import { useSnackbar } from 'notistack';

const { subdivisionsGetKnown } = subdivision;

const ListWrapper = styled.div`
  flex: 1;
  position: relative;
`;

const SearchWrapper = ListWrapper;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const KnownProp = ({ name, fips }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setItems([]);

    if (!name || !fips) {
      return;
    }

    setLoading(true);
    const request = subdivisionsGetKnown(name, fips);
    (async () => {
      try {
        const response = await request;
        setItems(response.items);
      }
      catch (e) {
        enqueueSnackbar('Could not load associated properties', { variant: 'error' })
      }
      setLoading(false);
    })();

    return () => { request.cancel(); setLoading(false) };
  }, [name, fips]);

  return <Wrapper>
    <ListWrapper>
      {loading && <LoadingOverlay />}
      <KnownProps items={items} ofSubdivision={subdivision} />
    </ListWrapper>
  </Wrapper>;
}

export default KnownProp;
