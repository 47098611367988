import { useSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { mgmtCompany } from "../../../api";
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";
import MgmtCompany from "../../../components/MgmtCompany";
import CompanyDeleteButton from "../../../components/CompanyDeleteButton";

const { get: getMgmtCompany, patch: patchMgmtCompany } = mgmtCompany;

const StyledMgmtCompany = styled(MgmtCompany)`
  padding-bottom: 30px;
`;

const Wrapper = styled.div`
  position: relative;
`;

const CompanyDetails = ({
  mgmtCompanyRecordId,
  onChangeHasChanges,
  onUpdate,
  onDelete,
  ...props
}) => {
  const [loadingEntity, setLoadingEntity] = useState(false);
  const [loadingChangeEntity, setLoadingChangeEntity] = useState(false);
  const [entity, setEntity] = useState({});
  const [hasChangesId, setHasChangesId] = useState(false);
  const [hasChangesEntity, setHasChangesEntity] = useState(false);

  useEffect(() => {
    onChangeHasChanges(hasChangesId || hasChangesEntity);
  }, [hasChangesId, hasChangesEntity]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!mgmtCompanyRecordId) {
      setEntity({});
      return;
    }

    setLoadingEntity(true);
    const request = getMgmtCompany(mgmtCompanyRecordId);

    (async () => {
      try {
        const data = await request;
        setLoadingEntity(false);
        setEntity(data);
      }
      catch (e) {
        enqueueSnackbar("Could not load the selected management company!", { variant: 'error' })
      }
    })();

    return () => { request.cancel(); setLoadingEntity(false) };
  }, [mgmtCompanyRecordId])

  const onSave = async (data) => {
    setLoadingEntity(true);
    try {
      setEntity(await patchMgmtCompany(mgmtCompanyRecordId, data));
      enqueueSnackbar('The Management Company has been updated', { variant: 'success' });
      data = { ...data, recordId: mgmtCompanyRecordId }
      onUpdate(data);
    } catch (e) {
      const message = e.response.data.message;
      enqueueSnackbar(`Cannot update the Management Company: ${message ?? 'Unknown error'}`, { variant: 'error' });
    }
    setLoadingEntity(false);
  }

  return <Wrapper>
    {(loadingEntity || loadingChangeEntity) && <LoadingOverlay />}
    {Boolean(!mgmtCompanyRecordId) || <StyledMgmtCompany {...entity} onChangeHasChanges={setHasChangesEntity} onSave={onSave} />}
    <CompanyDeleteButton name={props.name} recordId={mgmtCompanyRecordId} onDelete={onDelete} />
  </Wrapper>;
}

export default CompanyDetails;
