import { useSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { hoaid } from "../../api/endpoints";
import LoadingOverlay from "../../components/Loading/LoadingOverlay";
import { ROUTES } from "../../constants";
import Content from "./Content";
import Filter from "./Filter";
import Unauthorized from '../../routes/Unauthorized';
import { useAuth } from '../../utils/auth'

const { propertySearch: getPropertySearch, hasHOA: getHasHOA } = hoaid;

const { HOAID } = ROUTES;

const Wrapper = styled.div`
  display: flex !important;
  height: 100% !important;
  background-color: #F0F7FF !important;
  padding: 22px 0px !important;
`;
const LeftBar = styled.div`
  flex-basis: 30% !important;
  width:30%;
  display: flex !important;
  flex-direction: column !important;
  // overflow: hidden !important;
  position: relative !important;
`;
const RightBar = styled.div`
  flex-basis: 70% !important;
  overflow: auto;
  width:70%;
  background-color: rgb(235, 243, 252) !important;
`;


const ManagementCompanies = ({ location, match }) => {
  const { userData } = useAuth();
  const [initialized, setInitialized] = useState(false);
  const [items, setItems] = useState();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [leftSidebar, setLeftSidebar] = useState(true)
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    setInitialized(true);
  }, [])

  useEffect(() => {
    if (!initialized) {
      return;
    }
    setLoading(true);

    const request = getPropertySearch({ filter });
    (async () => {
      try {
        const properties = await request;
        setItems(properties);
        setLoading(false);
      }
      catch (e) {
        enqueueSnackbar(`Could not load property search results. ${e}`, { variant: 'warning' });
        setLoading(false)
      }
    })();
    return () => { request.cancel(); setLoading(false); }

  }, [filter]);
  const handleViewSidebar = () => {
    setLeftSidebar(!leftSidebar);
  };
  const onChangeFilter = (filter) => {
    setFilter(filter);
  }

  if (!userData.group) {
    return <Unauthorized />
  }
  return (
    <Wrapper>
      <LeftBar className={leftSidebar ? 'sidebar open' : 'sidebar'}>
        {loading && <LoadingOverlay />}
        <Filter onSubmit={onChangeFilter} />
        <button onClick={handleViewSidebar} className={leftSidebar ? "sidebar-toggle open" : "sidebar-toggle"}>
          <i className={leftSidebar ? "arrow left" : 'arrow right'}></i>
        </button>
      </LeftBar>
      <RightBar className={leftSidebar ? 'content open' : 'content'}>
        <Content
          properties={items}
        />
      </RightBar>
    </Wrapper>
  );
}

export default withRouter(ManagementCompanies);
