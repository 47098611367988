import { CircularProgress } from "@material-ui/core";
import React from 'react';
import styled from 'styled-components';

const sizes = {
  lg: {
    root: '64px',
    div: '51px',
    border: '3px',
    margin: '6px',
  },
  md: {
    root: '34px',
    div: '25px',
    border: '3px',
    margin: '6px',
  },
  sm: {
    root: '22px',
    div: '13px',
    border: '2px',
    margin: '5px',
  },
};

const HalfScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

const Root = styled.div`
  display: inline-block;
  position: relative;
  width: ${({ size }) => sizes[size].root};
  height: ${({ size }) => sizes[size].root};
  z-index: 25;
`;

const LoadingRing = ({ halfScreen, color = '#00cad1', size = 'lg' }) => {
  const ring = (
    <Root size={size} color={color}>
      <CircularProgress />
    </Root>
  );

  return halfScreen ? <HalfScreen>{ring}</HalfScreen> : ring;
};

export default LoadingRing;
