import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { STATES } from "../../constants";
import Overlay from "../Overlay";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTextField = styled(TextField)`
  flex-basis: ${props => props.width}%
`;

const StyledFormControl = styled(FormControl)`
  flex-basis: ${props => props.width}%;
`;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

const STATE_UNKNOWN = 'UNKNOWN';

const MgmtCompany = ({
  code,
  className,
  onChangeHasChanges,
  onSave,
  recordId,
  ...props
}) => {
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [ein, setEin] = useState('');
  const [updatedDate, setUpdatedDate] = useState('');
  const [name, setName] = useState('');
  const [notes, setNotes] = useState('');
  const [state, setState] = useState('');
  const [website, setWebsite] = useState('');
  const [zip, setZip] = useState('');
  const [zip4, setZip4] = useState('');

  const [hasUpdate, setHasUpdate] = useState(true);

  useEffect(() => {
    resetFromProps();
  }, [
    props.address,
    props.city,
    props.ein,
    props.name,
    props.notes,
    props.state,
    props.updatedDate,
    props.website,
    props.zip,
    props.zip4,
    recordId,
  ]);

  useEffect(() => {
    const anyFieldHadChanged =
      (address !== props.address && (address !== '' || props.address !== null))
      || (city !== props.city && (city !== '' || props.city !== null))
      || (ein !== props.ein && (ein !== '' || props.ein !== null))
      || (name !== props.name && (name !== '' || props.name !== null))
      || (notes !== props.notes && (notes !== '' || props.notes !== null))
      || (state !== props.state && (props.state !== null || state !== STATE_UNKNOWN))
      || (website !== props.website && (website !== '' || props.website !== null))
      || (zip !== props.zip && (zip !== '' || props.zip !== null))
      || (zip4 !== props.zip4 && (zip4 !== '' || props.zip4 !== null));
    setHasUpdate(anyFieldHadChanged && Boolean(recordId));
  }, [
    address,
    city,
    ein,
    name,
    notes,
    state,
    website,
    zip,
    zip4,
    props.address,
    props.city,
    props.ein,
    props.name,
    props.notes,
    props.state,
    props.website,
    props.zip,
    props.zip4,
  ]);

  useEffect(() => {
    onChangeHasChanges(hasUpdate);
  }, [hasUpdate])

  const onSubmit = () => {
    onSave({
      address,
      city,
      ein,
      name,
      notes,
      state,
      website,
      zip,
      zip4,
    });
  }

  const resetFromProps = () => {
    setAddress(props.address);
    setCity(props.city);
    setEin(props.ein);
    setUpdatedDate(props.updatedDate ? moment(props.updatedDate).format('MM/DD/YYYY') : '');
    setName(props.name);
    setNotes(props.notes);
    setState(props.state || STATE_UNKNOWN);
    setWebsite(props.website);
    setZip(props.zip);
    setZip4(props.zip4);
  }

  const onChangeAddress = event => {
    setAddress(event.target.value.toUpperCase());
  }

  const onChangeCity = event => {
    setCity(event.target.value.toUpperCase());
  }

  const onChangeEin = event => {
    setEin(event.target.value);
  }

  const onChangeName = event => {
    setName(event.target.value.toUpperCase());
  }

  const onChangeNotes = event => {
    setNotes(event.target.value);
  }

  const onChangeState = event => {
    setState(event.target.value);
  }

  const onChangeWebsite = event => {
    setWebsite(event.target.value.toUpperCase());
  }

  const onChangeZip = event => {
    setZip(event.target.value);
  }

  const onChangeZip4 = event => {
    setZip4(event.target.value);
  }

  return <Wrapper className={className}>
    {Boolean(recordId) || <Overlay text="You need to choose a Management Company first" />}
    <div class="set1_tab">
      <Row>
        <div class="wor mt-3">
          <div class="form_group lf">
            <StyledTextField width={85} size="small" label="Management Company Name" value={name || ''} onChange={onChangeName} />
          </div>
          <div class="form_group rg">
            <StyledTextField width={15} size="small" label="Management Co. Code" disabled value={recordId || ''} />
          </div>
        </div>
      </Row>
      <Row>
        <div class="wor mt-3">
          <div class="form_group lf">
            <StyledTextField width={85} size="small" label="Address" value={address || ''} onChange={onChangeAddress} />
          </div>
        </div>
      </Row>
      <Row>
        <div class="wor sec mt-3">
          <div class="twnf">
            <StyledTextField width={50} size="small" label="City" value={city || ''} onChange={onChangeCity} />
          </div>
          <div class="twnf">
            <StyledFormControl width={15} size="small">
              <InputLabel>State</InputLabel>
              <Select
                value={state}
                onChange={onChangeState}
              >
                <MenuItem value={STATE_UNKNOWN}>-UNKNOWN-</MenuItem>
                {STATES.map(({ code, label }) => <MenuItem key={code} value={code}>{code}</MenuItem>)}
              </Select>
            </StyledFormControl>
          </div>
          <div class="form_group twnf">
            <StyledTextField width={15} size="small" label="Zip" value={zip || ''} onChange={onChangeZip} />
          </div>
          <div class="form_group twnf">
            <StyledTextField width={15} size="small" label="Zip + 4" value={zip4 || ''} onChange={onChangeZip4} />
          </div>
        </div>
      </Row>
      <Row>
        <div class="wor trd mt-3">
          <div class="form_group fif">
            <StyledTextField width={50} size="small" label="Website" value={website || ''} onChange={onChangeWebsite} />
          </div>
          <div class="form_group fif">
            <StyledTextField width={50} size="small" label="EIN" value={ein || ''} onChange={onChangeEin} />
          </div>
        </div>
      </Row>
      <Row>
        <div class="wor mt-3">
          <div class="form_group lf">
            <StyledTextField width={70} size="small" label="Management Company Notes" multiline rows={7} value={notes || ''} onChange={onChangeNotes} />
          </div>
          <div class="form_group rg">
            <StyledTextField disabled label="Last Verified Date" value={updatedDate || ''} width={15} />
          </div>
        </div>
      </Row>
      <Row>
        <div class="btn_box rg">
          <Button variant="contained" color="primary" disabled={!hasUpdate} onClick={onSubmit}>Save</Button>
          <Button color="secondary" disabled={!hasUpdate} onClick={resetFromProps}>Cancel</Button>
        </div>
      </Row>
    </div>
  </Wrapper>;
}

export default MgmtCompany;
