import qs from 'qs';
import React, { useEffect } from 'react';
import { useAuth } from '../utils/auth';
import Loading from './Loading';

const AuthCallback = ({ location }) => {
  const { authCallback } = useAuth();

  useEffect(() => {
    const { code } = qs.parse(location.search, { ignoreQueryPrefix: true });
    authCallback(code);
  }, []);


  return <Loading />
}

export default AuthCallback;
