import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAuth } from '../utils/auth';
import AuthCallback from './AuthCallback';
import AuthenticatedApp from './AuthenticatedApp';
import Loading from './Loading';
import Login from './Login';
import LogoutCallback from './LogoutCallback';
import Page404 from './Page404';

const Routes = () => {
  const { loading } = useAuth();
  if (loading) {
    return <Loading />
  }

  return <Switch>
    <Route path='/auth_callback' component={AuthCallback} />
    <Route exact path='/login' component={Login} />
    <Route path='/logout_callback' component={LogoutCallback} />
    <Route exact path='/404' component={Page404} />
    <Route path='/' render={props => <AuthenticatedApp {...props} />} />
  </Switch>
};

export default Routes;
