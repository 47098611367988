import { useSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { mgmtCompany } from "../../api/endpoints";
import MgmtList from "../../components/MgmtList";
import LoadingOverlay from "../../components/Loading/LoadingOverlay";
import { ROUTES } from "../../constants";
import Content from "./Content";
import Filter from "./Filter";
import Unauthorized from '../../routes/Unauthorized';
import { useAuth } from '../../utils/auth'
import CreateCompany from "./Tabs/CreateCompany";

//latest add
import { Button } from "@material-ui/core";
import { Pagination } from "@mui/material";

const { getAll: getMgmtCompanies } = mgmtCompany;

const { MANAGEMENT } = ROUTES;

const Wrapper = styled.div`
  display: flex !important;
  height: 100% !important;
  background-color: #F0F7FF !important;
  padding: 22px 0px !important;
`;
const LeftBar = styled.div`
  flex-basis: 30% !important;
  width:30%;
  display: flex !important;
  flex-direction: column !important;
  // overflow: hidden !important;
  position: relative !important;
`;
const RightBar = styled.div`
  flex-basis: 70% !important;
  overflow: auto;
  width:70%;
  background-color: rgb(235, 243, 252) !important;
`;

const StyledMgmtList = styled(MgmtList)`
  overflow-y: auto !important;
`;

const ManagementCompanies = ({ location, match }) => {
  const { userData } = useAuth();
  const [hasChanges, setHasChanges] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [selectedItemId, setSelectedItemId] = useState();
  const [totalResults, setTotalResults] = useState();

  const [showCreateManagement, setShowCreateManagement] = useState(true);
  const [disableCreateMgmtButton, setDisableCreateMgmtButton] = useState(true)
  const [leftSidebar, setLeftSidebar] = useState(true)
  const [standaloneCreate, setStandaloneCreate] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    setInitialized(true);
  }, [])

  useEffect(() => {
    if (!initialized) {
      return;
    }
    setLoading(true);

    const request = getMgmtCompanies({ filter });
    (async () => {
      try {
        const companies = await request;
        setPages(companies?.meta?.totalItems / 20);
        setTotalResults(companies?.meta?.totalItems);
        setItems(companies.items);
        setLoading(false);
      }
      catch (e) {
        enqueueSnackbar(`Could not load Companies. Error : ${e}`, { variant: 'warning' });
        setLoading(false)
      }
    })();
    return () => { request.cancel(); setLoading(false); }

  }, [filter]);

  useEffect(() => {
    if (items.length == 1 && selectedItemId == null) {
      setSelectedItemId(items[0].recordId)
      onSelect(items[0].recordId);
    }

    if (!selectedItemId) {
      return;
    }
    if (!items.find(({ recordId }) => recordId === selectedItemId)) {
      enqueueSnackbar(`Cannot find the Management Comapany ${selectedItemId} amongst the currently filtered records.`, { variant: 'warning' });
    }
  }, [items]);

  useEffect(() => {
    setSelectedItemId(match.params.recordId ? Number(match.params.recordId) : null);
  }, [match.params.recordId])

  const onChangeFilter = (filter) => {
    setSelectedItemId(null) //bug
    if (!showCreateManagement) {
      setShowCreateManagement(true)
    }
    if (!hasChanges) {
      setFilter(filter);
      setPage(1);
      setDisableCreateMgmtButton(false)
      setStandaloneCreate(false)
    } else {
      enqueueSnackbar('You need to save or cancel the changes first.', { variant: 'error' });
    }
  }

  const onSelect = (id) => {
    if (!hasChanges) {
      history.push(`${MANAGEMENT}/${id}${location.search}${location.hash}`);
    } else {
      enqueueSnackbar('You need to save or cancel the changes first.', { variant: 'error' });
    }
  }

  const onDelete = () => {
    history.push(`${MANAGEMENT}${location.search}${location.hash}`);
    setItems(items.filter((item) => item.recordId !== selectedItemId));
  }

  // @todo Use redux for it here instead.
  const onUpdate = (updatedData) => {
    setItems(items.map((item) => {
      if (item.recordId === selectedItemId) {
        return updatedData;
      }
      return item;
    }));
  }

  //latest add
  const handleCreateManagement = () => {
    if (showCreateManagement) {
      setShowCreateManagement(false)
    } else {
      setShowCreateManagement(true)
    }
    if (selectedItemId == null) {
      setStandaloneCreate(!standaloneCreate)
    }
  }
  const changeOffset = (event, value) => {
    setFilter({ ...filter, offset: (value - 1) * 20 })
    setPage(value);
    history.push(`${MANAGEMENT}/${location.search}${location.hash}`);
  }
  const handleViewSidebar = () => {
    setLeftSidebar(!leftSidebar);
  };
  useEffect(() => {
    setShowCreateManagement(showCreateManagement)
  }, [showCreateManagement])


  if (!userData.group) {
    return <Unauthorized />
  }
  return (
    <Wrapper>
      <LeftBar className={leftSidebar ? 'sidebar open' : 'sidebar'}>
        {loading && <LoadingOverlay />}
        <Filter hasChanges={hasChanges} onSubmit={onChangeFilter} totalItems={totalResults} />
        <div class="cre_btn">
          <Button disabled={disableCreateMgmtButton || hasChanges} variant="contained" color="primary" onClick={handleCreateManagement} >{showCreateManagement ? 'Create new Company' : 'back'}</Button>
        </div>
        <div class="sec_filt">
          <StyledMgmtList
            items={items.map(i => ({ recordId: i.recordId, name: i.name, address: i.address, state: i.state, city: i.city }))}
            selectedItemId={selectedItemId}
            onSelect={onSelect}
          />
        </div>
        <Pagination style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 10 }} color='standard' onChange={changeOffset} count={Math.ceil(pages)} page={page} />
        <button onClick={handleViewSidebar} className={leftSidebar? "sidebar-toggle open": "sidebar-toggle"}>
          <i className={leftSidebar ? "arrow left" : 'arrow right'}></i>
        </button>
      </LeftBar>
      <RightBar className={leftSidebar ? 'content open' : 'content'}>
        <Content
          company={items.find(({ recordId }) => recordId === selectedItemId)}
          onChangeHasChanges={setHasChanges}
          onDelete={onDelete}
          onUpdate={onUpdate}
          ItemsList={res => {
            items.unshift(res);
            history.push(`${MANAGEMENT}/${items[0].recordId}${location.search}${location.hash}`);
          }}
          show={showCreateManagement}
          showFunction={res => setShowCreateManagement(res)}
          totalItems={totalResults}
          isStandaloneCreate={standaloneCreate}
        />
        {standaloneCreate ?
          <div class="wid_dep">
            <h4 className="hd_box">Create Management Company</h4>
            <CreateCompany
              standalone
              onChangeHasChanges={setHasChanges}
              onDelete={onDelete}
              onUpdate={onUpdate}
              changeShow={(res) => handleCreateManagement(res)}
              companyItemsList={res => {
                items.unshift(res)
              }}
            />
          </div> : ''}
      </RightBar>
    </Wrapper>
  );
}

export default withRouter(ManagementCompanies);
