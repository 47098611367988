import React from 'react';
import { mgmtCompany } from "../../api/endpoints";
import Autocomplete from "../../components/Autocomplete";

const { get: getMgmtCompany, getAll: getMgmtCompanies } = mgmtCompany;

const subLabelTransformer = (item) => {
  let result = `${item.state ? item.state : ''} ${item.address ? item.address : ''}`;
  return result.trim();
}

const SelectorMgmtCompany = ({ className, disabled = false, onChange, value }) => {
  const autocompleteGetter = ({ text, value }) => {
    let request;
    let result;
    if (typeof text !== 'undefined') {
      request = getMgmtCompanies({ filter: { name: text } });
      result = request.then(({ items }) => items.map(item => ({ label: item.name, subLabel: subLabelTransformer(item), value: item.recordId })));
    } else {
      request = getMgmtCompany(value);
      result = request.then((item) => [{ label: item.name, subLabel: subLabelTransformer(item), value: item.recordId }]);
    }

    const { cancel } = request;
    result.cancel = cancel;

    return result;
  }

  return <Autocomplete
    className={className}
    disabled={disabled}
    getter={autocompleteGetter}
    label="Management Company Name"
    onChange={onChange}
    value={value}
  />
}

export default SelectorMgmtCompany;
