import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import SubDeleteDialog from "./SubDeleteDialog";

const SubDeleteButton = ({ onDelete, recordId, name }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (isDeleted = false) => {
    if (isDeleted) {
      onDelete();
    }
    setOpen(false);
  };

  return (
    <div>
      <div class="btn_box delt_sub">
        <Button color="secondary" onClick={handleClickOpen} variant="contained">
          Delete Subdivision
        </Button>
        <SubDeleteDialog recordId={recordId} name={name} open={open} onClose={handleClose} />
      </div>
    </div>
  );
}

export default SubDeleteButton;
