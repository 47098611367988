import axios from "axios";
import config from "../../config";

const {
  auth: {
    authCallbackUri,
    baseUrl,
    clientId,
  }
} = config;

export const getTokensByAccessCode = async (code) => {
  const url = `${baseUrl}/oauth2/token?grant_type=authorization_code&client_id=${clientId}&code=${code}&redirect_uri=${authCallbackUri}`

  const { data: tokensData } = await axios
    .post(url, {}, {
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    });

  const {
    access_token: accessToken,
    id_token: idToken,
    refresh_token: refreshToken,
  } = tokensData;

  return {
    accessToken,
    idToken,
    refreshToken,
  }
}

export const getTokensByRefreshToken = async (tokenRefresh) => {
  const url = `${baseUrl}/oauth2/token?grant_type=refresh_token&client_id=${clientId}&refresh_token=${tokenRefresh}`;
  const { data: tokensData } = await axios
    .post(
      url,
      {},
      { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
    );
  const {
    access_token: accessToken,
    id_token: idToken,
  } = tokensData;

  return {
    accessToken,
    idToken,
  }
}
