import {
  Button,
  FormControl,
  Icon,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { hoa } from "../api/endpoints";
import { subdivisionConnect } from "../api/endpoints/hoa";
import LoadingOverlay from "../components/Loading/LoadingOverlay";
import HoaAddButton from "../components/HoaAddButton";
import { STATES } from "../constants";
import GridCellExpand from "../components/GridCellExpand.js";
import PropTypes from 'prop-types';
const { getHoas } = hoa;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const STATE_UNKNOWN = 'UNKNOWN';

const useStyles = makeStyles({
  highlightRow: {
    backgroundColor: '#dddddd',
  },
});

const HoaSearch = ({ defaultState, forSubdivision, onAdd, hoasAttached }) => {
  function renderCellExpand(params) {
    // const county = FIPS[params?.row?.fips] ? FIPS[params?.row?.fips].county : 'INCORRECT FIPS CODE';
    return (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
  }
  const columns = [
    {
      field: 'name',
      headerName: 'HOA Name',
      renderCell: ({ row: { name } }) => <GridCellExpand value={name} width={300} />,
      width: 300,
      filterable: false,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 120,
    },
    {
      field: 'id',
      headerName: 'HOA Code',
      width: 150,
    },
    {
      field: 'addressFull',
      headerName: 'Address',
      renderCell: ({ row: { addressFull } }) => <GridCellExpand value={addressFull} width={300} />,
      width: 300,
      filterable: false
    },
    {
      field: 'city',
      headerName: 'City',
      renderCell: ({ row: { city } }) => <GridCellExpand value={city} width={150} />,
      width: 150,
      filterable: false,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 110,
    },
    {
      field: 'action',
      headerName: 'Action',
      renderCell: ({ row: { recordId } }) => <Button color="primary" disabled={isSubdivisionAttached(recordId)} onClick={handleOnConnect(recordId)}>
        <Icon>link</Icon>
      </Button>,
      width: 120,
    },
  ];

  const classes = useStyles();

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [requestedAnything, setRequestedAnything] = useState(false);
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');

  useEffect(() => {
    setState(defaultState);
    setItems([]);
    setRequestedAnything(false);
  }, [defaultState]);
  useEffect(() => {
    setState(defaultState);
    setItems([]);
    setName('');
    setZip('');
    setCity('');
    setAddress('');
    setRequestedAnything(false);
  }, [forSubdivision]);
  const handleNameChange = event => {
    setName(event.target.value);
  }
  const handleCityChange = event => {
    setCity(event.target.value);
  }
  const handleZipChange = event => {
    setZip(event.target.value);
  }
  const handleAddressChange = event => {
    setAddress(event.target.value);
  }
  const handleStateChange = event => {
    setState(event.target.value);
  }

  const handleKeyUp = event => {
    if (event.key === 'Enter') {
      handleOnSearch();
    }
  }

  const handleOnSearch = async () => {
    setLoading(true);

    const request = getHoas({ filter: { name, state: state === STATE_UNKNOWN ? '' : state, address, city, zip } });
    (async () => {
      const hoas = await request;
      setItems(hoas.items);
      setLoading(false);
    })();
    setRequestedAnything(true);
  }

  const handleOnConnect = (hoaId) => async () => {
    setLoading(true);
    try {
      const subdivision = await subdivisionConnect(hoaId, forSubdivision);
      onAdd(items.find(hoa => hoa.recordId === hoaId));
      setLoading(false);
    }
    catch (e) {
      //add enqueueSnackbar to display error to user
      setLoading(false);
    }
  }

  const isSubdivisionAttached = (recordId) =>
    Boolean(hoasAttached.find(hoa => hoa.recordId === recordId));

  return <Wrapper>
    {loading && <LoadingOverlay />}
    <h3 style={{ marginTop: 30, marginBottom: 0, marginLeft: 20 }} class="hd_tg">Search HOAs</h3>
    <Header>

      <div class="flx_arg">
        <div class="form_item_fr">
          <div class="mb-2">
            <TextField size="small" fullWidth label="HOA Name" variant="outlined" value={name} onChange={handleNameChange} onKeyUp={handleKeyUp} />
          </div>
        </div>
        <div class="form_item_fr">
          <div class="mb-2">
            <TextField size="small" fullWidth label="Address" variant="outlined" value={address} onChange={handleAddressChange} onKeyUp={handleKeyUp} />
          </div>
        </div>
        <div class="form_item_fr">
          <div class="mb-2">
            <TextField size="small" fullWidth label="City" variant="outlined" value={city} onChange={handleCityChange} onKeyUp={handleKeyUp} />
          </div>
        </div>
        <div style={{ marginBottom: -7 }} class="dif_two">
          <FormControl variant="outlined" size="small">
            <InputLabel>State</InputLabel>
            <Select
              value={state}
              onChange={handleStateChange}
            >
              <MenuItem value={STATE_UNKNOWN}>
                <em>-UNKNOWN-</em>
              </MenuItem>
              {STATES.map(({ code, label }) => <MenuItem key={code} value={code}>{code}</MenuItem>)}
            </Select>
          </FormControl>
        </div>
        <div class="form_item_fr">
          <div class="mb-2">
            <TextField size="small" fullWidth label="Zip" variant="outlined" value={zip} onChange={handleZipChange} onKeyUp={handleKeyUp} />
          </div>
        </div>
        <div class="dif_thre">
          <Button color="primary" onClick={handleOnSearch}>
            <Icon>search</Icon>
          </Button>
        </div>
        <div class="dif_four">
          <HoaAddButton subdivisionId={forSubdivision} onAdd={onAdd} />
        </div>
      </div>

    </Header>

    {requestedAnything && <DataGrid
      disableColumnFilter
      columns={columns}
      getRowClassName={params => isSubdivisionAttached(params.row.recordId) ? classes.highlightRow : ''}
      rows={items.map((record) => ({ id: record.recordId, ...record }))}
      rowsPerPageOptions={[]}
    />}
  </Wrapper>;
}

export default HoaSearch;