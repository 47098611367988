import FIPS from './fips';

export const CONTACT_TYPES = {
  hoa: 'hoa',
  mgmtCompany: 'mgmtCompany',
};

export { FIPS };

export const LOCAL_STORAGE_KEY_AUTH_TOKEN_ACCESS = 'authTokenAccess';
export const LOCAL_STORAGE_KEY_AUTH_TOKEN_ID = 'authTokenId';
export const LOCAL_STORAGE_KEY_AUTH_TOKEN_REFRESH = 'authTokenRefresh';
export const LOCAL_STORAGE_KEY_AUTH_TOKEN_REFRESH_EXPIRES_AT = 'authTokenRefreshExpiresAt';
export const LOCAL_STORAGE_KEY_REDIRECT_PATH_AFTER_LOGIN = 'redirectPathAfterLogin';

export const ORDERING_INFO_DELIVERY_TYPES = {
  ALL: 'ALL',
  BUILDER: 'BUILDER',
  DOCUMENT: 'DOCUMENT',
  'FULL PACKAGE': 'FULL PACKAGE',
  REFINANCE: 'REFINANCE',
  STATEMENT: 'STATEMENT',
};

export const ORDERING_INFO_DELIVERY_TYPE_DEFAULT = ORDERING_INFO_DELIVERY_TYPES.ALL;

export const ORDERING_INFO_PROCESS_METHODS = {
  Email: 'Email',
  Fax: 'Fax',
  Mail: 'Mail',
  Phone: 'Phone',
  Website: 'Website',
};

export const ORDERING_INFO_TYPES = {
  hoa: 'hoa',
  mgmtCompany: 'mgmtCompany',
};

export const REGEXP_EMAIL = '.+@.+\\..+';
export const REGEXP_PHONE_NUMBER = '^[0-9]{10}$';
export const REGEXP_WEBSITE = '.+\\..+';

export const ROUTES = {
  HOA_MASTER: '/hoa-master',
  SUB_MASTER: '/sub-master',
  HOAID: '/hoaid',
  MANAGEMENT: '/management-companies',
};

export const STATES = [
  { code: 'AL', fipsCode: '01', label: 'ALABAMA', },
  { code: 'AK', fipsCode: '02', label: 'ALASKA', },
  { code: 'AZ', fipsCode: '04', label: 'ARIZONA', },
  { code: 'AR', fipsCode: '05', label: 'ARKANSAS', },
  { code: 'CA', fipsCode: '06', label: 'CALIFORNIA', },
  { code: 'CO', fipsCode: '08', label: 'COLORADO', },
  { code: 'CT', fipsCode: '09', label: 'CONNECTICUT', },
  { code: 'DE', fipsCode: '10', label: 'DELAWARE', },
  { code: 'DC', fipsCode: '11', label: 'DISTRICT OF COLUMBIA', },
  { code: 'FL', fipsCode: '12', label: 'FLORIDA', },
  { code: 'GA', fipsCode: '13', label: 'GEORGIA', },
  { code: 'HI', fipsCode: '15', label: 'HAWAII', },
  { code: 'ID', fipsCode: '16', label: 'IDAHO', },
  { code: 'IL', fipsCode: '17', label: 'ILLINOIS', },
  { code: 'IN', fipsCode: '18', label: 'INDIANA', },
  { code: 'IA', fipsCode: '19', label: 'IOWA', },
  { code: 'KS', fipsCode: '20', label: 'KANSAS', },
  { code: 'KY', fipsCode: '21', label: 'KENTUCKY', },
  { code: 'LA', fipsCode: '22', label: 'LOUISIANA', },
  { code: 'ME', fipsCode: '23', label: 'MAINE', },
  { code: 'MD', fipsCode: '24', label: 'MARYLAND', },
  { code: 'MA', fipsCode: '25', label: 'MASSACHUSETTS', },
  { code: 'MI', fipsCode: '26', label: 'MICHIGAN', },
  { code: 'MN', fipsCode: '27', label: 'MINNESOTA', },
  { code: 'MS', fipsCode: '28', label: 'MISSISSIPPI', },
  { code: 'MO', fipsCode: '29', label: 'MISSOURI', },
  { code: 'MT', fipsCode: '30', label: 'MONTANA', },
  { code: 'NE', fipsCode: '31', label: 'NEBRASKA', },
  { code: 'NV', fipsCode: '32', label: 'NEVADA', },
  { code: 'NH', fipsCode: '33', label: 'NEW HAMPSHIRE', },
  { code: 'NJ', fipsCode: '34', label: 'NEW JERSEY', },
  { code: 'NM', fipsCode: '35', label: 'NEW MEXICO', },
  { code: 'NY', fipsCode: '36', label: 'NEW YORK', },
  { code: 'NC', fipsCode: '37', label: 'NORTH CAROLINA', },
  { code: 'ND', fipsCode: '38', label: 'NORTH DAKOTA', },
  { code: 'OH', fipsCode: '39', label: 'OHIO', },
  { code: 'OK', fipsCode: '40', label: 'OKLAHOMA', },
  { code: 'OR', fipsCode: '41', label: 'OREGON', },
  { code: 'PA', fipsCode: '42', label: 'PENNSYLVANIA', },
  { code: 'RI', fipsCode: '44', label: 'RHODE ISLAND', },
  { code: 'SC', fipsCode: '45', label: 'SOUTH CAROLINA', },
  { code: 'SD', fipsCode: '46', label: 'SOUTH DAKOTA', },
  { code: 'TN', fipsCode: '47', label: 'TENNESSEE', },
  { code: 'TX', fipsCode: '48', label: 'TEXAS', },
  { code: 'UT', fipsCode: '49', label: 'UTAH', },
  { code: 'VT', fipsCode: '50', label: 'VERMONT', },
  { code: 'VA', fipsCode: '51', label: 'VIRGINIA', },
  { code: 'WA', fipsCode: '53', label: 'WASHINGTON', },
  { code: 'WV', fipsCode: '54', label: 'WEST VIRGINIA', },
  { code: 'WI', fipsCode: '55', label: 'WISCONSIN', },
  { code: 'WY', fipsCode: '56', label: 'WYOMING', },
  { code: 'AS', fipsCode: '60', label: 'AMERICAN SAMOA', },
  { code: 'GU', fipsCode: '66', label: 'GUAM', },
  { code: 'PR', fipsCode: '72', label: 'PUERTO RICO', },
  { code: 'VI', fipsCode: '78', label: 'VIRGIN ISLANDS', },
];

export const HOA_CATEGORIES = {
  MASTER: 'Master',
  STANDALONE: 'Stand-alone',
  SUB: 'Sub',
};

export const HOA_CATEGORY_DEFAULT = HOA_CATEGORIES.STANDALONE;

export const HOA_TYPES = {
  COA: 'COA',
  HOA: 'HOA',
  PUD: 'PUD',
  CDD: 'CDD',
};

export const DOCUMENTTYPE = [
  { id: 1, text: 'W9' },
  { id: 2, text: 'CCR' },
  { id: 3, text: 'BYLAWS' },
  { id: 4, text: 'FINANCIAL' },
  { id: 5, text: 'MEETING MINUTES' },
  { id: 6, text: 'INSURANCE' },
  { id: 7, text: 'OTHER' },
];

export const VALID_DOCUMENT_TYPES = [
  'jpg',
  'jpeg',
  'gif',
  'png',
  'bmp',
  'tiff',
  'doc',
  'docx',
  'docm',
  'dot',
  'dotx',
  'dotm',
  'xls',
  'xlsx',
  'xlsm',
  'xlt',
  'xltx',
  'xltm',
  'xlsb',
  'xla',
  'xlam',
  'ppt',
  'pptx',
  'pptm',
  'pot',
  'potx',
  'potm',
  'ppa',
  'ppam',
  'pps',
  'ppsx',
  'ppsm',
  'pdf',
  'txt',
  'rtf',
  'zip',
  'txf'
];

export const MAXIMUM_UPLOAD_SIZE = {
  SIZE: 125 * 1048576
}

export const HOA_TYPE_DEFAULT = HOA_TYPES.PUD;
