import { Button, Icon } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import { hoa } from "../../api";
import LoadingOverlay from "../../components/Loading/LoadingOverlay";
import { HOA_CATEGORIES } from "../../constants";
import Add from "./Add";

const { getHoas, patch } = hoa;

const SubAssociation = ({ recordId }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    {
      field: 'name',
      flex: 7,
      headerName: 'HOA Name',
    },
    {
      field: 'action',
      flex: 1,
      headerName: 'Action',
      renderCell: ({ row: { recordId: subRecordId }}) =>
        <Button onClick={onRemove(subRecordId)} color="secondary"><Icon>delete</Icon></Button>
      ,
    },
  ];

  const onRemove = (subRecordId) => async () => {
    setLoading(true);
    await patch(subRecordId, { category: HOA_CATEGORIES.STANDALONE, masterId: null });
    setItems(items.filter(({ recordId: id }) => id !== subRecordId));
    enqueueSnackbar('The Sub HOA has been removed', { variant: 'success' });
    setLoading(false);
  }

  const onAdd = (entity) => {
    setItems([ ...items, { id: entity.recordId, ...entity } ]);
  }

  useEffect(() => {
    setLoading(true);
    (async () => {
      const hoas = await getHoas({ filter: { masterId: recordId } });
      // Brute fix to keep the ID for the DataGrid component.
      setItems(hoas.items.map((hoa) => ({ id: hoa.recordId, ...hoa })));
      setLoading(false);
    })();
  }, [recordId]);


  return <div>
    {loading && <LoadingOverlay />}
    <Add recordId={recordId} onSuccess={onAdd} />
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid disableColumnFilter rows={items} columns={columns} rowsPerPageOptions={[]} />
    </div>
  </div>;
}

export default SubAssociation;
