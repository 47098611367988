import React from 'react';
import List from "@material-ui/core/List";
import Item from "./Item";

const MgmtList = ({ items, selectedItemId, onSelect, className }) => {
  return (
    <List className={className}>
      {items.map(i => <Item
        key={i.recordId} {...i}
        isSelected={selectedItemId === i.recordId}
        onClick={() => onSelect(i.recordId)}
      />)}
    </List>
  );
}

export default MgmtList;
