import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { subdivision } from "../../../api/endpoints";
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";
import Properties from "../../../components/Properties";
import { useSnackbar } from 'notistack';

const { subdivisionsGetProps } = subdivision;

const ListWrapper = styled.div`
  flex: 1;
  position: relative;
`;

const SearchWrapper = ListWrapper;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Property = ({ normalizedName, fips }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setItems([]);

    if (!normalizedName || !fips) {
      return;
    }

    setLoading(true);
    const request = subdivisionsGetProps(normalizedName, fips);
    (async () => {
      try {
        const response = await request;
        setItems(response.items);
        setCount(response.meta.totalItems);
      }
      catch (e) {
        enqueueSnackbar('Could not load associated properties', { variant: 'error' })
      }
      setLoading(false);
    })();

    return () => { request.cancel(); setLoading(false) };
  }, [normalizedName, fips]);

  return <Wrapper>
    <ListWrapper>
      {loading && <LoadingOverlay />}
      <Properties items={items} ofSubdivision={subdivision} count={count} />
    </ListWrapper>
  </Wrapper>;
}

export default Property;
