import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { trimStart } from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import SubDetails from "./Tabs/SubDetails";
import HoaInfo from "./Tabs/HoaInfo";
import KnownProp from "./Tabs/KnownProp"
import Property from "./Tabs/Property"
import CreateSubdivision from "./Tabs/CreateSubdivision";


const TabPanelWrapper = styled.div`
  height: 100%;
`;

const StyledBox = styled(Box)`
  height: 100%;
`;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <TabPanelWrapper
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      <StyledBox>
        {children}
      </StyledBox>
    </TabPanelWrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;
const WrapperInternal = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
`;

const tabNames = [
  'subdivision-details',
  'hoa-info',
  'known-properties',
  'property-master',
  'create-subdivision',
];

const Content = ({ subdivision, location, onChangeHasChanges, onDelete, onUpdate, show, showFunction, ItemsList, totalItems, isStandaloneCreate }) => {
  const [tabNum, setTabNum] = useState(0);
  const [hasChangesSubdivision, setHasChangesSubdivision] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  //latest add
  const [showTab, setShowTab] = useState()

  useEffect(() => {
    setShowTab(show)
    if (!show) {
      history.push(`${location.pathname}${location.search}#${tabNames[4]}`);
    } else {
      history.push(`${location.pathname}${location.search}#${tabNames[0]}`);
    }
  }, [show])

  useEffect(() => {
    onChangeHasChanges(hasChangesSubdivision);
  }, [hasChangesSubdivision]);

  useEffect(() => {
    const tabName = trimStart(location.hash, '#');
    const tabIndex = tabNames.indexOf(tabName);
    setTabNum(tabIndex === -1 ? 0 : tabIndex);
  }, [location.hash]);

  const handleChange = (event, newValue) => {
    if (!hasChangesSubdivision || !subdivision) {
      history.push(`${location.pathname}${location.search}#${tabNames[newValue]}`);
    } else {
      enqueueSnackbar('You need to save or cancel the changes first.', { variant: 'error' });
    }
  };
  if (!subdivision && !isStandaloneCreate) {
    return <>Please select a Subdivision {totalItems ? `out of the ${totalItems} Subdivisions found` : ''}</>;
  }
  if(isStandaloneCreate){
    return <></>;
  }
  return (
    <div class="wid_dep">
      <Wrapper>
        <WrapperInternal>
          <AppBar position="static">
            {tabNum == 0 || tabNum == 4 ? '' : <span style={{ color: "black", marginTop: -12, marginBottom: 10 }}>Normalized Subdivison Name: {subdivision.normalizedName}</span>}
            <div class="tab_sty">
              <Tabs value={tabNum} onChange={handleChange}>
                {showTab ? <Tab label="Subdivision Details" /> : null}
                {showTab ? <Tab label="HOA Info" /> : null}
                {showTab ? <Tab label="Known Properties" /> : null}
                {showTab ? <Tab label="Property Master" /> : null}
                {!showTab ? <Tab disabled={true} label="Create Subdivision" /> : null}
              </Tabs>
            </div>
          </AppBar>
          <ContentWrapper>
            <TabPanel style={{ display: showTab ? null : 'none' }} value={tabNum} index={0}>
              <SubDetails {...subdivision} onChangeHasChanges={setHasChangesSubdivision} onDelete={onDelete} onUpdate={onUpdate} />
            </TabPanel>
            <TabPanel style={{ display: showTab ? null : 'none' }} value={tabNum} index={1}>
              <HoaInfo subdivisionId={subdivision.recordId} subdivisionState={subdivision.state} />
            </TabPanel>
            <TabPanel style={{ display: showTab ? null : 'none' }} value={tabNum} index={2}>
              <KnownProp name={subdivision.name} fips={subdivision.fips} />
            </TabPanel>
            <TabPanel style={{ display: showTab ? null : 'none' }} value={tabNum} index={3}>
              <Property normalizedName={subdivision.normalizedName} fips={subdivision.fips} />
            </TabPanel>
            <TabPanel
              style={{ display: showTab ? "none" : null }}
              value={tabNum}
              index={4}
            >
              <CreateSubdivision
                {...subdivision}
                onChangeHasChanges={setHasChangesSubdivision}
                onDelete={onDelete}
                onUpdate={onUpdate}
                changeShow={(res) => showFunction(res)}
                subdivisionItemsList={(res) => ItemsList(res)}
              />
            </TabPanel>
          </ContentWrapper>
        </WrapperInternal>
      </Wrapper>
    </div>
  );
}

export default withRouter(Content);
