import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import { hoa, mgmtCompany } from "../api";
import InputPhoneNumber from "../components/InputPhoneNumber";
import LoadingOverlay from "../components/Loading/LoadingOverlay";
import {
  CONTACT_TYPES,
  REGEXP_EMAIL,
  REGEXP_PHONE_NUMBER,
  REGEXP_WEBSITE,
} from "../constants";

const { contactCreate: hoaContactCreate, contactPatch: hoaContactPatch } = hoa;
const { contactCreate: mgmtCompanyContactCreate, contactPatch: mgmtCompanyContactPatch } = mgmtCompany;

const DEFAULT_NAME = 'Main Office';

const ContactDialog = ({ entity = {}, linkedRecordId, onClose, open, type }) => {
  const [address, setAddress] = useState('')
  const [email, setEmail] = useState('')
  const [fax, setFax] = useState('')
  const [name, setName] = useState('')
  const [phonePrimary, setPhonePrimary] = useState('')
  const [phoneSecondary, setPhoneSecondary] = useState('')
  const [title, setTitle] = useState('')
  const [website, setWebsite] = useState('')
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [hasChanges, setHasChanges] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const anyOfRequiredFieldsSet = Boolean(email.length || phonePrimary.length)
    const phoneFormatRegex = new RegExp(REGEXP_PHONE_NUMBER);
    const phonePrimaryValid = !phonePrimary || Boolean(phonePrimary.match(phoneFormatRegex));
    const phoneSecondaryValid = !phoneSecondary || Boolean(phoneSecondary.match(phoneFormatRegex));
    const faxValid = !fax || Boolean(fax.match(phoneFormatRegex));

    const emailFormatRegex = new RegExp(REGEXP_EMAIL);
    const emailValid = !email || Boolean(email.match(emailFormatRegex));

    const websiteFormatRegex = new RegExp(REGEXP_WEBSITE);
    const websiteValid = !website || Boolean(website.match(websiteFormatRegex));

    setIsValid(
      anyOfRequiredFieldsSet
      && phonePrimaryValid
      && phoneSecondaryValid
      && faxValid
      && emailValid
      && websiteValid
    );
  }, [
    email,
    phonePrimary,
    phoneSecondary,
    fax,
    website,
  ]);

  useEffect(() => {
    resetFromProps();
  }, [
    entity.address,
    entity.email,
    entity.fax,
    entity.name,
    entity.phonePrimary,
    entity.phoneSecondary,
    entity.recordId,
    entity.title,
    entity.website,
  ]);

  useEffect(() => {
    const anythingIsChanged =
      (address !== entity.address && (address !== '' || entity.address !== null))
      || (email !== entity.email && (email !== '' || entity.email !== null))
      || (fax !== entity.fax && (fax !== '' || entity.fax !== null))
      || (name !== entity.name && (name !== '' || entity.name !== null))
      || (phonePrimary !== entity.phonePrimary && (phonePrimary !== '' || entity.phonePrimary !== null))
      || (phoneSecondary !== entity.phoneSecondary && (phoneSecondary !== '' || entity.phoneSecondary !== null))
      || (title !== entity.title && (title !== '' || entity.title !== null))
      || (website !== entity.website && (website !== '' || entity.website !== null));
    setHasChanges(anythingIsChanged);
  }, [
    address,
    email,
    fax,
    name,
    phonePrimary,
    phoneSecondary,
    title,
    website,
    entity,
  ]);

  const onChangeAddress = (event) => {
    setAddress(event.target.value.toUpperCase());
  }

  const onChangeEmail = (event) => {
    setEmail(event.target.value.toUpperCase());
  }

  const onChangeFax = (event) => {
    setFax(event.target.value);
  }

  const onChangeName = (event) => {
    setName(event.target.value.toUpperCase());
  }

  const onChangePhonePrimary = (event) => {
    setPhonePrimary(event.target.value);
  }

  const onChangePhoneSecondary = (event) => {
    setPhoneSecondary(event.target.value);
  }

  const onChangeTitle = (event) => {
    setTitle(event.target.value.toUpperCase());
  }

  const onChangeWebsite = (event) => {
    setWebsite(event.target.value.toUpperCase());
  }

  const resetFromProps = () => {
    setAddress(entity.address || '');
    setEmail(entity.email || '');
    setFax(entity.fax || '');
    setName(entity.name || '');
    setPhonePrimary(entity.phonePrimary || '');
    setPhoneSecondary(entity.phoneSecondary || '');
    setTitle(entity.title || '');
    setWebsite(entity.website || '');
  }

  const handleSave = async () => {
    setLoading(true);

    if (!name) {
      setName(DEFAULT_NAME);
    }
    if (phoneSecondary != '0000000000' && phonePrimary != '0000000000' && fax != '0000000000') {
      const contactPayload = {
        address,
        email: email || null,
        fax: fax || null,
        name: name || DEFAULT_NAME,
        phonePrimary: phonePrimary || null,
        phoneSecondary: phoneSecondary || null,
        title,
        website: website || null,
      };

      try {
        let contact;
        let successMessage;
        if (entity.recordId) {
          if (type === CONTACT_TYPES.hoa) {
            contact = await hoaContactPatch(linkedRecordId, entity.recordId, contactPayload);
          } else {
            contact = await mgmtCompanyContactPatch(linkedRecordId, entity.recordId, contactPayload);
          }

          successMessage = 'The Contact has been updated';
        } else {
          if (type === CONTACT_TYPES.hoa) {
            contact = await hoaContactCreate(linkedRecordId, contactPayload);
          } else {
            contact = await mgmtCompanyContactCreate(linkedRecordId, contactPayload);
          }
          successMessage = 'The Contact has been created';
        }
        enqueueSnackbar(successMessage, { variant: 'success' });
        onClose(contact);
      } catch (e) {
        console.error(e);
        const message = e.response.data.message;
        enqueueSnackbar(`Error while creating the Contact: ${message ?? 'Unknown error'}`, { variant: 'error' });
      }
      resetFromProps();
    }
    else {
      enqueueSnackbar('Contact number cannot be 000-000-0000', { variant: 'error' })
    }
    setLoading(false);
  }

  const handleCancel = () => {
    resetFromProps();
    onClose();
  }

  const onDialogClose = () => {
    if (loading) {
      enqueueSnackbar('Wait the action to be completed', { variant: 'warning' });
    } else {
      onClose();
    }
  }

  return (
    <Dialog onClose={onDialogClose} open={open}>
      {loading && <LoadingOverlay />}
      <DialogTitle>{entity.recordId ? 'Update' : 'Create'} Contact</DialogTitle>
      <DialogContent>
        <TextField label="Contact Name" onChange={onChangeName} placeholder={DEFAULT_NAME} value={name} />
        <TextField label="Title" onChange={onChangeTitle} value={title} />
        <TextField disabled={true} label="Contact Type" value={type === CONTACT_TYPES.hoa ? 'HOA' : 'Management Company'} />
        <TextField
          InputProps={{
            inputComponent: InputPhoneNumber,
          }}
          label="Primary Phone"
          onChange={onChangePhonePrimary}
          value={phonePrimary}
        />
        <TextField
          InputProps={{
            inputComponent: InputPhoneNumber,
          }}
          label="Secondary Phone"
          onChange={onChangePhoneSecondary}
          value={phoneSecondary}
        />
        <TextField label="Email" onChange={onChangeEmail} value={email} />
        <TextField
          InputProps={{
            inputComponent: InputPhoneNumber,
          }}
          label="Fax"
          onChange={onChangeFax}
          value={fax}
        />
        <TextField label="Address" onChange={onChangeAddress} value={address} />
        <TextField label="Website" onChange={onChangeWebsite} value={website} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button disabled={!isValid || !hasChanges} onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ContactDialog;
