import React from 'react'
import styled from "styled-components";
import ReactJson from "react-json-view";
import LoadingOverlay from "../../components/Loading/LoadingOverlay";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-left: -10px;
  position: relative
`;

function Results({ hasHOA, estimatedFee, resolveHOA, loading }) {
    return (
        <Wrapper>
            {loading && <LoadingOverlay />}
            {hasHOA ? <ReactJson name={'Has HOA Results'} src={hasHOA} theme="bright:inverted" displayDataTypes={false} displayObjectSize={false} /> : ''}
            {resolveHOA ? <ReactJson name={'Resolve HOA by ID Results'} src={resolveHOA} theme="bright:inverted" displayDataTypes={false} displayObjectSize={false} /> : ''}
            {estimatedFee ? <ReactJson name={'Estimated Fee Results'} src={estimatedFee} theme="bright:inverted" displayDataTypes={false} displayObjectSize={false} /> : ''}
        </Wrapper>
    )
}

export default Results