import { useSnackbar } from "notistack";
import { trimStart } from 'lodash';
import qs from 'qs';
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import config from "../../config";
import { STATES, ROUTES } from "../../constants";
import Button from "@material-ui/core/Button";


const { autoLoadingHoasOnLoad } = config;
const { HOAID } = ROUTES;
const STATE_ANY = 'ANY';
const STATE_UNKNOWN = 'UNKNOWN';

const Filter = ({ location, onSubmit }) => {
  const history = useHistory();

  const [address, setAddress] = useState('');
  const [initialized, setInitialized] = useState(false);
  const [parcel, setParcel] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const searchParameters = qs.parse(trimStart(location.search, '?'));
    syncStateWithProps();

    if (
      !initialized
      && Object.keys(searchParameters).length === 0
      && !autoLoadingHoasOnLoad
    ) {
      return;
    }

    onSubmit(searchParameters);
  }, [location.search]);

  useEffect(() => {
    setInitialized(true);
  }, []);

  const syncStateWithProps = () => {
    const {
      address: initialAddress,
      parcel: initialParcel,
      city: initialCity,
      state: initialState,
      zip: initialZip,
    } = qs.parse(trimStart(location.search, '?'));

    setAddress(initialAddress === undefined ? '' : initialAddress);
    setParcel(initialParcel === undefined ? '' : initialParcel);
    setCity(initialCity === undefined ? undefined : initialCity);
    setState(initialState === '' ? STATE_UNKNOWN : (initialState ? initialState : STATE_ANY));
    setZip(initialZip === undefined ? '' : initialZip);
  }

  const handleAddressChange = event => {
    setAddress(event.target.value);
  }
  const handleParcelChange = event => {
    setParcel(event.target.value);
  }
  const handleCityChange = event => {
    setCity(event.target.value);
  }
  const handleStateChange = event => {
    setState(event.target.value);
  }
  const handleZipChange = event => {
    setZip(event.target.value);
  }
  const handleKeyUp = event => {
    if (event.key === 'Enter') {
      handleOnClick();
    }
  }

  const handleOnClick = () => {
    if (zip && (zip.length < 5 || zip.length > 5)) {
      enqueueSnackbar('Zip must be 5 digits', { variant: 'error' })
      return
    }
    let stateFilter;
    switch (state) {
      case STATE_ANY:
        enqueueSnackbar('Please Select a State', { variant: 'error' })
        return
      case STATE_UNKNOWN:
        enqueueSnackbar('Please Select a State', { variant: 'error' })
        return
      default:
        stateFilter = state;
    }

    const stringParameters = qs.stringify({
      address: address || undefined,
      parcel: parcel || undefined,
      city: city || undefined,
      state: stateFilter,
      zip: zip || undefined,
    });
    if (address && city && state && zip) {
      history.push(`${HOAID}?${stringParameters}${location.hash}`);
    }
    else if (!address) {
      enqueueSnackbar('Please provide an Address', { variant: 'error' })
    }
    else if (!city) {
      enqueueSnackbar('Please provide a City', { variant: 'error' })
    }
    else if (!state) {
      enqueueSnackbar('Please Select a State', { variant: 'error' })
    }
    else if (!zip) {
      enqueueSnackbar('Please provide a Zip', { variant: 'error' })
    }
  }


  return <div>
    <div class="wht_box" style={{ paddingBottom: 20 }}>
      <h4 class="hd_box">Search Address for HOA</h4>
      <div class="form_item_fr">
        <div class="form-group">
          <TextField size="small" fullWidth label="Address*" variant="outlined" value={address} onChange={handleAddressChange} onKeyUp={handleKeyUp} />
        </div>
      </div>

      <div class="form_item_fr">
        <div class="form-group">
          <TextField size="small" label="City*" variant="outlined" value={city} onChange={handleCityChange} onKeyUp={handleKeyUp} />
        </div>
      </div>
      <div class="form_item_fr flex">
        <div class="btn-group dropup tw">
          <FormControl variant="outlined" size="small">
            <InputLabel>State*</InputLabel>
            <Select
              value={state}
              onChange={handleStateChange}
            >
              <MenuItem value={STATE_ANY}>
                <em>State*</em>
              </MenuItem>
              {STATES.map(({ code, label }) => <MenuItem key={code} value={code}>{code}</MenuItem>)}
            </Select>
          </FormControl>
        </div>
        <div class="form_item_fr trd">
          <TextField size="small" label="Zip*" variant="outlined" value={zip} onChange={handleZipChange} onKeyUp={handleKeyUp} />
        </div>
      </div>
      <div class="form_item_fr">
        <div class="mb-2">
          <TextField size="small" fullWidth label="Parcel Id (Optional)" variant="outlined" value={parcel} onChange={handleParcelChange} onKeyUp={handleKeyUp} />
        </div>
      </div>
      <div class="btn_box">
        <Button variant="contained" color="primary" onClick={handleOnClick}>
          Search
        </Button>
      </div>
    </div>
  </div>
}

export default withRouter(Filter);
