import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 40px;
`;

const Page404 = () => <Wrap>Page not found</Wrap>;

export default Page404;
