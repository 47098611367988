import { del as requestDel, get as requestGet, patch as requestPatch, post as requestPost } from '../base/request';
import options from '../servers/apiV5';

export const contactCreate = (mgmtCompanyId, contact) =>
  requestPost(`managementCompany/${mgmtCompanyId}/contact`, options(), contact);

export const contactDelete = (hoaId, contactId) =>
  requestDel(`managementCompany/${hoaId}/contact/${contactId}`, options());

export const contactPatch = (mgmtCompanyId, contactId, payload) =>
  requestPatch(`managementCompany/${mgmtCompanyId}/contact/${contactId}`, options(), payload);

export const get = (recordId) =>
  requestGet(`managementCompany/${recordId}`, options());

// @todo Rename to contactsGet
export const getContacts = (mgmtCompanyId) =>
  requestGet(`managementCompany/${mgmtCompanyId}/contact`, options())

export const getAll = (
  {
    limit = 20,
    filter: {
      offset,
      name,
      address,
      city,
      state,
      zip,
    } = {}
  } = {}
) =>
  requestGet('managementCompany', options(), {
    limit,
    offset,
    name: name ?? undefined,
    address: address ?? undefined,
    city: city ?? undefined,
    state: state ?? undefined,
    zip: zip ?? undefined,
  });

export const orderingInfoCreate = (mgmtCompanyId, orderingInfo) =>
  requestPost(`managementCompany/${mgmtCompanyId}/orderingInfo`, options(), orderingInfo);

export const orderingInfoDelete = (mgmtCompanyId, orderingInfoId) =>
  requestDel(`managementCompany/${mgmtCompanyId}/orderingInfo/${orderingInfoId}`, options());

export const orderingInfoGet = (mgmtCompanyId) =>
  requestGet(`managementCompany/${mgmtCompanyId}/orderingInfo`, options())

export const orderingInfoPatch = (mgmtCompanyId, orderingInfoId, payload) =>
  requestPatch(`managementCompany/${mgmtCompanyId}/orderingInfo/${orderingInfoId}`, options(), payload);

export const patch = (recordId, payload) =>
  requestPatch(`managementCompany/${recordId}`, options(), payload);

export const newManagementCompany = (mgmtCompanyData) =>
  requestPost(`managementCompany`, options(), mgmtCompanyData);

export const del = (recordId) =>
  requestDel(`managementCompany/${recordId}`, options());

export const hoasGetAll = (mgmtCompanyId) =>
  requestGet(`managementCompany/${mgmtCompanyId}/hoa`, options());