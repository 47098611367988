import { trimStart } from "lodash";
import qs from "qs";
import React, { useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { useAuth } from '../utils/auth';
import Loading from './Loading';

const Login = ({ location }) => {
  const { login } = useAuth();

  useEffect(() => {
    const { redirect } = qs.parse(trimStart(location.search, '?'));

    login(redirect);
  }, []);

  return <Loading />

}

export default withRouter(Login);
