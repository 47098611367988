// @todo Get rid of REACT_APP_* prefix.
const config = {
  auth: {
    authCallbackUri: process.env.REACT_APP_AUTH_AUTH_CALLBACK_URI,
    baseUrl: process.env.REACT_APP_AUTH_BASE_URL,
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    logoutCallbackUri: process.env.REACT_APP_AUTH_LOGOUT_CALLBACK_URI,
    // This variable is required to be set through config as the token itself is encrypted and we can't get his lifetime.
    // Also we don't get the lifetime from the response while receiving the token.
    refreshTokenLifetime: Number(process.env.REACT_APP_AUTH_REFRESH_TOKEN_LIFETIME),
    // Refresh auth token and access token before they expired in N seconds.
    refreshTokensBeforeExpiration: Number(process.env.REACT_APP_AUTH_REFRESH_TOKENS_BEFORE_EXPIRATION),
  },
  autoLoadingHoasOnLoad: process.env.REACT_APP_AUTO_LOADING_HOAS_ON_LOAD === '1',
  backend: {
    apiV3: {
      baseUrl: process.env.REACT_APP_BACKEND_API_V3_BASE_URL,
    },
    apiV5: {
      baseUrl: process.env.REACT_APP_BACKEND_API_V5_BASE_URL,
    },
    hoaid: {
      baseUrl: process.env.REACT_APP_HOAID_BASE_URL,
    }
  }
};

export default config;
