export default {
  '01000': { county: 'ALABAMA', state: 'AL' },
  '01001': { county: 'AUTAUGA ', state: 'AL' },
  '01003': { county: 'BALDWIN ', state: 'AL' },
  '01005': { county: 'BARBOUR ', state: 'AL' },
  '01007': { county: 'BIBB ', state: 'AL' },
  '01009': { county: 'BLOUNT ', state: 'AL' },
  '01011': { county: 'BULLOCK ', state: 'AL' },
  '01013': { county: 'BUTLER ', state: 'AL' },
  '01015': { county: 'CALHOUN ', state: 'AL' },
  '01017': { county: 'CHAMBERS ', state: 'AL' },
  '01019': { county: 'CHEROKEE ', state: 'AL' },
  '01021': { county: 'CHILTON ', state: 'AL' },
  '01023': { county: 'CHOCTAW ', state: 'AL' },
  '01025': { county: 'CLARKE ', state: 'AL' },
  '01027': { county: 'CLAY ', state: 'AL' },
  '01029': { county: 'CLEBURNE ', state: 'AL' },
  '01031': { county: 'COFFEE ', state: 'AL' },
  '01033': { county: 'COLBERT ', state: 'AL' },
  '01035': { county: 'CONECUH ', state: 'AL' },
  '01037': { county: 'COOSA ', state: 'AL' },
  '01039': { county: 'COVINGTON ', state: 'AL' },
  '01041': { county: 'CRENSHAW ', state: 'AL' },
  '01043': { county: 'CULLMAN ', state: 'AL' },
  '01045': { county: 'DALE ', state: 'AL' },
  '01047': { county: 'DALLAS ', state: 'AL' },
  '01049': { county: 'DEKALB ', state: 'AL' },
  '01051': { county: 'ELMORE ', state: 'AL' },
  '01053': { county: 'ESCAMBIA ', state: 'AL' },
  '01055': { county: 'ETOWAH ', state: 'AL' },
  '01057': { county: 'FAYETTE ', state: 'AL' },
  '01059': { county: 'FRANKLIN ', state: 'AL' },
  '01061': { county: 'GENEVA ', state: 'AL' },
  '01063': { county: 'GREENE ', state: 'AL' },
  '01065': { county: 'HALE ', state: 'AL' },
  '01067': { county: 'HENRY ', state: 'AL' },
  '01069': { county: 'HOUSTON ', state: 'AL' },
  '01071': { county: 'JACKSON ', state: 'AL' },
  '01073': { county: 'JEFFERSON ', state: 'AL' },
  '01075': { county: 'LAMAR ', state: 'AL' },
  '01077': { county: 'LAUDERDALE ', state: 'AL' },
  '01079': { county: 'LAWRENCE ', state: 'AL' },
  '01081': { county: 'LEE ', state: 'AL' },
  '01083': { county: 'LIMESTONE ', state: 'AL' },
  '01085': { county: 'LOWNDES ', state: 'AL' },
  '01087': { county: 'MACON ', state: 'AL' },
  '01089': { county: 'MADISON ', state: 'AL' },
  '01091': { county: 'MARENGO ', state: 'AL' },
  '01093': { county: 'MARION ', state: 'AL' },
  '01095': { county: 'MARSHALL ', state: 'AL' },
  '01097': { county: 'MOBILE ', state: 'AL' },
  '01099': { county: 'MONROE ', state: 'AL' },
  '01101': { county: 'MONTGOMERY ', state: 'AL' },
  '01103': { county: 'MORGAN ', state: 'AL' },
  '01105': { county: 'PERRY ', state: 'AL' },
  '01107': { county: 'PICKENS ', state: 'AL' },
  '01109': { county: 'PIKE ', state: 'AL' },
  '01111': { county: 'RANDOLPH ', state: 'AL' },
  '01113': { county: 'RUSSELL ', state: 'AL' },
  '01115': { county: 'ST. CLAIR ', state: 'AL' },
  '01117': { county: 'SHELBY ', state: 'AL' },
  '01119': { county: 'SUMTER ', state: 'AL' },
  '01121': { county: 'TALLADEGA ', state: 'AL' },
  '01123': { county: 'TALLAPOOSA ', state: 'AL' },
  '01125': { county: 'TUSCALOOSA ', state: 'AL' },
  '01127': { county: 'WALKER ', state: 'AL' },
  '01129': { county: 'WASHINGTON ', state: 'AL' },
  '01131': { county: 'WILCOX ', state: 'AL' },
  '01133': { county: 'WINSTON ', state: 'AL' },
  '02000': { county: 'ALASKA', state: 'AK' },
  '02013': { county: 'ALEUTIANS EAST BOROUGH', state: 'AK' },
  '02016': { county: 'ALEUTIANS WEST CENSUS AREA', state: 'AK' },
  '02020': { county: 'ANCHORAGE MUNICIPALITY', state: 'AK' },
  '02050': { county: 'BETHEL CENSUS AREA', state: 'AK' },
  '02060': { county: 'BRISTOL BAY BOROUGH', state: 'AK' },
  '02068': { county: 'DENALI BOROUGH', state: 'AK' },
  '02070': { county: 'DILLINGHAM CENSUS AREA', state: 'AK' },
  '02090': { county: 'FAIRBANKS NORTH STAR BOROUGH', state: 'AK' },
  '02100': { county: 'HAINES BOROUGH', state: 'AK' },
  '02105': { county: 'HOONAH-ANGOON CENSUS AREA', state: 'AK' },
  '02110': { county: 'JUNEAU CITY AND BOROUGH', state: 'AK' },
  '02122': { county: 'KENAI PENINSULA BOROUGH', state: 'AK' },
  '02130': { county: 'KETCHIKAN GATEWAY BOROUGH', state: 'AK' },
  '02150': { county: 'KODIAK ISLAND BOROUGH', state: 'AK' },
  '02158': { county: 'KUSILVAK CENSUS AREA', state: 'AK' },
  '02164': { county: 'LAKE AND PENINSULA BOROUGH', state: 'AK' },
  '02170': { county: 'MATANUSKA-SUSITNA BOROUGH', state: 'AK' },
  '02180': { county: 'NOME CENSUS AREA', state: 'AK' },
  '02185': { county: 'NORTH SLOPE BOROUGH', state: 'AK' },
  '02188': { county: 'NORTHWEST ARCTIC BOROUGH', state: 'AK' },
  '02195': { county: 'PETERSBURG BOROUGH', state: 'AK' },
  '02198': { county: 'PRINCE OF WALES-HYDER CENSUS AREA', state: 'AK' },
  '02220': { county: 'SITKA CITY AND BOROUGH', state: 'AK' },
  '02230': { county: 'SKAGWAY MUNICIPALITY', state: 'AK' },
  '02240': { county: 'SOUTHEAST FAIRBANKS CENSUS AREA', state: 'AK' },
  '02261': { county: 'VALDEZ-CORDOVA CENSUS AREA', state: 'AK' },
  '02270': { county: 'WADE HAMPTON CENSUS AREA', state: 'AK' },
  '02275': { county: 'WRANGELL CITY AND BOROUGH', state: 'AK' },
  '02282': { county: 'YAKUTAT CITY AND BOROUGH', state: 'AK' },
  '02290': { county: 'YUKON-KOYUKUK CENSUS AREA', state: 'AK' },
  '04000': { county: 'ARIZONA', state: 'AZ' },
  '04001': { county: 'APACHE ', state: 'AZ' },
  '04003': { county: 'COCHISE ', state: 'AZ' },
  '04005': { county: 'COCONINO ', state: 'AZ' },
  '04007': { county: 'GILA ', state: 'AZ' },
  '04009': { county: 'GRAHAM ', state: 'AZ' },
  '04011': { county: 'GREENLEE ', state: 'AZ' },
  '04012': { county: 'LA PAZ ', state: 'AZ' },
  '04013': { county: 'MARICOPA ', state: 'AZ' },
  '04015': { county: 'MOHAVE ', state: 'AZ' },
  '04017': { county: 'NAVAJO ', state: 'AZ' },
  '04019': { county: 'PIMA ', state: 'AZ' },
  '04021': { county: 'PINAL ', state: 'AZ' },
  '04023': { county: 'SANTA CRUZ ', state: 'AZ' },
  '04025': { county: 'YAVAPAI ', state: 'AZ' },
  '04027': { county: 'YUMA ', state: 'AZ' },
  '05000': { county: 'ARKANSAS', state: 'AR' },
  '05001': { county: 'ARKANSAS ', state: 'AR' },
  '05003': { county: 'ASHLEY ', state: 'AR' },
  '05005': { county: 'BAXTER ', state: 'AR' },
  '05007': { county: 'BENTON ', state: 'AR' },
  '05009': { county: 'BOONE ', state: 'AR' },
  '05011': { county: 'BRADLEY ', state: 'AR' },
  '05013': { county: 'CALHOUN ', state: 'AR' },
  '05015': { county: 'CARROLL ', state: 'AR' },
  '05017': { county: 'CHICOT ', state: 'AR' },
  '05019': { county: 'CLARK ', state: 'AR' },
  '05021': { county: 'CLAY ', state: 'AR' },
  '05023': { county: 'CLEBURNE ', state: 'AR' },
  '05025': { county: 'CLEVELAND ', state: 'AR' },
  '05027': { county: 'COLUMBIA ', state: 'AR' },
  '05029': { county: 'CONWAY ', state: 'AR' },
  '05031': { county: 'CRAIGHEAD ', state: 'AR' },
  '05033': { county: 'CRAWFORD ', state: 'AR' },
  '05035': { county: 'CRITTENDEN ', state: 'AR' },
  '05037': { county: 'CROSS ', state: 'AR' },
  '05039': { county: 'DALLAS ', state: 'AR' },
  '05041': { county: 'DESHA ', state: 'AR' },
  '05043': { county: 'DREW ', state: 'AR' },
  '05045': { county: 'FAULKNER ', state: 'AR' },
  '05047': { county: 'FRANKLIN ', state: 'AR' },
  '05049': { county: 'FULTON ', state: 'AR' },
  '05051': { county: 'GARLAND ', state: 'AR' },
  '05053': { county: 'GRANT ', state: 'AR' },
  '05055': { county: 'GREENE ', state: 'AR' },
  '05057': { county: 'HEMPSTEAD ', state: 'AR' },
  '05059': { county: 'HOT SPRING ', state: 'AR' },
  '05061': { county: 'HOWARD ', state: 'AR' },
  '05063': { county: 'INDEPENDENCE ', state: 'AR' },
  '05065': { county: 'IZARD ', state: 'AR' },
  '05067': { county: 'JACKSON ', state: 'AR' },
  '05069': { county: 'JEFFERSON ', state: 'AR' },
  '05071': { county: 'JOHNSON ', state: 'AR' },
  '05073': { county: 'LAFAYETTE ', state: 'AR' },
  '05075': { county: 'LAWRENCE ', state: 'AR' },
  '05077': { county: 'LEE ', state: 'AR' },
  '05079': { county: 'LINCOLN ', state: 'AR' },
  '05081': { county: 'LITTLE RIVER ', state: 'AR' },
  '05083': { county: 'LOGAN ', state: 'AR' },
  '05085': { county: 'LONOKE ', state: 'AR' },
  '05087': { county: 'MADISON ', state: 'AR' },
  '05089': { county: 'MARION ', state: 'AR' },
  '05091': { county: 'MILLER ', state: 'AR' },
  '05093': { county: 'MISSISSIPPI ', state: 'AR' },
  '05095': { county: 'MONROE ', state: 'AR' },
  '05097': { county: 'MONTGOMERY ', state: 'AR' },
  '05099': { county: 'NEVADA ', state: 'AR' },
  '05101': { county: 'NEWTON ', state: 'AR' },
  '05103': { county: 'OUACHITA ', state: 'AR' },
  '05105': { county: 'PERRY ', state: 'AR' },
  '05107': { county: 'PHILLIPS ', state: 'AR' },
  '05109': { county: 'PIKE ', state: 'AR' },
  '05111': { county: 'POINSETT ', state: 'AR' },
  '05113': { county: 'POLK ', state: 'AR' },
  '05115': { county: 'POPE ', state: 'AR' },
  '05117': { county: 'PRAIRIE ', state: 'AR' },
  '05119': { county: 'PULASKI ', state: 'AR' },
  '05121': { county: 'RANDOLPH ', state: 'AR' },
  '05123': { county: 'ST. FRANCIS ', state: 'AR' },
  '05125': { county: 'SALINE ', state: 'AR' },
  '05127': { county: 'SCOTT ', state: 'AR' },
  '05129': { county: 'SEARCY ', state: 'AR' },
  '05131': { county: 'SEBASTIAN ', state: 'AR' },
  '05133': { county: 'SEVIER ', state: 'AR' },
  '05135': { county: 'SHARP ', state: 'AR' },
  '05137': { county: 'STONE ', state: 'AR' },
  '05139': { county: 'UNION ', state: 'AR' },
  '05141': { county: 'VAN BUREN ', state: 'AR' },
  '05143': { county: 'WASHINGTON ', state: 'AR' },
  '05145': { county: 'WHITE ', state: 'AR' },
  '05147': { county: 'WOODRUFF ', state: 'AR' },
  '05149': { county: 'YELL ', state: 'AR' },
  '06000': { county: 'CALIFORNIA', state: 'CA' },
  '06001': { county: 'ALAMEDA ', state: 'CA' },
  '06003': { county: 'ALPINE ', state: 'CA' },
  '06005': { county: 'AMADOR ', state: 'CA' },
  '06007': { county: 'BUTTE ', state: 'CA' },
  '06009': { county: 'CALAVERAS ', state: 'CA' },
  '06011': { county: 'COLUSA ', state: 'CA' },
  '06013': { county: 'CONTRA COSTA ', state: 'CA' },
  '06015': { county: 'DEL NORTE ', state: 'CA' },
  '06017': { county: 'EL DORADO ', state: 'CA' },
  '06019': { county: 'FRESNO ', state: 'CA' },
  '06021': { county: 'GLENN ', state: 'CA' },
  '06023': { county: 'HUMBOLDT ', state: 'CA' },
  '06025': { county: 'IMPERIAL ', state: 'CA' },
  '06027': { county: 'INYO ', state: 'CA' },
  '06029': { county: 'KERN ', state: 'CA' },
  '06031': { county: 'KINGS ', state: 'CA' },
  '06033': { county: 'LAKE ', state: 'CA' },
  '06035': { county: 'LASSEN ', state: 'CA' },
  '06037': { county: 'LOS ANGELES ', state: 'CA' },
  '06039': { county: 'MADERA ', state: 'CA' },
  '06041': { county: 'MARIN ', state: 'CA' },
  '06043': { county: 'MARIPOSA ', state: 'CA' },
  '06045': { county: 'MENDOCINO ', state: 'CA' },
  '06047': { county: 'MERCED ', state: 'CA' },
  '06049': { county: 'MODOC ', state: 'CA' },
  '06051': { county: 'MONO ', state: 'CA' },
  '06053': { county: 'MONTEREY ', state: 'CA' },
  '06055': { county: 'NAPA ', state: 'CA' },
  '06057': { county: 'NEVADA ', state: 'CA' },
  '06059': { county: 'ORANGE ', state: 'CA' },
  '06061': { county: 'PLACER ', state: 'CA' },
  '06063': { county: 'PLUMAS ', state: 'CA' },
  '06065': { county: 'RIVERSIDE ', state: 'CA' },
  '06067': { county: 'SACRAMENTO ', state: 'CA' },
  '06069': { county: 'SAN BENITO ', state: 'CA' },
  '06071': { county: 'SAN BERNARDINO ', state: 'CA' },
  '06073': { county: 'SAN DIEGO ', state: 'CA' },
  '06075': { county: 'SAN FRANCISCO ', state: 'CA' },
  '06077': { county: 'SAN JOAQUIN ', state: 'CA' },
  '06079': { county: 'SAN LUIS OBISPO ', state: 'CA' },
  '06081': { county: 'SAN MATEO ', state: 'CA' },
  '06083': { county: 'SANTA BARBARA ', state: 'CA' },
  '06085': { county: 'SANTA CLARA ', state: 'CA' },
  '06087': { county: 'SANTA CRUZ ', state: 'CA' },
  '06089': { county: 'SHASTA ', state: 'CA' },
  '06091': { county: 'SIERRA ', state: 'CA' },
  '06093': { county: 'SISKIYOU ', state: 'CA' },
  '06095': { county: 'SOLANO ', state: 'CA' },
  '06097': { county: 'SONOMA ', state: 'CA' },
  '06099': { county: 'STANISLAUS ', state: 'CA' },
  '06101': { county: 'SUTTER ', state: 'CA' },
  '06103': { county: 'TEHAMA ', state: 'CA' },
  '06105': { county: 'TRINITY ', state: 'CA' },
  '06107': { county: 'TULARE ', state: 'CA' },
  '06109': { county: 'TUOLUMNE ', state: 'CA' },
  '06111': { county: 'VENTURA ', state: 'CA' },
  '06113': { county: 'YOLO ', state: 'CA' },
  '06115': { county: 'YUBA ', state: 'CA' },
  '08000': { county: 'COLORADO', state: 'CO' },
  '08001': { county: 'ADAMS ', state: 'CO' },
  '08003': { county: 'ALAMOSA ', state: 'CO' },
  '08005': { county: 'ARAPAHOE ', state: 'CO' },
  '08007': { county: 'ARCHULETA ', state: 'CO' },
  '08009': { county: 'BACA ', state: 'CO' },
  '08011': { county: 'BENT ', state: 'CO' },
  '08013': { county: 'BOULDER ', state: 'CO' },
  '08014': { county: 'BROOMFIELD ', state: 'CO' },
  '08015': { county: 'CHAFFEE ', state: 'CO' },
  '08017': { county: 'CHEYENNE ', state: 'CO' },
  '08019': { county: 'CLEAR CREEK ', state: 'CO' },
  '08021': { county: 'CONEJOS ', state: 'CO' },
  '08023': { county: 'COSTILLA ', state: 'CO' },
  '08025': { county: 'CROWLEY ', state: 'CO' },
  '08027': { county: 'CUSTER ', state: 'CO' },
  '08029': { county: 'DELTA ', state: 'CO' },
  '08031': { county: 'DENVER ', state: 'CO' },
  '08033': { county: 'DOLORES ', state: 'CO' },
  '08035': { county: 'DOUGLAS ', state: 'CO' },
  '08037': { county: 'EAGLE ', state: 'CO' },
  '08039': { county: 'ELBERT ', state: 'CO' },
  '08041': { county: 'EL PASO ', state: 'CO' },
  '08043': { county: 'FREMONT ', state: 'CO' },
  '08045': { county: 'GARFIELD ', state: 'CO' },
  '08047': { county: 'GILPIN ', state: 'CO' },
  '08049': { county: 'GRAND ', state: 'CO' },
  '08051': { county: 'GUNNISON ', state: 'CO' },
  '08053': { county: 'HINSDALE ', state: 'CO' },
  '08055': { county: 'HUERFANO ', state: 'CO' },
  '08057': { county: 'JACKSON ', state: 'CO' },
  '08059': { county: 'JEFFERSON ', state: 'CO' },
  '08061': { county: 'KIOWA ', state: 'CO' },
  '08063': { county: 'KIT CARSON ', state: 'CO' },
  '08065': { county: 'LAKE ', state: 'CO' },
  '08067': { county: 'LA PLATA ', state: 'CO' },
  '08069': { county: 'LARIMER ', state: 'CO' },
  '08071': { county: 'LAS ANIMAS ', state: 'CO' },
  '08073': { county: 'LINCOLN ', state: 'CO' },
  '08075': { county: 'LOGAN ', state: 'CO' },
  '08077': { county: 'MESA ', state: 'CO' },
  '08079': { county: 'MINERAL ', state: 'CO' },
  '08081': { county: 'MOFFAT ', state: 'CO' },
  '08083': { county: 'MONTEZUMA ', state: 'CO' },
  '08085': { county: 'MONTROSE ', state: 'CO' },
  '08087': { county: 'MORGAN ', state: 'CO' },
  '08089': { county: 'OTERO ', state: 'CO' },
  '08091': { county: 'OURAY ', state: 'CO' },
  '08093': { county: 'PARK ', state: 'CO' },
  '08095': { county: 'PHILLIPS ', state: 'CO' },
  '08097': { county: 'PITKIN ', state: 'CO' },
  '08099': { county: 'PROWERS ', state: 'CO' },
  '08101': { county: 'PUEBLO ', state: 'CO' },
  '08103': { county: 'RIO BLANCO ', state: 'CO' },
  '08105': { county: 'RIO GRANDE ', state: 'CO' },
  '08107': { county: 'ROUTT ', state: 'CO' },
  '08109': { county: 'SAGUACHE ', state: 'CO' },
  '08111': { county: 'SAN JUAN ', state: 'CO' },
  '08113': { county: 'SAN MIGUEL ', state: 'CO' },
  '08115': { county: 'SEDGWICK ', state: 'CO' },
  '08117': { county: 'SUMMIT ', state: 'CO' },
  '08119': { county: 'TELLER ', state: 'CO' },
  '08121': { county: 'WASHINGTON ', state: 'CO' },
  '08123': { county: 'WELD ', state: 'CO' },
  '08125': { county: 'YUMA ', state: 'CO' },
  '09000': { county: 'CONNECTICUT', state: 'CT' },
  '09001': { county: 'FAIRFIELD ', state: 'CT' },
  '09003': { county: 'HARTFORD ', state: 'CT' },
  '09005': { county: 'LITCHFIELD ', state: 'CT' },
  '09007': { county: 'MIDDLESEX ', state: 'CT' },
  '09009': { county: 'NEW HAVEN ', state: 'CT' },
  '09011': { county: 'NEW LONDON ', state: 'CT' },
  '09013': { county: 'TOLLAND ', state: 'CT' },
  '09015': { county: 'WINDHAM ', state: 'CT' },
  '10000': { county: 'DELAWARE', state: 'DE' },
  '10001': { county: 'KENT ', state: 'DE' },
  '10003': { county: 'NEW CASTLE ', state: 'DE' },
  '10005': { county: 'SUSSEX ', state: 'DE' },
  '11000': { county: 'DISTRICT OF COLUMBIA', state: 'DC' },
  '11001': { county: 'DISTRICT OF COLUMBIA', state: 'DC' },
  '12000': { county: 'FLORIDA', state: 'FL' },
  '12001': { county: 'ALACHUA ', state: 'FL' },
  '12003': { county: 'BAKER ', state: 'FL' },
  '12005': { county: 'BAY ', state: 'FL' },
  '12007': { county: 'BRADFORD ', state: 'FL' },
  '12009': { county: 'BREVARD ', state: 'FL' },
  '12011': { county: 'BROWARD ', state: 'FL' },
  '12013': { county: 'CALHOUN ', state: 'FL' },
  '12015': { county: 'CHARLOTTE ', state: 'FL' },
  '12017': { county: 'CITRUS ', state: 'FL' },
  '12019': { county: 'CLAY ', state: 'FL' },
  '12021': { county: 'COLLIER ', state: 'FL' },
  '12023': { county: 'COLUMBIA ', state: 'FL' },
  '12027': { county: 'DESOTO ', state: 'FL' },
  '12029': { county: 'DIXIE ', state: 'FL' },
  '12031': { county: 'DUVAL ', state: 'FL' },
  '12033': { county: 'ESCAMBIA ', state: 'FL' },
  '12035': { county: 'FLAGLER ', state: 'FL' },
  '12037': { county: 'FRANKLIN ', state: 'FL' },
  '12039': { county: 'GADSDEN ', state: 'FL' },
  '12041': { county: 'GILCHRIST ', state: 'FL' },
  '12043': { county: 'GLADES ', state: 'FL' },
  '12045': { county: 'GULF ', state: 'FL' },
  '12047': { county: 'HAMILTON ', state: 'FL' },
  '12049': { county: 'HARDEE ', state: 'FL' },
  '12051': { county: 'HENDRY ', state: 'FL' },
  '12053': { county: 'HERNANDO ', state: 'FL' },
  '12055': { county: 'HIGHLANDS ', state: 'FL' },
  '12057': { county: 'HILLSBOROUGH ', state: 'FL' },
  '12059': { county: 'HOLMES ', state: 'FL' },
  '12061': { county: 'INDIAN RIVER ', state: 'FL' },
  '12063': { county: 'JACKSON ', state: 'FL' },
  '12065': { county: 'JEFFERSON ', state: 'FL' },
  '12067': { county: 'LAFAYETTE ', state: 'FL' },
  '12069': { county: 'LAKE ', state: 'FL' },
  '12071': { county: 'LEE ', state: 'FL' },
  '12073': { county: 'LEON ', state: 'FL' },
  '12075': { county: 'LEVY ', state: 'FL' },
  '12077': { county: 'LIBERTY ', state: 'FL' },
  '12079': { county: 'MADISON ', state: 'FL' },
  '12081': { county: 'MANATEE ', state: 'FL' },
  '12083': { county: 'MARION ', state: 'FL' },
  '12085': { county: 'MARTIN ', state: 'FL' },
  '12086': { county: 'MIAMI-DADE ', state: 'FL' },
  '12087': { county: 'MONROE ', state: 'FL' },
  '12089': { county: 'NASSAU ', state: 'FL' },
  '12091': { county: 'OKALOOSA ', state: 'FL' },
  '12093': { county: 'OKEECHOBEE ', state: 'FL' },
  '12095': { county: 'ORANGE ', state: 'FL' },
  '12097': { county: 'OSCEOLA ', state: 'FL' },
  '12099': { county: 'PALM BEACH ', state: 'FL' },
  '12101': { county: 'PASCO ', state: 'FL' },
  '12103': { county: 'PINELLAS ', state: 'FL' },
  '12105': { county: 'POLK ', state: 'FL' },
  '12107': { county: 'PUTNAM ', state: 'FL' },
  '12109': { county: 'ST. JOHNS ', state: 'FL' },
  '12111': { county: 'ST. LUCIE ', state: 'FL' },
  '12113': { county: 'SANTA ROSA ', state: 'FL' },
  '12115': { county: 'SARASOTA ', state: 'FL' },
  '12117': { county: 'SEMINOLE ', state: 'FL' },
  '12119': { county: 'SUMTER ', state: 'FL' },
  '12121': { county: 'SUWANNEE ', state: 'FL' },
  '12123': { county: 'TAYLOR ', state: 'FL' },
  '12125': { county: 'UNION ', state: 'FL' },
  '12127': { county: 'VOLUSIA ', state: 'FL' },
  '12129': { county: 'WAKULLA ', state: 'FL' },
  '12131': { county: 'WALTON ', state: 'FL' },
  '12133': { county: 'WASHINGTON ', state: 'FL' },
  '13000': { county: 'GEORGIA', state: 'GA' },
  '13001': { county: 'APPLING ', state: 'GA' },
  '13003': { county: 'ATKINSON ', state: 'GA' },
  '13005': { county: 'BACON ', state: 'GA' },
  '13007': { county: 'BAKER ', state: 'GA' },
  '13009': { county: 'BALDWIN ', state: 'GA' },
  '13011': { county: 'BANKS ', state: 'GA' },
  '13013': { county: 'BARROW ', state: 'GA' },
  '13015': { county: 'BARTOW ', state: 'GA' },
  '13017': { county: 'BEN HILL ', state: 'GA' },
  '13019': { county: 'BERRIEN ', state: 'GA' },
  '13021': { county: 'BIBB ', state: 'GA' },
  '13023': { county: 'BLECKLEY ', state: 'GA' },
  '13025': { county: 'BRANTLEY ', state: 'GA' },
  '13027': { county: 'BROOKS ', state: 'GA' },
  '13029': { county: 'BRYAN ', state: 'GA' },
  '13031': { county: 'BULLOCH ', state: 'GA' },
  '13033': { county: 'BURKE ', state: 'GA' },
  '13035': { county: 'BUTTS ', state: 'GA' },
  '13037': { county: 'CALHOUN ', state: 'GA' },
  '13039': { county: 'CAMDEN ', state: 'GA' },
  '13043': { county: 'CANDLER ', state: 'GA' },
  '13045': { county: 'CARROLL ', state: 'GA' },
  '13047': { county: 'CATOOSA ', state: 'GA' },
  '13049': { county: 'CHARLTON ', state: 'GA' },
  '13051': { county: 'CHATHAM ', state: 'GA' },
  '13053': { county: 'CHATTAHOOCHEE ', state: 'GA' },
  '13055': { county: 'CHATTOOGA ', state: 'GA' },
  '13057': { county: 'CHEROKEE ', state: 'GA' },
  '13059': { county: 'CLARKE ', state: 'GA' },
  '13061': { county: 'CLAY ', state: 'GA' },
  '13063': { county: 'CLAYTON ', state: 'GA' },
  '13065': { county: 'CLINCH ', state: 'GA' },
  '13067': { county: 'COBB ', state: 'GA' },
  '13069': { county: 'COFFEE ', state: 'GA' },
  '13071': { county: 'COLQUITT ', state: 'GA' },
  '13073': { county: 'COLUMBIA ', state: 'GA' },
  '13075': { county: 'COOK ', state: 'GA' },
  '13077': { county: 'COWETA ', state: 'GA' },
  '13079': { county: 'CRAWFORD ', state: 'GA' },
  '13081': { county: 'CRISP ', state: 'GA' },
  '13083': { county: 'DADE ', state: 'GA' },
  '13085': { county: 'DAWSON ', state: 'GA' },
  '13087': { county: 'DECATUR ', state: 'GA' },
  '13089': { county: 'DEKALB ', state: 'GA' },
  '13091': { county: 'DODGE ', state: 'GA' },
  '13093': { county: 'DOOLY ', state: 'GA' },
  '13095': { county: 'DOUGHERTY ', state: 'GA' },
  '13097': { county: 'DOUGLAS ', state: 'GA' },
  '13099': { county: 'EARLY ', state: 'GA' },
  '13101': { county: 'ECHOLS ', state: 'GA' },
  '13103': { county: 'EFFINGHAM ', state: 'GA' },
  '13105': { county: 'ELBERT ', state: 'GA' },
  '13107': { county: 'EMANUEL ', state: 'GA' },
  '13109': { county: 'EVANS ', state: 'GA' },
  '13111': { county: 'FANNIN ', state: 'GA' },
  '13113': { county: 'FAYETTE ', state: 'GA' },
  '13115': { county: 'FLOYD ', state: 'GA' },
  '13117': { county: 'FORSYTH ', state: 'GA' },
  '13119': { county: 'FRANKLIN ', state: 'GA' },
  '13121': { county: 'FULTON ', state: 'GA' },
  '13123': { county: 'GILMER ', state: 'GA' },
  '13125': { county: 'GLASCOCK ', state: 'GA' },
  '13127': { county: 'GLYNN ', state: 'GA' },
  '13129': { county: 'GORDON ', state: 'GA' },
  '13131': { county: 'GRADY ', state: 'GA' },
  '13133': { county: 'GREENE ', state: 'GA' },
  '13135': { county: 'GWINNETT ', state: 'GA' },
  '13137': { county: 'HABERSHAM ', state: 'GA' },
  '13139': { county: 'HALL ', state: 'GA' },
  '13141': { county: 'HANCOCK ', state: 'GA' },
  '13143': { county: 'HARALSON ', state: 'GA' },
  '13145': { county: 'HARRIS ', state: 'GA' },
  '13147': { county: 'HART ', state: 'GA' },
  '13149': { county: 'HEARD ', state: 'GA' },
  '13151': { county: 'HENRY ', state: 'GA' },
  '13153': { county: 'HOUSTON ', state: 'GA' },
  '13155': { county: 'IRWIN ', state: 'GA' },
  '13157': { county: 'JACKSON ', state: 'GA' },
  '13159': { county: 'JASPER ', state: 'GA' },
  '13161': { county: 'JEFF DAVIS ', state: 'GA' },
  '13163': { county: 'JEFFERSON ', state: 'GA' },
  '13165': { county: 'JENKINS ', state: 'GA' },
  '13167': { county: 'JOHNSON ', state: 'GA' },
  '13169': { county: 'JONES ', state: 'GA' },
  '13171': { county: 'LAMAR ', state: 'GA' },
  '13173': { county: 'LANIER ', state: 'GA' },
  '13175': { county: 'LAURENS ', state: 'GA' },
  '13177': { county: 'LEE ', state: 'GA' },
  '13179': { county: 'LIBERTY ', state: 'GA' },
  '13181': { county: 'LINCOLN ', state: 'GA' },
  '13183': { county: 'LONG ', state: 'GA' },
  '13185': { county: 'LOWNDES ', state: 'GA' },
  '13187': { county: 'LUMPKIN ', state: 'GA' },
  '13189': { county: 'MCDUFFIE ', state: 'GA' },
  '13191': { county: 'MCINTOSH ', state: 'GA' },
  '13193': { county: 'MACON ', state: 'GA' },
  '13195': { county: 'MADISON ', state: 'GA' },
  '13197': { county: 'MARION ', state: 'GA' },
  '13199': { county: 'MERIWETHER ', state: 'GA' },
  '13201': { county: 'MILLER ', state: 'GA' },
  '13205': { county: 'MITCHELL ', state: 'GA' },
  '13207': { county: 'MONROE ', state: 'GA' },
  '13209': { county: 'MONTGOMERY ', state: 'GA' },
  '13211': { county: 'MORGAN ', state: 'GA' },
  '13213': { county: 'MURRAY ', state: 'GA' },
  '13215': { county: 'MUSCOGEE ', state: 'GA' },
  '13217': { county: 'NEWTON ', state: 'GA' },
  '13219': { county: 'OCONEE ', state: 'GA' },
  '13221': { county: 'OGLETHORPE ', state: 'GA' },
  '13223': { county: 'PAULDING ', state: 'GA' },
  '13225': { county: 'PEACH ', state: 'GA' },
  '13227': { county: 'PICKENS ', state: 'GA' },
  '13229': { county: 'PIERCE ', state: 'GA' },
  '13231': { county: 'PIKE ', state: 'GA' },
  '13233': { county: 'POLK ', state: 'GA' },
  '13235': { county: 'PULASKI ', state: 'GA' },
  '13237': { county: 'PUTNAM ', state: 'GA' },
  '13239': { county: 'QUITMAN ', state: 'GA' },
  '13241': { county: 'RABUN ', state: 'GA' },
  '13243': { county: 'RANDOLPH ', state: 'GA' },
  '13245': { county: 'RICHMOND ', state: 'GA' },
  '13247': { county: 'ROCKDALE ', state: 'GA' },
  '13249': { county: 'SCHLEY ', state: 'GA' },
  '13251': { county: 'SCREVEN ', state: 'GA' },
  '13253': { county: 'SEMINOLE ', state: 'GA' },
  '13255': { county: 'SPALDING ', state: 'GA' },
  '13257': { county: 'STEPHENS ', state: 'GA' },
  '13259': { county: 'STEWART ', state: 'GA' },
  '13261': { county: 'SUMTER ', state: 'GA' },
  '13263': { county: 'TALBOT ', state: 'GA' },
  '13265': { county: 'TALIAFERRO ', state: 'GA' },
  '13267': { county: 'TATTNALL ', state: 'GA' },
  '13269': { county: 'TAYLOR ', state: 'GA' },
  '13271': { county: 'TELFAIR ', state: 'GA' },
  '13273': { county: 'TERRELL ', state: 'GA' },
  '13275': { county: 'THOMAS ', state: 'GA' },
  '13277': { county: 'TIFT ', state: 'GA' },
  '13279': { county: 'TOOMBS ', state: 'GA' },
  '13281': { county: 'TOWNS ', state: 'GA' },
  '13283': { county: 'TREUTLEN ', state: 'GA' },
  '13285': { county: 'TROUP ', state: 'GA' },
  '13287': { county: 'TURNER ', state: 'GA' },
  '13289': { county: 'TWIGGS ', state: 'GA' },
  '13291': { county: 'UNION ', state: 'GA' },
  '13293': { county: 'UPSON ', state: 'GA' },
  '13295': { county: 'WALKER ', state: 'GA' },
  '13297': { county: 'WALTON ', state: 'GA' },
  '13299': { county: 'WARE ', state: 'GA' },
  '13301': { county: 'WARREN ', state: 'GA' },
  '13303': { county: 'WASHINGTON ', state: 'GA' },
  '13305': { county: 'WAYNE ', state: 'GA' },
  '13307': { county: 'WEBSTER ', state: 'GA' },
  '13309': { county: 'WHEELER ', state: 'GA' },
  '13311': { county: 'WHITE ', state: 'GA' },
  '13313': { county: 'WHITFIELD ', state: 'GA' },
  '13315': { county: 'WILCOX ', state: 'GA' },
  '13317': { county: 'WILKES ', state: 'GA' },
  '13319': { county: 'WILKINSON ', state: 'GA' },
  '13321': { county: 'WORTH ', state: 'GA' },
  '15000': { county: 'HAWAII', state: 'HI' },
  '15001': { county: 'HAWAII ', state: 'HI' },
  '15003': { county: 'HONOLULU ', state: 'HI' },
  '15005': { county: 'KALAWAO ', state: 'HI' },
  '15007': { county: 'KAUAI ', state: 'HI' },
  '15009': { county: 'MAUI ', state: 'HI' },
  '16000': { county: 'IDAHO', state: 'ID' },
  '16001': { county: 'ADA ', state: 'ID' },
  '16003': { county: 'ADAMS ', state: 'ID' },
  '16005': { county: 'BANNOCK ', state: 'ID' },
  '16007': { county: 'BEAR LAKE ', state: 'ID' },
  '16009': { county: 'BENEWAH ', state: 'ID' },
  '16011': { county: 'BINGHAM ', state: 'ID' },
  '16013': { county: 'BLAINE ', state: 'ID' },
  '16015': { county: 'BOISE ', state: 'ID' },
  '16017': { county: 'BONNER ', state: 'ID' },
  '16019': { county: 'BONNEVILLE ', state: 'ID' },
  '16021': { county: 'BOUNDARY ', state: 'ID' },
  '16023': { county: 'BUTTE ', state: 'ID' },
  '16025': { county: 'CAMAS ', state: 'ID' },
  '16027': { county: 'CANYON ', state: 'ID' },
  '16029': { county: 'CARIBOU ', state: 'ID' },
  '16031': { county: 'CASSIA ', state: 'ID' },
  '16033': { county: 'CLARK ', state: 'ID' },
  '16035': { county: 'CLEARWATER ', state: 'ID' },
  '16037': { county: 'CUSTER ', state: 'ID' },
  '16039': { county: 'ELMORE ', state: 'ID' },
  '16041': { county: 'FRANKLIN ', state: 'ID' },
  '16043': { county: 'FREMONT ', state: 'ID' },
  '16045': { county: 'GEM ', state: 'ID' },
  '16047': { county: 'GOODING ', state: 'ID' },
  '16049': { county: 'IDAHO ', state: 'ID' },
  '16051': { county: 'JEFFERSON ', state: 'ID' },
  '16053': { county: 'JEROME ', state: 'ID' },
  '16055': { county: 'KOOTENAI ', state: 'ID' },
  '16057': { county: 'LATAH ', state: 'ID' },
  '16059': { county: 'LEMHI ', state: 'ID' },
  '16061': { county: 'LEWIS ', state: 'ID' },
  '16063': { county: 'LINCOLN ', state: 'ID' },
  '16065': { county: 'MADISON ', state: 'ID' },
  '16067': { county: 'MINIDOKA ', state: 'ID' },
  '16069': { county: 'NEZ PERCE ', state: 'ID' },
  '16071': { county: 'ONEIDA ', state: 'ID' },
  '16073': { county: 'OWYHEE ', state: 'ID' },
  '16075': { county: 'PAYETTE ', state: 'ID' },
  '16077': { county: 'POWER ', state: 'ID' },
  '16079': { county: 'SHOSHONE ', state: 'ID' },
  '16081': { county: 'TETON ', state: 'ID' },
  '16083': { county: 'TWIN FALLS ', state: 'ID' },
  '16085': { county: 'VALLEY ', state: 'ID' },
  '16087': { county: 'WASHINGTON ', state: 'ID' },
  '17000': { county: 'ILLINOIS', state: 'IL' },
  '17001': { county: 'ADAMS ', state: 'IL' },
  '17003': { county: 'ALEXANDER ', state: 'IL' },
  '17005': { county: 'BOND ', state: 'IL' },
  '17007': { county: 'BOONE ', state: 'IL' },
  '17009': { county: 'BROWN ', state: 'IL' },
  '17011': { county: 'BUREAU ', state: 'IL' },
  '17013': { county: 'CALHOUN ', state: 'IL' },
  '17015': { county: 'CARROLL ', state: 'IL' },
  '17017': { county: 'CASS ', state: 'IL' },
  '17019': { county: 'CHAMPAIGN ', state: 'IL' },
  '17021': { county: 'CHRISTIAN ', state: 'IL' },
  '17023': { county: 'CLARK ', state: 'IL' },
  '17025': { county: 'CLAY ', state: 'IL' },
  '17027': { county: 'CLINTON ', state: 'IL' },
  '17029': { county: 'COLES ', state: 'IL' },
  '17031': { county: 'COOK ', state: 'IL' },
  '17033': { county: 'CRAWFORD ', state: 'IL' },
  '17035': { county: 'CUMBERLAND ', state: 'IL' },
  '17037': { county: 'DEKALB ', state: 'IL' },
  '17039': { county: 'DE WITT ', state: 'IL' },
  '17041': { county: 'DOUGLAS ', state: 'IL' },
  '17043': { county: 'DUPAGE ', state: 'IL' },
  '17045': { county: 'EDGAR ', state: 'IL' },
  '17047': { county: 'EDWARDS ', state: 'IL' },
  '17049': { county: 'EFFINGHAM ', state: 'IL' },
  '17051': { county: 'FAYETTE ', state: 'IL' },
  '17053': { county: 'FORD ', state: 'IL' },
  '17055': { county: 'FRANKLIN ', state: 'IL' },
  '17057': { county: 'FULTON ', state: 'IL' },
  '17059': { county: 'GALLATIN ', state: 'IL' },
  '17061': { county: 'GREENE ', state: 'IL' },
  '17063': { county: 'GRUNDY ', state: 'IL' },
  '17065': { county: 'HAMILTON ', state: 'IL' },
  '17067': { county: 'HANCOCK ', state: 'IL' },
  '17069': { county: 'HARDIN ', state: 'IL' },
  '17071': { county: 'HENDERSON ', state: 'IL' },
  '17073': { county: 'HENRY ', state: 'IL' },
  '17075': { county: 'IROQUOIS ', state: 'IL' },
  '17077': { county: 'JACKSON ', state: 'IL' },
  '17079': { county: 'JASPER ', state: 'IL' },
  '17081': { county: 'JEFFERSON ', state: 'IL' },
  '17083': { county: 'JERSEY ', state: 'IL' },
  '17085': { county: 'JO DAVIESS ', state: 'IL' },
  '17087': { county: 'JOHNSON ', state: 'IL' },
  '17089': { county: 'KANE ', state: 'IL' },
  '17091': { county: 'KANKAKEE ', state: 'IL' },
  '17093': { county: 'KENDALL ', state: 'IL' },
  '17095': { county: 'KNOX ', state: 'IL' },
  '17097': { county: 'LAKE ', state: 'IL' },
  '17099': { county: 'LASALLE ', state: 'IL' },
  '17101': { county: 'LAWRENCE ', state: 'IL' },
  '17103': { county: 'LEE ', state: 'IL' },
  '17105': { county: 'LIVINGSTON ', state: 'IL' },
  '17107': { county: 'LOGAN ', state: 'IL' },
  '17109': { county: 'MCDONOUGH ', state: 'IL' },
  '17111': { county: 'MCHENRY ', state: 'IL' },
  '17113': { county: 'MCLEAN ', state: 'IL' },
  '17115': { county: 'MACON ', state: 'IL' },
  '17117': { county: 'MACOUPIN ', state: 'IL' },
  '17119': { county: 'MADISON ', state: 'IL' },
  '17121': { county: 'MARION ', state: 'IL' },
  '17123': { county: 'MARSHALL ', state: 'IL' },
  '17125': { county: 'MASON ', state: 'IL' },
  '17127': { county: 'MASSAC ', state: 'IL' },
  '17129': { county: 'MENARD ', state: 'IL' },
  '17131': { county: 'MERCER ', state: 'IL' },
  '17133': { county: 'MONROE ', state: 'IL' },
  '17135': { county: 'MONTGOMERY ', state: 'IL' },
  '17137': { county: 'MORGAN ', state: 'IL' },
  '17139': { county: 'MOULTRIE ', state: 'IL' },
  '17141': { county: 'OGLE ', state: 'IL' },
  '17143': { county: 'PEORIA ', state: 'IL' },
  '17145': { county: 'PERRY ', state: 'IL' },
  '17147': { county: 'PIATT ', state: 'IL' },
  '17149': { county: 'PIKE ', state: 'IL' },
  '17151': { county: 'POPE ', state: 'IL' },
  '17153': { county: 'PULASKI ', state: 'IL' },
  '17155': { county: 'PUTNAM ', state: 'IL' },
  '17157': { county: 'RANDOLPH ', state: 'IL' },
  '17159': { county: 'RICHLAND ', state: 'IL' },
  '17161': { county: 'ROCK ISLAND ', state: 'IL' },
  '17163': { county: 'ST. CLAIR ', state: 'IL' },
  '17165': { county: 'SALINE ', state: 'IL' },
  '17167': { county: 'SANGAMON ', state: 'IL' },
  '17169': { county: 'SCHUYLER ', state: 'IL' },
  '17171': { county: 'SCOTT ', state: 'IL' },
  '17173': { county: 'SHELBY ', state: 'IL' },
  '17175': { county: 'STARK ', state: 'IL' },
  '17177': { county: 'STEPHENSON ', state: 'IL' },
  '17179': { county: 'TAZEWELL ', state: 'IL' },
  '17181': { county: 'UNION ', state: 'IL' },
  '17183': { county: 'VERMILION ', state: 'IL' },
  '17185': { county: 'WABASH ', state: 'IL' },
  '17187': { county: 'WARREN ', state: 'IL' },
  '17189': { county: 'WASHINGTON ', state: 'IL' },
  '17191': { county: 'WAYNE ', state: 'IL' },
  '17193': { county: 'WHITE ', state: 'IL' },
  '17195': { county: 'WHITESIDE ', state: 'IL' },
  '17197': { county: 'WILL ', state: 'IL' },
  '17199': { county: 'WILLIAMSON ', state: 'IL' },
  '17201': { county: 'WINNEBAGO ', state: 'IL' },
  '17203': { county: 'WOODFORD ', state: 'IL' },
  '18000': { county: 'INDIANA', state: 'IN' },
  '18001': { county: 'ADAMS ', state: 'IN' },
  '18003': { county: 'ALLEN ', state: 'IN' },
  '18005': { county: 'BARTHOLOMEW ', state: 'IN' },
  '18007': { county: 'BENTON ', state: 'IN' },
  '18009': { county: 'BLACKFORD ', state: 'IN' },
  '18011': { county: 'BOONE ', state: 'IN' },
  '18013': { county: 'BROWN ', state: 'IN' },
  '18015': { county: 'CARROLL ', state: 'IN' },
  '18017': { county: 'CASS ', state: 'IN' },
  '18019': { county: 'CLARK ', state: 'IN' },
  '18021': { county: 'CLAY ', state: 'IN' },
  '18023': { county: 'CLINTON ', state: 'IN' },
  '18025': { county: 'CRAWFORD ', state: 'IN' },
  '18027': { county: 'DAVIESS ', state: 'IN' },
  '18029': { county: 'DEARBORN ', state: 'IN' },
  '18031': { county: 'DECATUR ', state: 'IN' },
  '18033': { county: 'DEKALB ', state: 'IN' },
  '18035': { county: 'DELAWARE ', state: 'IN' },
  '18037': { county: 'DUBOIS ', state: 'IN' },
  '18039': { county: 'ELKHART ', state: 'IN' },
  '18041': { county: 'FAYETTE ', state: 'IN' },
  '18043': { county: 'FLOYD ', state: 'IN' },
  '18045': { county: 'FOUNTAIN ', state: 'IN' },
  '18047': { county: 'FRANKLIN ', state: 'IN' },
  '18049': { county: 'FULTON ', state: 'IN' },
  '18051': { county: 'GIBSON ', state: 'IN' },
  '18053': { county: 'GRANT ', state: 'IN' },
  '18055': { county: 'GREENE ', state: 'IN' },
  '18057': { county: 'HAMILTON ', state: 'IN' },
  '18059': { county: 'HANCOCK ', state: 'IN' },
  '18061': { county: 'HARRISON ', state: 'IN' },
  '18063': { county: 'HENDRICKS ', state: 'IN' },
  '18065': { county: 'HENRY ', state: 'IN' },
  '18067': { county: 'HOWARD ', state: 'IN' },
  '18069': { county: 'HUNTINGTON ', state: 'IN' },
  '18071': { county: 'JACKSON ', state: 'IN' },
  '18073': { county: 'JASPER ', state: 'IN' },
  '18075': { county: 'JAY ', state: 'IN' },
  '18077': { county: 'JEFFERSON ', state: 'IN' },
  '18079': { county: 'JENNINGS ', state: 'IN' },
  '18081': { county: 'JOHNSON ', state: 'IN' },
  '18083': { county: 'KNOX ', state: 'IN' },
  '18085': { county: 'KOSCIUSKO ', state: 'IN' },
  '18087': { county: 'LAGRANGE ', state: 'IN' },
  '18089': { county: 'LAKE ', state: 'IN' },
  '18091': { county: 'LAPORTE ', state: 'IN' },
  '18093': { county: 'LAWRENCE ', state: 'IN' },
  '18095': { county: 'MADISON ', state: 'IN' },
  '18097': { county: 'MARION ', state: 'IN' },
  '18099': { county: 'MARSHALL ', state: 'IN' },
  '18101': { county: 'MARTIN ', state: 'IN' },
  '18103': { county: 'MIAMI ', state: 'IN' },
  '18105': { county: 'MONROE ', state: 'IN' },
  '18107': { county: 'MONTGOMERY ', state: 'IN' },
  '18109': { county: 'MORGAN ', state: 'IN' },
  '18111': { county: 'NEWTON ', state: 'IN' },
  '18113': { county: 'NOBLE ', state: 'IN' },
  '18115': { county: 'OHIO ', state: 'IN' },
  '18117': { county: 'ORANGE ', state: 'IN' },
  '18119': { county: 'OWEN ', state: 'IN' },
  '18121': { county: 'PARKE ', state: 'IN' },
  '18123': { county: 'PERRY ', state: 'IN' },
  '18125': { county: 'PIKE ', state: 'IN' },
  '18127': { county: 'PORTER ', state: 'IN' },
  '18129': { county: 'POSEY ', state: 'IN' },
  '18131': { county: 'PULASKI ', state: 'IN' },
  '18133': { county: 'PUTNAM ', state: 'IN' },
  '18135': { county: 'RANDOLPH ', state: 'IN' },
  '18137': { county: 'RIPLEY ', state: 'IN' },
  '18139': { county: 'RUSH ', state: 'IN' },
  '18141': { county: 'ST. JOSEPH ', state: 'IN' },
  '18143': { county: 'SCOTT ', state: 'IN' },
  '18145': { county: 'SHELBY ', state: 'IN' },
  '18147': { county: 'SPENCER ', state: 'IN' },
  '18149': { county: 'STARKE ', state: 'IN' },
  '18151': { county: 'STEUBEN ', state: 'IN' },
  '18153': { county: 'SULLIVAN ', state: 'IN' },
  '18155': { county: 'SWITZERLAND ', state: 'IN' },
  '18157': { county: 'TIPPECANOE ', state: 'IN' },
  '18159': { county: 'TIPTON ', state: 'IN' },
  '18161': { county: 'UNION ', state: 'IN' },
  '18163': { county: 'VANDERBURGH ', state: 'IN' },
  '18165': { county: 'VERMILLION ', state: 'IN' },
  '18167': { county: 'VIGO ', state: 'IN' },
  '18169': { county: 'WABASH ', state: 'IN' },
  '18171': { county: 'WARREN ', state: 'IN' },
  '18173': { county: 'WARRICK ', state: 'IN' },
  '18175': { county: 'WASHINGTON ', state: 'IN' },
  '18177': { county: 'WAYNE ', state: 'IN' },
  '18179': { county: 'WELLS ', state: 'IN' },
  '18181': { county: 'WHITE ', state: 'IN' },
  '18183': { county: 'WHITLEY ', state: 'IN' },
  '19000': { county: 'IOWA', state: 'IA' },
  '19001': { county: 'ADAIR ', state: 'IA' },
  '19003': { county: 'ADAMS ', state: 'IA' },
  '19005': { county: 'ALLAMAKEE ', state: 'IA' },
  '19007': { county: 'APPANOOSE ', state: 'IA' },
  '19009': { county: 'AUDUBON ', state: 'IA' },
  '19011': { county: 'BENTON ', state: 'IA' },
  '19013': { county: 'BLACK HAWK ', state: 'IA' },
  '19015': { county: 'BOONE ', state: 'IA' },
  '19017': { county: 'BREMER ', state: 'IA' },
  '19019': { county: 'BUCHANAN ', state: 'IA' },
  '19021': { county: 'BUENA VISTA ', state: 'IA' },
  '19023': { county: 'BUTLER ', state: 'IA' },
  '19025': { county: 'CALHOUN ', state: 'IA' },
  '19027': { county: 'CARROLL ', state: 'IA' },
  '19029': { county: 'CASS ', state: 'IA' },
  '19031': { county: 'CEDAR ', state: 'IA' },
  '19033': { county: 'CERRO GORDO ', state: 'IA' },
  '19035': { county: 'CHEROKEE ', state: 'IA' },
  '19037': { county: 'CHICKASAW ', state: 'IA' },
  '19039': { county: 'CLARKE ', state: 'IA' },
  '19041': { county: 'CLAY ', state: 'IA' },
  '19043': { county: 'CLAYTON ', state: 'IA' },
  '19045': { county: 'CLINTON ', state: 'IA' },
  '19047': { county: 'CRAWFORD ', state: 'IA' },
  '19049': { county: 'DALLAS ', state: 'IA' },
  '19051': { county: 'DAVIS ', state: 'IA' },
  '19053': { county: 'DECATUR ', state: 'IA' },
  '19055': { county: 'DELAWARE ', state: 'IA' },
  '19057': { county: 'DES MOINES ', state: 'IA' },
  '19059': { county: 'DICKINSON ', state: 'IA' },
  '19061': { county: 'DUBUQUE ', state: 'IA' },
  '19063': { county: 'EMMET ', state: 'IA' },
  '19065': { county: 'FAYETTE ', state: 'IA' },
  '19067': { county: 'FLOYD ', state: 'IA' },
  '19069': { county: 'FRANKLIN ', state: 'IA' },
  '19071': { county: 'FREMONT ', state: 'IA' },
  '19073': { county: 'GREENE ', state: 'IA' },
  '19075': { county: 'GRUNDY ', state: 'IA' },
  '19077': { county: 'GUTHRIE ', state: 'IA' },
  '19079': { county: 'HAMILTON ', state: 'IA' },
  '19081': { county: 'HANCOCK ', state: 'IA' },
  '19083': { county: 'HARDIN ', state: 'IA' },
  '19085': { county: 'HARRISON ', state: 'IA' },
  '19087': { county: 'HENRY ', state: 'IA' },
  '19089': { county: 'HOWARD ', state: 'IA' },
  '19091': { county: 'HUMBOLDT ', state: 'IA' },
  '19093': { county: 'IDA ', state: 'IA' },
  '19095': { county: 'IOWA ', state: 'IA' },
  '19097': { county: 'JACKSON ', state: 'IA' },
  '19099': { county: 'JASPER ', state: 'IA' },
  '19101': { county: 'JEFFERSON ', state: 'IA' },
  '19103': { county: 'JOHNSON ', state: 'IA' },
  '19105': { county: 'JONES ', state: 'IA' },
  '19107': { county: 'KEOKUK ', state: 'IA' },
  '19109': { county: 'KOSSUTH ', state: 'IA' },
  '19111': { county: 'LEE ', state: 'IA' },
  '19113': { county: 'LINN ', state: 'IA' },
  '19115': { county: 'LOUISA ', state: 'IA' },
  '19117': { county: 'LUCAS ', state: 'IA' },
  '19119': { county: 'LYON ', state: 'IA' },
  '19121': { county: 'MADISON ', state: 'IA' },
  '19123': { county: 'MAHASKA ', state: 'IA' },
  '19125': { county: 'MARION ', state: 'IA' },
  '19127': { county: 'MARSHALL ', state: 'IA' },
  '19129': { county: 'MILLS ', state: 'IA' },
  '19131': { county: 'MITCHELL ', state: 'IA' },
  '19133': { county: 'MONONA ', state: 'IA' },
  '19135': { county: 'MONROE ', state: 'IA' },
  '19137': { county: 'MONTGOMERY ', state: 'IA' },
  '19139': { county: 'MUSCATINE ', state: 'IA' },
  '19141': { county: 'O\'BRIEN ', state: 'IA' },
  '19143': { county: 'OSCEOLA ', state: 'IA' },
  '19145': { county: 'PAGE ', state: 'IA' },
  '19147': { county: 'PALO ALTO ', state: 'IA' },
  '19149': { county: 'PLYMOUTH ', state: 'IA' },
  '19151': { county: 'POCAHONTAS ', state: 'IA' },
  '19153': { county: 'POLK ', state: 'IA' },
  '19155': { county: 'POTTAWATTAMIE ', state: 'IA' },
  '19157': { county: 'POWESHIEK ', state: 'IA' },
  '19159': { county: 'RINGGOLD ', state: 'IA' },
  '19161': { county: 'SAC ', state: 'IA' },
  '19163': { county: 'SCOTT ', state: 'IA' },
  '19165': { county: 'SHELBY ', state: 'IA' },
  '19167': { county: 'SIOUX ', state: 'IA' },
  '19169': { county: 'STORY ', state: 'IA' },
  '19171': { county: 'TAMA ', state: 'IA' },
  '19173': { county: 'TAYLOR ', state: 'IA' },
  '19175': { county: 'UNION ', state: 'IA' },
  '19177': { county: 'VAN BUREN ', state: 'IA' },
  '19179': { county: 'WAPELLO ', state: 'IA' },
  '19181': { county: 'WARREN ', state: 'IA' },
  '19183': { county: 'WASHINGTON ', state: 'IA' },
  '19185': { county: 'WAYNE ', state: 'IA' },
  '19187': { county: 'WEBSTER ', state: 'IA' },
  '19189': { county: 'WINNEBAGO ', state: 'IA' },
  '19191': { county: 'WINNESHIEK ', state: 'IA' },
  '19193': { county: 'WOODBURY ', state: 'IA' },
  '19195': { county: 'WORTH ', state: 'IA' },
  '19197': { county: 'WRIGHT ', state: 'IA' },
  '20000': { county: 'KANSAS', state: 'KS' },
  '20001': { county: 'ALLEN ', state: 'KS' },
  '20003': { county: 'ANDERSON ', state: 'KS' },
  '20005': { county: 'ATCHISON ', state: 'KS' },
  '20007': { county: 'BARBER ', state: 'KS' },
  '20009': { county: 'BARTON ', state: 'KS' },
  '20011': { county: 'BOURBON ', state: 'KS' },
  '20013': { county: 'BROWN ', state: 'KS' },
  '20015': { county: 'BUTLER ', state: 'KS' },
  '20017': { county: 'CHASE ', state: 'KS' },
  '20019': { county: 'CHAUTAUQUA ', state: 'KS' },
  '20021': { county: 'CHEROKEE ', state: 'KS' },
  '20023': { county: 'CHEYENNE ', state: 'KS' },
  '20025': { county: 'CLARK ', state: 'KS' },
  '20027': { county: 'CLAY ', state: 'KS' },
  '20029': { county: 'CLOUD ', state: 'KS' },
  '20031': { county: 'COFFEY ', state: 'KS' },
  '20033': { county: 'COMANCHE ', state: 'KS' },
  '20035': { county: 'COWLEY ', state: 'KS' },
  '20037': { county: 'CRAWFORD ', state: 'KS' },
  '20039': { county: 'DECATUR ', state: 'KS' },
  '20041': { county: 'DICKINSON ', state: 'KS' },
  '20043': { county: 'DONIPHAN ', state: 'KS' },
  '20045': { county: 'DOUGLAS ', state: 'KS' },
  '20047': { county: 'EDWARDS ', state: 'KS' },
  '20049': { county: 'ELK ', state: 'KS' },
  '20051': { county: 'ELLIS ', state: 'KS' },
  '20053': { county: 'ELLSWORTH ', state: 'KS' },
  '20055': { county: 'FINNEY ', state: 'KS' },
  '20057': { county: 'FORD ', state: 'KS' },
  '20059': { county: 'FRANKLIN ', state: 'KS' },
  '20061': { county: 'GEARY ', state: 'KS' },
  '20063': { county: 'GOVE ', state: 'KS' },
  '20065': { county: 'GRAHAM ', state: 'KS' },
  '20067': { county: 'GRANT ', state: 'KS' },
  '20069': { county: 'GRAY ', state: 'KS' },
  '20071': { county: 'GREELEY ', state: 'KS' },
  '20073': { county: 'GREENWOOD ', state: 'KS' },
  '20075': { county: 'HAMILTON ', state: 'KS' },
  '20077': { county: 'HARPER ', state: 'KS' },
  '20079': { county: 'HARVEY ', state: 'KS' },
  '20081': { county: 'HASKELL ', state: 'KS' },
  '20083': { county: 'HODGEMAN ', state: 'KS' },
  '20085': { county: 'JACKSON ', state: 'KS' },
  '20087': { county: 'JEFFERSON ', state: 'KS' },
  '20089': { county: 'JEWELL ', state: 'KS' },
  '20091': { county: 'JOHNSON ', state: 'KS' },
  '20093': { county: 'KEARNY ', state: 'KS' },
  '20095': { county: 'KINGMAN ', state: 'KS' },
  '20097': { county: 'KIOWA ', state: 'KS' },
  '20099': { county: 'LABETTE ', state: 'KS' },
  '20101': { county: 'LANE ', state: 'KS' },
  '20103': { county: 'LEAVENWORTH ', state: 'KS' },
  '20105': { county: 'LINCOLN ', state: 'KS' },
  '20107': { county: 'LINN ', state: 'KS' },
  '20109': { county: 'LOGAN ', state: 'KS' },
  '20111': { county: 'LYON ', state: 'KS' },
  '20113': { county: 'MCPHERSON ', state: 'KS' },
  '20115': { county: 'MARION ', state: 'KS' },
  '20117': { county: 'MARSHALL ', state: 'KS' },
  '20119': { county: 'MEADE ', state: 'KS' },
  '20121': { county: 'MIAMI ', state: 'KS' },
  '20123': { county: 'MITCHELL ', state: 'KS' },
  '20125': { county: 'MONTGOMERY ', state: 'KS' },
  '20127': { county: 'MORRIS ', state: 'KS' },
  '20129': { county: 'MORTON ', state: 'KS' },
  '20131': { county: 'NEMAHA ', state: 'KS' },
  '20133': { county: 'NEOSHO ', state: 'KS' },
  '20135': { county: 'NESS ', state: 'KS' },
  '20137': { county: 'NORTON ', state: 'KS' },
  '20139': { county: 'OSAGE ', state: 'KS' },
  '20141': { county: 'OSBORNE ', state: 'KS' },
  '20143': { county: 'OTTAWA ', state: 'KS' },
  '20145': { county: 'PAWNEE ', state: 'KS' },
  '20147': { county: 'PHILLIPS ', state: 'KS' },
  '20149': { county: 'POTTAWATOMIE ', state: 'KS' },
  '20151': { county: 'PRATT ', state: 'KS' },
  '20153': { county: 'RAWLINS ', state: 'KS' },
  '20155': { county: 'RENO ', state: 'KS' },
  '20157': { county: 'REPUBLIC ', state: 'KS' },
  '20159': { county: 'RICE ', state: 'KS' },
  '20161': { county: 'RILEY ', state: 'KS' },
  '20163': { county: 'ROOKS ', state: 'KS' },
  '20165': { county: 'RUSH ', state: 'KS' },
  '20167': { county: 'RUSSELL ', state: 'KS' },
  '20169': { county: 'SALINE ', state: 'KS' },
  '20171': { county: 'SCOTT ', state: 'KS' },
  '20173': { county: 'SEDGWICK ', state: 'KS' },
  '20175': { county: 'SEWARD ', state: 'KS' },
  '20177': { county: 'SHAWNEE ', state: 'KS' },
  '20179': { county: 'SHERIDAN ', state: 'KS' },
  '20181': { county: 'SHERMAN ', state: 'KS' },
  '20183': { county: 'SMITH ', state: 'KS' },
  '20185': { county: 'STAFFORD ', state: 'KS' },
  '20187': { county: 'STANTON ', state: 'KS' },
  '20189': { county: 'STEVENS ', state: 'KS' },
  '20191': { county: 'SUMNER ', state: 'KS' },
  '20193': { county: 'THOMAS ', state: 'KS' },
  '20195': { county: 'TREGO ', state: 'KS' },
  '20197': { county: 'WABAUNSEE ', state: 'KS' },
  '20199': { county: 'WALLACE ', state: 'KS' },
  '20201': { county: 'WASHINGTON ', state: 'KS' },
  '20203': { county: 'WICHITA ', state: 'KS' },
  '20205': { county: 'WILSON ', state: 'KS' },
  '20207': { county: 'WOODSON ', state: 'KS' },
  '20209': { county: 'WYANDOTTE ', state: 'KS' },
  '21000': { county: 'KENTUCKY', state: 'KY' },
  '21001': { county: 'ADAIR ', state: 'KY' },
  '21003': { county: 'ALLEN ', state: 'KY' },
  '21005': { county: 'ANDERSON ', state: 'KY' },
  '21007': { county: 'BALLARD ', state: 'KY' },
  '21009': { county: 'BARREN ', state: 'KY' },
  '21011': { county: 'BATH ', state: 'KY' },
  '21013': { county: 'BELL ', state: 'KY' },
  '21015': { county: 'BOONE ', state: 'KY' },
  '21017': { county: 'BOURBON ', state: 'KY' },
  '21019': { county: 'BOYD ', state: 'KY' },
  '21021': { county: 'BOYLE ', state: 'KY' },
  '21023': { county: 'BRACKEN ', state: 'KY' },
  '21025': { county: 'BREATHITT ', state: 'KY' },
  '21027': { county: 'BRECKINRIDGE ', state: 'KY' },
  '21029': { county: 'BULLITT ', state: 'KY' },
  '21031': { county: 'BUTLER ', state: 'KY' },
  '21033': { county: 'CALDWELL ', state: 'KY' },
  '21035': { county: 'CALLOWAY ', state: 'KY' },
  '21037': { county: 'CAMPBELL ', state: 'KY' },
  '21039': { county: 'CARLISLE ', state: 'KY' },
  '21041': { county: 'CARROLL ', state: 'KY' },
  '21043': { county: 'CARTER ', state: 'KY' },
  '21045': { county: 'CASEY ', state: 'KY' },
  '21047': { county: 'CHRISTIAN ', state: 'KY' },
  '21049': { county: 'CLARK ', state: 'KY' },
  '21051': { county: 'CLAY ', state: 'KY' },
  '21053': { county: 'CLINTON ', state: 'KY' },
  '21055': { county: 'CRITTENDEN ', state: 'KY' },
  '21057': { county: 'CUMBERLAND ', state: 'KY' },
  '21059': { county: 'DAVIESS ', state: 'KY' },
  '21061': { county: 'EDMONSON ', state: 'KY' },
  '21063': { county: 'ELLIOTT ', state: 'KY' },
  '21065': { county: 'ESTILL ', state: 'KY' },
  '21067': { county: 'FAYETTE ', state: 'KY' },
  '21069': { county: 'FLEMING ', state: 'KY' },
  '21071': { county: 'FLOYD ', state: 'KY' },
  '21073': { county: 'FRANKLIN ', state: 'KY' },
  '21075': { county: 'FULTON ', state: 'KY' },
  '21077': { county: 'GALLATIN ', state: 'KY' },
  '21079': { county: 'GARRARD ', state: 'KY' },
  '21081': { county: 'GRANT ', state: 'KY' },
  '21083': { county: 'GRAVES ', state: 'KY' },
  '21085': { county: 'GRAYSON ', state: 'KY' },
  '21087': { county: 'GREEN ', state: 'KY' },
  '21089': { county: 'GREENUP ', state: 'KY' },
  '21091': { county: 'HANCOCK ', state: 'KY' },
  '21093': { county: 'HARDIN ', state: 'KY' },
  '21095': { county: 'HARLAN ', state: 'KY' },
  '21097': { county: 'HARRISON ', state: 'KY' },
  '21099': { county: 'HART ', state: 'KY' },
  '21101': { county: 'HENDERSON ', state: 'KY' },
  '21103': { county: 'HENRY ', state: 'KY' },
  '21105': { county: 'HICKMAN ', state: 'KY' },
  '21107': { county: 'HOPKINS ', state: 'KY' },
  '21109': { county: 'JACKSON ', state: 'KY' },
  '21111': { county: 'JEFFERSON ', state: 'KY' },
  '21113': { county: 'JESSAMINE ', state: 'KY' },
  '21115': { county: 'JOHNSON ', state: 'KY' },
  '21117': { county: 'KENTON ', state: 'KY' },
  '21119': { county: 'KNOTT ', state: 'KY' },
  '21121': { county: 'KNOX ', state: 'KY' },
  '21123': { county: 'LARUE ', state: 'KY' },
  '21125': { county: 'LAUREL ', state: 'KY' },
  '21127': { county: 'LAWRENCE ', state: 'KY' },
  '21129': { county: 'LEE ', state: 'KY' },
  '21131': { county: 'LESLIE ', state: 'KY' },
  '21133': { county: 'LETCHER ', state: 'KY' },
  '21135': { county: 'LEWIS ', state: 'KY' },
  '21137': { county: 'LINCOLN ', state: 'KY' },
  '21139': { county: 'LIVINGSTON ', state: 'KY' },
  '21141': { county: 'LOGAN ', state: 'KY' },
  '21143': { county: 'LYON ', state: 'KY' },
  '21145': { county: 'MCCRACKEN ', state: 'KY' },
  '21147': { county: 'MCCREARY ', state: 'KY' },
  '21149': { county: 'MCLEAN ', state: 'KY' },
  '21151': { county: 'MADISON ', state: 'KY' },
  '21153': { county: 'MAGOFFIN ', state: 'KY' },
  '21155': { county: 'MARION ', state: 'KY' },
  '21157': { county: 'MARSHALL ', state: 'KY' },
  '21159': { county: 'MARTIN ', state: 'KY' },
  '21161': { county: 'MASON ', state: 'KY' },
  '21163': { county: 'MEADE ', state: 'KY' },
  '21165': { county: 'MENIFEE ', state: 'KY' },
  '21167': { county: 'MERCER ', state: 'KY' },
  '21169': { county: 'METCALFE ', state: 'KY' },
  '21171': { county: 'MONROE ', state: 'KY' },
  '21173': { county: 'MONTGOMERY ', state: 'KY' },
  '21175': { county: 'MORGAN ', state: 'KY' },
  '21177': { county: 'MUHLENBERG ', state: 'KY' },
  '21179': { county: 'NELSON ', state: 'KY' },
  '21181': { county: 'NICHOLAS ', state: 'KY' },
  '21183': { county: 'OHIO ', state: 'KY' },
  '21185': { county: 'OLDHAM ', state: 'KY' },
  '21187': { county: 'OWEN ', state: 'KY' },
  '21189': { county: 'OWSLEY ', state: 'KY' },
  '21191': { county: 'PENDLETON ', state: 'KY' },
  '21193': { county: 'PERRY ', state: 'KY' },
  '21195': { county: 'PIKE ', state: 'KY' },
  '21197': { county: 'POWELL ', state: 'KY' },
  '21199': { county: 'PULASKI ', state: 'KY' },
  '21201': { county: 'ROBERTSON ', state: 'KY' },
  '21203': { county: 'ROCKCASTLE ', state: 'KY' },
  '21205': { county: 'ROWAN ', state: 'KY' },
  '21207': { county: 'RUSSELL ', state: 'KY' },
  '21209': { county: 'SCOTT ', state: 'KY' },
  '21211': { county: 'SHELBY ', state: 'KY' },
  '21213': { county: 'SIMPSON ', state: 'KY' },
  '21215': { county: 'SPENCER ', state: 'KY' },
  '21217': { county: 'TAYLOR ', state: 'KY' },
  '21219': { county: 'TODD ', state: 'KY' },
  '21221': { county: 'TRIGG ', state: 'KY' },
  '21223': { county: 'TRIMBLE ', state: 'KY' },
  '21225': { county: 'UNION ', state: 'KY' },
  '21227': { county: 'WARREN ', state: 'KY' },
  '21229': { county: 'WASHINGTON ', state: 'KY' },
  '21231': { county: 'WAYNE ', state: 'KY' },
  '21233': { county: 'WEBSTER ', state: 'KY' },
  '21235': { county: 'WHITLEY ', state: 'KY' },
  '21237': { county: 'WOLFE ', state: 'KY' },
  '21239': { county: 'WOODFORD ', state: 'KY' },
  '22000': { county: 'LOUISIANA', state: 'LA' },
  '22001': { county: 'ACADIA PARISH', state: 'LA' },
  '22003': { county: 'ALLEN PARISH', state: 'LA' },
  '22005': { county: 'ASCENSION PARISH', state: 'LA' },
  '22007': { county: 'ASSUMPTION PARISH', state: 'LA' },
  '22009': { county: 'AVOYELLES PARISH', state: 'LA' },
  '22011': { county: 'BEAUREGARD PARISH', state: 'LA' },
  '22013': { county: 'BIENVILLE PARISH', state: 'LA' },
  '22015': { county: 'BOSSIER PARISH', state: 'LA' },
  '22017': { county: 'CADDO PARISH', state: 'LA' },
  '22019': { county: 'CALCASIEU PARISH', state: 'LA' },
  '22021': { county: 'CALDWELL PARISH', state: 'LA' },
  '22023': { county: 'CAMERON PARISH', state: 'LA' },
  '22025': { county: 'CATAHOULA PARISH', state: 'LA' },
  '22027': { county: 'CLAIBORNE PARISH', state: 'LA' },
  '22029': { county: 'CONCORDIA PARISH', state: 'LA' },
  '22031': { county: 'DE SOTO PARISH', state: 'LA' },
  '22033': { county: 'EAST BATON ROUGE PARISH', state: 'LA' },
  '22035': { county: 'EAST CARROLL PARISH', state: 'LA' },
  '22037': { county: 'EAST FELICIANA PARISH', state: 'LA' },
  '22039': { county: 'EVANGELINE PARISH', state: 'LA' },
  '22041': { county: 'FRANKLIN PARISH', state: 'LA' },
  '22043': { county: 'GRANT PARISH', state: 'LA' },
  '22045': { county: 'IBERIA PARISH', state: 'LA' },
  '22047': { county: 'IBERVILLE PARISH', state: 'LA' },
  '22049': { county: 'JACKSON PARISH', state: 'LA' },
  '22051': { county: 'JEFFERSON PARISH', state: 'LA' },
  '22053': { county: 'JEFFERSON DAVIS PARISH', state: 'LA' },
  '22055': { county: 'LAFAYETTE PARISH', state: 'LA' },
  '22057': { county: 'LAFOURCHE PARISH', state: 'LA' },
  '22059': { county: 'LASALLE PARISH', state: 'LA' },
  '22061': { county: 'LINCOLN PARISH', state: 'LA' },
  '22063': { county: 'LIVINGSTON PARISH', state: 'LA' },
  '22065': { county: 'MADISON PARISH', state: 'LA' },
  '22067': { county: 'MOREHOUSE PARISH', state: 'LA' },
  '22069': { county: 'NATCHITOCHES PARISH', state: 'LA' },
  '22071': { county: 'ORLEANS PARISH', state: 'LA' },
  '22073': { county: 'OUACHITA PARISH', state: 'LA' },
  '22075': { county: 'PLAQUEMINES PARISH', state: 'LA' },
  '22077': { county: 'POINTE COUPEE PARISH', state: 'LA' },
  '22079': { county: 'RAPIDES PARISH', state: 'LA' },
  '22081': { county: 'RED RIVER PARISH', state: 'LA' },
  '22083': { county: 'RICHLAND PARISH', state: 'LA' },
  '22085': { county: 'SABINE PARISH', state: 'LA' },
  '22087': { county: 'ST. BERNARD PARISH', state: 'LA' },
  '22089': { county: 'ST. CHARLES PARISH', state: 'LA' },
  '22091': { county: 'ST. HELENA PARISH', state: 'LA' },
  '22093': { county: 'ST. JAMES PARISH', state: 'LA' },
  '22095': { county: 'ST. JOHN THE BAPTIST PARISH', state: 'LA' },
  '22097': { county: 'ST. LANDRY PARISH', state: 'LA' },
  '22099': { county: 'ST. MARTIN PARISH', state: 'LA' },
  '22101': { county: 'ST. MARY PARISH', state: 'LA' },
  '22103': { county: 'ST. TAMMANY PARISH', state: 'LA' },
  '22105': { county: 'TANGIPAHOA PARISH', state: 'LA' },
  '22107': { county: 'TENSAS PARISH', state: 'LA' },
  '22109': { county: 'TERREBONNE PARISH', state: 'LA' },
  '22111': { county: 'UNION PARISH', state: 'LA' },
  '22113': { county: 'VERMILION PARISH', state: 'LA' },
  '22115': { county: 'VERNON PARISH', state: 'LA' },
  '22117': { county: 'WASHINGTON PARISH', state: 'LA' },
  '22119': { county: 'WEBSTER PARISH', state: 'LA' },
  '22121': { county: 'WEST BATON ROUGE PARISH', state: 'LA' },
  '22123': { county: 'WEST CARROLL PARISH', state: 'LA' },
  '22125': { county: 'WEST FELICIANA PARISH', state: 'LA' },
  '22127': { county: 'WINN PARISH', state: 'LA' },
  '23000': { county: 'MAINE', state: 'ME' },
  '23001': { county: 'ANDROSCOGGIN ', state: 'ME' },
  '23003': { county: 'AROOSTOOK ', state: 'ME' },
  '23005': { county: 'CUMBERLAND ', state: 'ME' },
  '23007': { county: 'FRANKLIN ', state: 'ME' },
  '23009': { county: 'HANCOCK ', state: 'ME' },
  '23011': { county: 'KENNEBEC ', state: 'ME' },
  '23013': { county: 'KNOX ', state: 'ME' },
  '23015': { county: 'LINCOLN ', state: 'ME' },
  '23017': { county: 'OXFORD ', state: 'ME' },
  '23019': { county: 'PENOBSCOT ', state: 'ME' },
  '23021': { county: 'PISCATAQUIS ', state: 'ME' },
  '23023': { county: 'SAGADAHOC ', state: 'ME' },
  '23025': { county: 'SOMERSET ', state: 'ME' },
  '23027': { county: 'WALDO ', state: 'ME' },
  '23029': { county: 'WASHINGTON ', state: 'ME' },
  '23031': { county: 'YORK ', state: 'ME' },
  '24000': { county: 'MARYLAND', state: 'MD' },
  '24001': { county: 'ALLEGANY ', state: 'MD' },
  '24003': { county: 'ANNE ARUNDEL ', state: 'MD' },
  '24005': { county: 'BALTIMORE ', state: 'MD' },
  '24009': { county: 'CALVERT ', state: 'MD' },
  '24011': { county: 'CAROLINE ', state: 'MD' },
  '24013': { county: 'CARROLL ', state: 'MD' },
  '24015': { county: 'CECIL ', state: 'MD' },
  '24017': { county: 'CHARLES ', state: 'MD' },
  '24019': { county: 'DORCHESTER ', state: 'MD' },
  '24021': { county: 'FREDERICK ', state: 'MD' },
  '24023': { county: 'GARRETT ', state: 'MD' },
  '24025': { county: 'HARFORD ', state: 'MD' },
  '24027': { county: 'HOWARD ', state: 'MD' },
  '24029': { county: 'KENT ', state: 'MD' },
  '24031': { county: 'MONTGOMERY ', state: 'MD' },
  '24033': { county: 'PRINCE GEORGE\'S ', state: 'MD' },
  '24035': { county: 'QUEEN ANNE\'S ', state: 'MD' },
  '24037': { county: 'ST. MARY\'S ', state: 'MD' },
  '24039': { county: 'SOMERSET ', state: 'MD' },
  '24041': { county: 'TALBOT ', state: 'MD' },
  '24043': { county: 'WASHINGTON ', state: 'MD' },
  '24045': { county: 'WICOMICO ', state: 'MD' },
  '24047': { county: 'WORCESTER ', state: 'MD' },
  '24510': { county: 'BALTIMORE CITY', state: 'MD' },
  '25000': { county: 'MASSACHUSETTS', state: 'MA' },
  '25001': { county: 'BARNSTABLE ', state: 'MA' },
  '25003': { county: 'BERKSHIRE ', state: 'MA' },
  '25005': { county: 'BRISTOL ', state: 'MA' },
  '25007': { county: 'DUKES ', state: 'MA' },
  '25009': { county: 'ESSEX ', state: 'MA' },
  '25011': { county: 'FRANKLIN ', state: 'MA' },
  '25013': { county: 'HAMPDEN ', state: 'MA' },
  '25015': { county: 'HAMPSHIRE ', state: 'MA' },
  '25017': { county: 'MIDDLESEX ', state: 'MA' },
  '25019': { county: 'NANTUCKET ', state: 'MA' },
  '25021': { county: 'NORFOLK ', state: 'MA' },
  '25023': { county: 'PLYMOUTH ', state: 'MA' },
  '25025': { county: 'SUFFOLK ', state: 'MA' },
  '25027': { county: 'WORCESTER ', state: 'MA' },
  '26000': { county: 'MICHIGAN', state: 'MI' },
  '26001': { county: 'ALCONA ', state: 'MI' },
  '26003': { county: 'ALGER ', state: 'MI' },
  '26005': { county: 'ALLEGAN ', state: 'MI' },
  '26007': { county: 'ALPENA ', state: 'MI' },
  '26009': { county: 'ANTRIM ', state: 'MI' },
  '26011': { county: 'ARENAC ', state: 'MI' },
  '26013': { county: 'BARAGA ', state: 'MI' },
  '26015': { county: 'BARRY ', state: 'MI' },
  '26017': { county: 'BAY ', state: 'MI' },
  '26019': { county: 'BENZIE ', state: 'MI' },
  '26021': { county: 'BERRIEN ', state: 'MI' },
  '26023': { county: 'BRANCH ', state: 'MI' },
  '26025': { county: 'CALHOUN ', state: 'MI' },
  '26027': { county: 'CASS ', state: 'MI' },
  '26029': { county: 'CHARLEVOIX ', state: 'MI' },
  '26031': { county: 'CHEBOYGAN ', state: 'MI' },
  '26033': { county: 'CHIPPEWA ', state: 'MI' },
  '26035': { county: 'CLARE ', state: 'MI' },
  '26037': { county: 'CLINTON ', state: 'MI' },
  '26039': { county: 'CRAWFORD ', state: 'MI' },
  '26041': { county: 'DELTA ', state: 'MI' },
  '26043': { county: 'DICKINSON ', state: 'MI' },
  '26045': { county: 'EATON ', state: 'MI' },
  '26047': { county: 'EMMET ', state: 'MI' },
  '26049': { county: 'GENESEE ', state: 'MI' },
  '26051': { county: 'GLADWIN ', state: 'MI' },
  '26053': { county: 'GOGEBIC ', state: 'MI' },
  '26055': { county: 'GRAND TRAVERSE ', state: 'MI' },
  '26057': { county: 'GRATIOT ', state: 'MI' },
  '26059': { county: 'HILLSDALE ', state: 'MI' },
  '26061': { county: 'HOUGHTON ', state: 'MI' },
  '26063': { county: 'HURON ', state: 'MI' },
  '26065': { county: 'INGHAM ', state: 'MI' },
  '26067': { county: 'IONIA ', state: 'MI' },
  '26069': { county: 'IOSCO ', state: 'MI' },
  '26071': { county: 'IRON ', state: 'MI' },
  '26073': { county: 'ISABELLA ', state: 'MI' },
  '26075': { county: 'JACKSON ', state: 'MI' },
  '26077': { county: 'KALAMAZOO ', state: 'MI' },
  '26079': { county: 'KALKASKA ', state: 'MI' },
  '26081': { county: 'KENT ', state: 'MI' },
  '26083': { county: 'KEWEENAW ', state: 'MI' },
  '26085': { county: 'LAKE ', state: 'MI' },
  '26087': { county: 'LAPEER ', state: 'MI' },
  '26089': { county: 'LEELANAU ', state: 'MI' },
  '26091': { county: 'LENAWEE ', state: 'MI' },
  '26093': { county: 'LIVINGSTON ', state: 'MI' },
  '26095': { county: 'LUCE ', state: 'MI' },
  '26097': { county: 'MACKINAC ', state: 'MI' },
  '26099': { county: 'MACOMB ', state: 'MI' },
  '26101': { county: 'MANISTEE ', state: 'MI' },
  '26103': { county: 'MARQUETTE ', state: 'MI' },
  '26105': { county: 'MASON ', state: 'MI' },
  '26107': { county: 'MECOSTA ', state: 'MI' },
  '26109': { county: 'MENOMINEE ', state: 'MI' },
  '26111': { county: 'MIDLAND ', state: 'MI' },
  '26113': { county: 'MISSAUKEE ', state: 'MI' },
  '26115': { county: 'MONROE ', state: 'MI' },
  '26117': { county: 'MONTCALM ', state: 'MI' },
  '26119': { county: 'MONTMORENCY ', state: 'MI' },
  '26121': { county: 'MUSKEGON ', state: 'MI' },
  '26123': { county: 'NEWAYGO ', state: 'MI' },
  '26125': { county: 'OAKLAND ', state: 'MI' },
  '26127': { county: 'OCEANA ', state: 'MI' },
  '26129': { county: 'OGEMAW ', state: 'MI' },
  '26131': { county: 'ONTONAGON ', state: 'MI' },
  '26133': { county: 'OSCEOLA ', state: 'MI' },
  '26135': { county: 'OSCODA ', state: 'MI' },
  '26137': { county: 'OTSEGO ', state: 'MI' },
  '26139': { county: 'OTTAWA ', state: 'MI' },
  '26141': { county: 'PRESQUE ISLE ', state: 'MI' },
  '26143': { county: 'ROSCOMMON ', state: 'MI' },
  '26145': { county: 'SAGINAW ', state: 'MI' },
  '26147': { county: 'ST. CLAIR ', state: 'MI' },
  '26149': { county: 'ST. JOSEPH ', state: 'MI' },
  '26151': { county: 'SANILAC ', state: 'MI' },
  '26153': { county: 'SCHOOLCRAFT ', state: 'MI' },
  '26155': { county: 'SHIAWASSEE ', state: 'MI' },
  '26157': { county: 'TUSCOLA ', state: 'MI' },
  '26159': { county: 'VAN BUREN ', state: 'MI' },
  '26161': { county: 'WASHTENAW ', state: 'MI' },
  '26163': { county: 'WAYNE ', state: 'MI' },
  '26165': { county: 'WEXFORD ', state: 'MI' },
  '27000': { county: 'MINNESOTA', state: 'MN' },
  '27001': { county: 'AITKIN ', state: 'MN' },
  '27003': { county: 'ANOKA ', state: 'MN' },
  '27005': { county: 'BECKER ', state: 'MN' },
  '27007': { county: 'BELTRAMI ', state: 'MN' },
  '27009': { county: 'BENTON ', state: 'MN' },
  '27011': { county: 'BIG STONE ', state: 'MN' },
  '27013': { county: 'BLUE EARTH ', state: 'MN' },
  '27015': { county: 'BROWN ', state: 'MN' },
  '27017': { county: 'CARLTON ', state: 'MN' },
  '27019': { county: 'CARVER ', state: 'MN' },
  '27021': { county: 'CASS ', state: 'MN' },
  '27023': { county: 'CHIPPEWA ', state: 'MN' },
  '27025': { county: 'CHISAGO ', state: 'MN' },
  '27027': { county: 'CLAY ', state: 'MN' },
  '27029': { county: 'CLEARWATER ', state: 'MN' },
  '27031': { county: 'COOK ', state: 'MN' },
  '27033': { county: 'COTTONWOOD ', state: 'MN' },
  '27035': { county: 'CROW WING ', state: 'MN' },
  '27037': { county: 'DAKOTA ', state: 'MN' },
  '27039': { county: 'DODGE ', state: 'MN' },
  '27041': { county: 'DOUGLAS ', state: 'MN' },
  '27043': { county: 'FARIBAULT ', state: 'MN' },
  '27045': { county: 'FILLMORE ', state: 'MN' },
  '27047': { county: 'FREEBORN ', state: 'MN' },
  '27049': { county: 'GOODHUE ', state: 'MN' },
  '27051': { county: 'GRANT ', state: 'MN' },
  '27053': { county: 'HENNEPIN ', state: 'MN' },
  '27055': { county: 'HOUSTON ', state: 'MN' },
  '27057': { county: 'HUBBARD ', state: 'MN' },
  '27059': { county: 'ISANTI ', state: 'MN' },
  '27061': { county: 'ITASCA ', state: 'MN' },
  '27063': { county: 'JACKSON ', state: 'MN' },
  '27065': { county: 'KANABEC ', state: 'MN' },
  '27067': { county: 'KANDIYOHI ', state: 'MN' },
  '27069': { county: 'KITTSON ', state: 'MN' },
  '27071': { county: 'KOOCHICHING ', state: 'MN' },
  '27073': { county: 'LAC QUI PARLE ', state: 'MN' },
  '27075': { county: 'LAKE ', state: 'MN' },
  '27077': { county: 'LAKE OF THE WOODS ', state: 'MN' },
  '27079': { county: 'LE SUEUR ', state: 'MN' },
  '27081': { county: 'LINCOLN ', state: 'MN' },
  '27083': { county: 'LYON ', state: 'MN' },
  '27085': { county: 'MCLEOD ', state: 'MN' },
  '27087': { county: 'MAHNOMEN ', state: 'MN' },
  '27089': { county: 'MARSHALL ', state: 'MN' },
  '27091': { county: 'MARTIN ', state: 'MN' },
  '27093': { county: 'MEEKER ', state: 'MN' },
  '27095': { county: 'MILLE LACS ', state: 'MN' },
  '27097': { county: 'MORRISON ', state: 'MN' },
  '27099': { county: 'MOWER ', state: 'MN' },
  '27101': { county: 'MURRAY ', state: 'MN' },
  '27103': { county: 'NICOLLET ', state: 'MN' },
  '27105': { county: 'NOBLES ', state: 'MN' },
  '27107': { county: 'NORMAN ', state: 'MN' },
  '27109': { county: 'OLMSTED ', state: 'MN' },
  '27111': { county: 'OTTER TAIL ', state: 'MN' },
  '27113': { county: 'PENNINGTON ', state: 'MN' },
  '27115': { county: 'PINE ', state: 'MN' },
  '27117': { county: 'PIPESTONE ', state: 'MN' },
  '27119': { county: 'POLK ', state: 'MN' },
  '27121': { county: 'POPE ', state: 'MN' },
  '27123': { county: 'RAMSEY ', state: 'MN' },
  '27125': { county: 'RED LAKE ', state: 'MN' },
  '27127': { county: 'REDWOOD ', state: 'MN' },
  '27129': { county: 'RENVILLE ', state: 'MN' },
  '27131': { county: 'RICE ', state: 'MN' },
  '27133': { county: 'ROCK ', state: 'MN' },
  '27135': { county: 'ROSEAU ', state: 'MN' },
  '27137': { county: 'ST. LOUIS ', state: 'MN' },
  '27139': { county: 'SCOTT ', state: 'MN' },
  '27141': { county: 'SHERBURNE ', state: 'MN' },
  '27143': { county: 'SIBLEY ', state: 'MN' },
  '27145': { county: 'STEARNS ', state: 'MN' },
  '27147': { county: 'STEELE ', state: 'MN' },
  '27149': { county: 'STEVENS ', state: 'MN' },
  '27151': { county: 'SWIFT ', state: 'MN' },
  '27153': { county: 'TODD ', state: 'MN' },
  '27155': { county: 'TRAVERSE ', state: 'MN' },
  '27157': { county: 'WABASHA ', state: 'MN' },
  '27159': { county: 'WADENA ', state: 'MN' },
  '27161': { county: 'WASECA ', state: 'MN' },
  '27163': { county: 'WASHINGTON ', state: 'MN' },
  '27165': { county: 'WATONWAN ', state: 'MN' },
  '27167': { county: 'WILKIN ', state: 'MN' },
  '27169': { county: 'WINONA ', state: 'MN' },
  '27171': { county: 'WRIGHT ', state: 'MN' },
  '27173': { county: 'YELLOW MEDICINE ', state: 'MN' },
  '28000': { county: 'MISSISSIPPI', state: 'MS' },
  '28001': { county: 'ADAMS ', state: 'MS' },
  '28003': { county: 'ALCORN ', state: 'MS' },
  '28005': { county: 'AMITE ', state: 'MS' },
  '28007': { county: 'ATTALA ', state: 'MS' },
  '28009': { county: 'BENTON ', state: 'MS' },
  '28011': { county: 'BOLIVAR ', state: 'MS' },
  '28013': { county: 'CALHOUN ', state: 'MS' },
  '28015': { county: 'CARROLL ', state: 'MS' },
  '28017': { county: 'CHICKASAW ', state: 'MS' },
  '28019': { county: 'CHOCTAW ', state: 'MS' },
  '28021': { county: 'CLAIBORNE ', state: 'MS' },
  '28023': { county: 'CLARKE ', state: 'MS' },
  '28025': { county: 'CLAY ', state: 'MS' },
  '28027': { county: 'COAHOMA ', state: 'MS' },
  '28029': { county: 'COPIAH ', state: 'MS' },
  '28031': { county: 'COVINGTON ', state: 'MS' },
  '28033': { county: 'DESOTO ', state: 'MS' },
  '28035': { county: 'FORREST ', state: 'MS' },
  '28037': { county: 'FRANKLIN ', state: 'MS' },
  '28039': { county: 'GEORGE ', state: 'MS' },
  '28041': { county: 'GREENE ', state: 'MS' },
  '28043': { county: 'GRENADA ', state: 'MS' },
  '28045': { county: 'HANCOCK ', state: 'MS' },
  '28047': { county: 'HARRISON ', state: 'MS' },
  '28049': { county: 'HINDS ', state: 'MS' },
  '28051': { county: 'HOLMES ', state: 'MS' },
  '28053': { county: 'HUMPHREYS ', state: 'MS' },
  '28055': { county: 'ISSAQUENA ', state: 'MS' },
  '28057': { county: 'ITAWAMBA ', state: 'MS' },
  '28059': { county: 'JACKSON ', state: 'MS' },
  '28061': { county: 'JASPER ', state: 'MS' },
  '28063': { county: 'JEFFERSON ', state: 'MS' },
  '28065': { county: 'JEFFERSON DAVIS ', state: 'MS' },
  '28067': { county: 'JONES ', state: 'MS' },
  '28069': { county: 'KEMPER ', state: 'MS' },
  '28071': { county: 'LAFAYETTE ', state: 'MS' },
  '28073': { county: 'LAMAR ', state: 'MS' },
  '28075': { county: 'LAUDERDALE ', state: 'MS' },
  '28077': { county: 'LAWRENCE ', state: 'MS' },
  '28079': { county: 'LEAKE ', state: 'MS' },
  '28081': { county: 'LEE ', state: 'MS' },
  '28083': { county: 'LEFLORE ', state: 'MS' },
  '28085': { county: 'LINCOLN ', state: 'MS' },
  '28087': { county: 'LOWNDES ', state: 'MS' },
  '28089': { county: 'MADISON ', state: 'MS' },
  '28091': { county: 'MARION ', state: 'MS' },
  '28093': { county: 'MARSHALL ', state: 'MS' },
  '28095': { county: 'MONROE ', state: 'MS' },
  '28097': { county: 'MONTGOMERY ', state: 'MS' },
  '28099': { county: 'NESHOBA ', state: 'MS' },
  '28101': { county: 'NEWTON ', state: 'MS' },
  '28103': { county: 'NOXUBEE ', state: 'MS' },
  '28105': { county: 'OKTIBBEHA ', state: 'MS' },
  '28107': { county: 'PANOLA ', state: 'MS' },
  '28109': { county: 'PEARL RIVER ', state: 'MS' },
  '28111': { county: 'PERRY ', state: 'MS' },
  '28113': { county: 'PIKE ', state: 'MS' },
  '28115': { county: 'PONTOTOC ', state: 'MS' },
  '28117': { county: 'PRENTISS ', state: 'MS' },
  '28119': { county: 'QUITMAN ', state: 'MS' },
  '28121': { county: 'RANKIN ', state: 'MS' },
  '28123': { county: 'SCOTT ', state: 'MS' },
  '28125': { county: 'SHARKEY ', state: 'MS' },
  '28127': { county: 'SIMPSON ', state: 'MS' },
  '28129': { county: 'SMITH ', state: 'MS' },
  '28131': { county: 'STONE ', state: 'MS' },
  '28133': { county: 'SUNFLOWER ', state: 'MS' },
  '28135': { county: 'TALLAHATCHIE ', state: 'MS' },
  '28137': { county: 'TATE ', state: 'MS' },
  '28139': { county: 'TIPPAH ', state: 'MS' },
  '28141': { county: 'TISHOMINGO ', state: 'MS' },
  '28143': { county: 'TUNICA ', state: 'MS' },
  '28145': { county: 'UNION ', state: 'MS' },
  '28147': { county: 'WALTHALL ', state: 'MS' },
  '28149': { county: 'WARREN ', state: 'MS' },
  '28151': { county: 'WASHINGTON ', state: 'MS' },
  '28153': { county: 'WAYNE ', state: 'MS' },
  '28155': { county: 'WEBSTER ', state: 'MS' },
  '28157': { county: 'WILKINSON ', state: 'MS' },
  '28159': { county: 'WINSTON ', state: 'MS' },
  '28161': { county: 'YALOBUSHA ', state: 'MS' },
  '28163': { county: 'YAZOO ', state: 'MS' },
  '29000': { county: 'MISSOURI', state: 'MO' },
  '29001': { county: 'ADAIR ', state: 'MO' },
  '29003': { county: 'ANDREW ', state: 'MO' },
  '29005': { county: 'ATCHISON ', state: 'MO' },
  '29007': { county: 'AUDRAIN ', state: 'MO' },
  '29009': { county: 'BARRY ', state: 'MO' },
  '29011': { county: 'BARTON ', state: 'MO' },
  '29013': { county: 'BATES ', state: 'MO' },
  '29015': { county: 'BENTON ', state: 'MO' },
  '29017': { county: 'BOLLINGER ', state: 'MO' },
  '29019': { county: 'BOONE ', state: 'MO' },
  '29021': { county: 'BUCHANAN ', state: 'MO' },
  '29023': { county: 'BUTLER ', state: 'MO' },
  '29025': { county: 'CALDWELL ', state: 'MO' },
  '29027': { county: 'CALLAWAY ', state: 'MO' },
  '29029': { county: 'CAMDEN ', state: 'MO' },
  '29031': { county: 'CAPE GIRARDEAU ', state: 'MO' },
  '29033': { county: 'CARROLL ', state: 'MO' },
  '29035': { county: 'CARTER ', state: 'MO' },
  '29037': { county: 'CASS ', state: 'MO' },
  '29039': { county: 'CEDAR ', state: 'MO' },
  '29041': { county: 'CHARITON ', state: 'MO' },
  '29043': { county: 'CHRISTIAN ', state: 'MO' },
  '29045': { county: 'CLARK ', state: 'MO' },
  '29047': { county: 'CLAY ', state: 'MO' },
  '29049': { county: 'CLINTON ', state: 'MO' },
  '29051': { county: 'COLE ', state: 'MO' },
  '29053': { county: 'COOPER ', state: 'MO' },
  '29055': { county: 'CRAWFORD ', state: 'MO' },
  '29057': { county: 'DADE ', state: 'MO' },
  '29059': { county: 'DALLAS ', state: 'MO' },
  '29061': { county: 'DAVIESS ', state: 'MO' },
  '29063': { county: 'DEKALB ', state: 'MO' },
  '29065': { county: 'DENT ', state: 'MO' },
  '29067': { county: 'DOUGLAS ', state: 'MO' },
  '29069': { county: 'DUNKLIN ', state: 'MO' },
  '29071': { county: 'FRANKLIN ', state: 'MO' },
  '29073': { county: 'GASCONADE ', state: 'MO' },
  '29075': { county: 'GENTRY ', state: 'MO' },
  '29077': { county: 'GREENE ', state: 'MO' },
  '29079': { county: 'GRUNDY ', state: 'MO' },
  '29081': { county: 'HARRISON ', state: 'MO' },
  '29083': { county: 'HENRY ', state: 'MO' },
  '29085': { county: 'HICKORY ', state: 'MO' },
  '29087': { county: 'HOLT ', state: 'MO' },
  '29089': { county: 'HOWARD ', state: 'MO' },
  '29091': { county: 'HOWELL ', state: 'MO' },
  '29093': { county: 'IRON ', state: 'MO' },
  '29095': { county: 'JACKSON ', state: 'MO' },
  '29097': { county: 'JASPER ', state: 'MO' },
  '29099': { county: 'JEFFERSON ', state: 'MO' },
  '29101': { county: 'JOHNSON ', state: 'MO' },
  '29103': { county: 'KNOX ', state: 'MO' },
  '29105': { county: 'LACLEDE ', state: 'MO' },
  '29107': { county: 'LAFAYETTE ', state: 'MO' },
  '29109': { county: 'LAWRENCE ', state: 'MO' },
  '29111': { county: 'LEWIS ', state: 'MO' },
  '29113': { county: 'LINCOLN ', state: 'MO' },
  '29115': { county: 'LINN ', state: 'MO' },
  '29117': { county: 'LIVINGSTON ', state: 'MO' },
  '29119': { county: 'MCDONALD ', state: 'MO' },
  '29121': { county: 'MACON ', state: 'MO' },
  '29123': { county: 'MADISON ', state: 'MO' },
  '29125': { county: 'MARIES ', state: 'MO' },
  '29127': { county: 'MARION ', state: 'MO' },
  '29129': { county: 'MERCER ', state: 'MO' },
  '29131': { county: 'MILLER ', state: 'MO' },
  '29133': { county: 'MISSISSIPPI ', state: 'MO' },
  '29135': { county: 'MONITEAU ', state: 'MO' },
  '29137': { county: 'MONROE ', state: 'MO' },
  '29139': { county: 'MONTGOMERY ', state: 'MO' },
  '29141': { county: 'MORGAN ', state: 'MO' },
  '29143': { county: 'NEW MADRID ', state: 'MO' },
  '29145': { county: 'NEWTON ', state: 'MO' },
  '29147': { county: 'NODAWAY ', state: 'MO' },
  '29149': { county: 'OREGON ', state: 'MO' },
  '29151': { county: 'OSAGE ', state: 'MO' },
  '29153': { county: 'OZARK ', state: 'MO' },
  '29155': { county: 'PEMISCOT ', state: 'MO' },
  '29157': { county: 'PERRY ', state: 'MO' },
  '29159': { county: 'PETTIS ', state: 'MO' },
  '29161': { county: 'PHELPS ', state: 'MO' },
  '29163': { county: 'PIKE ', state: 'MO' },
  '29165': { county: 'PLATTE ', state: 'MO' },
  '29167': { county: 'POLK ', state: 'MO' },
  '29169': { county: 'PULASKI ', state: 'MO' },
  '29171': { county: 'PUTNAM ', state: 'MO' },
  '29173': { county: 'RALLS ', state: 'MO' },
  '29175': { county: 'RANDOLPH ', state: 'MO' },
  '29177': { county: 'RAY ', state: 'MO' },
  '29179': { county: 'REYNOLDS ', state: 'MO' },
  '29181': { county: 'RIPLEY ', state: 'MO' },
  '29183': { county: 'ST. CHARLES ', state: 'MO' },
  '29185': { county: 'ST. CLAIR ', state: 'MO' },
  '29186': { county: 'STE. GENEVIEVE ', state: 'MO' },
  '29187': { county: 'ST. FRANCOIS ', state: 'MO' },
  '29189': { county: 'ST. LOUIS ', state: 'MO' },
  '29195': { county: 'SALINE ', state: 'MO' },
  '29197': { county: 'SCHUYLER ', state: 'MO' },
  '29199': { county: 'SCOTLAND ', state: 'MO' },
  '29201': { county: 'SCOTT ', state: 'MO' },
  '29203': { county: 'SHANNON ', state: 'MO' },
  '29205': { county: 'SHELBY ', state: 'MO' },
  '29207': { county: 'STODDARD ', state: 'MO' },
  '29209': { county: 'STONE ', state: 'MO' },
  '29211': { county: 'SULLIVAN ', state: 'MO' },
  '29213': { county: 'TANEY ', state: 'MO' },
  '29215': { county: 'TEXAS ', state: 'MO' },
  '29217': { county: 'VERNON ', state: 'MO' },
  '29219': { county: 'WARREN ', state: 'MO' },
  '29221': { county: 'WASHINGTON ', state: 'MO' },
  '29223': { county: 'WAYNE ', state: 'MO' },
  '29225': { county: 'WEBSTER ', state: 'MO' },
  '29227': { county: 'WORTH ', state: 'MO' },
  '29229': { county: 'WRIGHT ', state: 'MO' },
  '29510': { county: 'ST. LOUIS CITY', state: 'MO' },
  '30000': { county: 'MONTANA', state: 'MT' },
  '30001': { county: 'BEAVERHEAD ', state: 'MT' },
  '30003': { county: 'BIG HORN ', state: 'MT' },
  '30005': { county: 'BLAINE ', state: 'MT' },
  '30007': { county: 'BROADWATER ', state: 'MT' },
  '30009': { county: 'CARBON ', state: 'MT' },
  '30011': { county: 'CARTER ', state: 'MT' },
  '30013': { county: 'CASCADE ', state: 'MT' },
  '30015': { county: 'CHOUTEAU ', state: 'MT' },
  '30017': { county: 'CUSTER ', state: 'MT' },
  '30019': { county: 'DANIELS ', state: 'MT' },
  '30021': { county: 'DAWSON ', state: 'MT' },
  '30023': { county: 'DEER LODGE ', state: 'MT' },
  '30025': { county: 'FALLON ', state: 'MT' },
  '30027': { county: 'FERGUS ', state: 'MT' },
  '30029': { county: 'FLATHEAD ', state: 'MT' },
  '30031': { county: 'GALLATIN ', state: 'MT' },
  '30033': { county: 'GARFIELD ', state: 'MT' },
  '30035': { county: 'GLACIER ', state: 'MT' },
  '30037': { county: 'GOLDEN VALLEY ', state: 'MT' },
  '30039': { county: 'GRANITE ', state: 'MT' },
  '30041': { county: 'HILL ', state: 'MT' },
  '30043': { county: 'JEFFERSON ', state: 'MT' },
  '30045': { county: 'JUDITH BASIN ', state: 'MT' },
  '30047': { county: 'LAKE ', state: 'MT' },
  '30049': { county: 'LEWIS AND CLARK ', state: 'MT' },
  '30051': { county: 'LIBERTY ', state: 'MT' },
  '30053': { county: 'LINCOLN ', state: 'MT' },
  '30055': { county: 'MCCONE ', state: 'MT' },
  '30057': { county: 'MADISON ', state: 'MT' },
  '30059': { county: 'MEAGHER ', state: 'MT' },
  '30061': { county: 'MINERAL ', state: 'MT' },
  '30063': { county: 'MISSOULA ', state: 'MT' },
  '30065': { county: 'MUSSELSHELL ', state: 'MT' },
  '30067': { county: 'PARK ', state: 'MT' },
  '30069': { county: 'PETROLEUM ', state: 'MT' },
  '30071': { county: 'PHILLIPS ', state: 'MT' },
  '30073': { county: 'PONDERA ', state: 'MT' },
  '30075': { county: 'POWDER RIVER ', state: 'MT' },
  '30077': { county: 'POWELL ', state: 'MT' },
  '30079': { county: 'PRAIRIE ', state: 'MT' },
  '30081': { county: 'RAVALLI ', state: 'MT' },
  '30083': { county: 'RICHLAND ', state: 'MT' },
  '30085': { county: 'ROOSEVELT ', state: 'MT' },
  '30087': { county: 'ROSEBUD ', state: 'MT' },
  '30089': { county: 'SANDERS ', state: 'MT' },
  '30091': { county: 'SHERIDAN ', state: 'MT' },
  '30093': { county: 'SILVER BOW ', state: 'MT' },
  '30095': { county: 'STILLWATER ', state: 'MT' },
  '30097': { county: 'SWEET GRASS ', state: 'MT' },
  '30099': { county: 'TETON ', state: 'MT' },
  '30101': { county: 'TOOLE ', state: 'MT' },
  '30103': { county: 'TREASURE ', state: 'MT' },
  '30105': { county: 'VALLEY ', state: 'MT' },
  '30107': { county: 'WHEATLAND ', state: 'MT' },
  '30109': { county: 'WIBAUX ', state: 'MT' },
  '30111': { county: 'YELLOWSTONE ', state: 'MT' },
  '31000': { county: 'NEBRASKA', state: 'NE' },
  '31001': { county: 'ADAMS ', state: 'NE' },
  '31003': { county: 'ANTELOPE ', state: 'NE' },
  '31005': { county: 'ARTHUR ', state: 'NE' },
  '31007': { county: 'BANNER ', state: 'NE' },
  '31009': { county: 'BLAINE ', state: 'NE' },
  '31011': { county: 'BOONE ', state: 'NE' },
  '31013': { county: 'BOX BUTTE ', state: 'NE' },
  '31015': { county: 'BOYD ', state: 'NE' },
  '31017': { county: 'BROWN ', state: 'NE' },
  '31019': { county: 'BUFFALO ', state: 'NE' },
  '31021': { county: 'BURT ', state: 'NE' },
  '31023': { county: 'BUTLER ', state: 'NE' },
  '31025': { county: 'CASS ', state: 'NE' },
  '31027': { county: 'CEDAR ', state: 'NE' },
  '31029': { county: 'CHASE ', state: 'NE' },
  '31031': { county: 'CHERRY ', state: 'NE' },
  '31033': { county: 'CHEYENNE ', state: 'NE' },
  '31035': { county: 'CLAY ', state: 'NE' },
  '31037': { county: 'COLFAX ', state: 'NE' },
  '31039': { county: 'CUMING ', state: 'NE' },
  '31041': { county: 'CUSTER ', state: 'NE' },
  '31043': { county: 'DAKOTA ', state: 'NE' },
  '31045': { county: 'DAWES ', state: 'NE' },
  '31047': { county: 'DAWSON ', state: 'NE' },
  '31049': { county: 'DEUEL ', state: 'NE' },
  '31051': { county: 'DIXON ', state: 'NE' },
  '31053': { county: 'DODGE ', state: 'NE' },
  '31055': { county: 'DOUGLAS ', state: 'NE' },
  '31057': { county: 'DUNDY ', state: 'NE' },
  '31059': { county: 'FILLMORE ', state: 'NE' },
  '31061': { county: 'FRANKLIN ', state: 'NE' },
  '31063': { county: 'FRONTIER ', state: 'NE' },
  '31065': { county: 'FURNAS ', state: 'NE' },
  '31067': { county: 'GAGE ', state: 'NE' },
  '31069': { county: 'GARDEN ', state: 'NE' },
  '31071': { county: 'GARFIELD ', state: 'NE' },
  '31073': { county: 'GOSPER ', state: 'NE' },
  '31075': { county: 'GRANT ', state: 'NE' },
  '31077': { county: 'GREELEY ', state: 'NE' },
  '31079': { county: 'HALL ', state: 'NE' },
  '31081': { county: 'HAMILTON ', state: 'NE' },
  '31083': { county: 'HARLAN ', state: 'NE' },
  '31085': { county: 'HAYES ', state: 'NE' },
  '31087': { county: 'HITCHCOCK ', state: 'NE' },
  '31089': { county: 'HOLT ', state: 'NE' },
  '31091': { county: 'HOOKER ', state: 'NE' },
  '31093': { county: 'HOWARD ', state: 'NE' },
  '31095': { county: 'JEFFERSON ', state: 'NE' },
  '31097': { county: 'JOHNSON ', state: 'NE' },
  '31099': { county: 'KEARNEY ', state: 'NE' },
  '31101': { county: 'KEITH ', state: 'NE' },
  '31103': { county: 'KEYA PAHA ', state: 'NE' },
  '31105': { county: 'KIMBALL ', state: 'NE' },
  '31107': { county: 'KNOX ', state: 'NE' },
  '31109': { county: 'LANCASTER ', state: 'NE' },
  '31111': { county: 'LINCOLN ', state: 'NE' },
  '31113': { county: 'LOGAN ', state: 'NE' },
  '31115': { county: 'LOUP ', state: 'NE' },
  '31117': { county: 'MCPHERSON ', state: 'NE' },
  '31119': { county: 'MADISON ', state: 'NE' },
  '31121': { county: 'MERRICK ', state: 'NE' },
  '31123': { county: 'MORRILL ', state: 'NE' },
  '31125': { county: 'NANCE ', state: 'NE' },
  '31127': { county: 'NEMAHA ', state: 'NE' },
  '31129': { county: 'NUCKOLLS ', state: 'NE' },
  '31131': { county: 'OTOE ', state: 'NE' },
  '31133': { county: 'PAWNEE ', state: 'NE' },
  '31135': { county: 'PERKINS ', state: 'NE' },
  '31137': { county: 'PHELPS ', state: 'NE' },
  '31139': { county: 'PIERCE ', state: 'NE' },
  '31141': { county: 'PLATTE ', state: 'NE' },
  '31143': { county: 'POLK ', state: 'NE' },
  '31145': { county: 'RED WILLOW ', state: 'NE' },
  '31147': { county: 'RICHARDSON ', state: 'NE' },
  '31149': { county: 'ROCK ', state: 'NE' },
  '31151': { county: 'SALINE ', state: 'NE' },
  '31153': { county: 'SARPY ', state: 'NE' },
  '31155': { county: 'SAUNDERS ', state: 'NE' },
  '31157': { county: 'SCOTTS BLUFF ', state: 'NE' },
  '31159': { county: 'SEWARD ', state: 'NE' },
  '31161': { county: 'SHERIDAN ', state: 'NE' },
  '31163': { county: 'SHERMAN ', state: 'NE' },
  '31165': { county: 'SIOUX ', state: 'NE' },
  '31167': { county: 'STANTON ', state: 'NE' },
  '31169': { county: 'THAYER ', state: 'NE' },
  '31171': { county: 'THOMAS ', state: 'NE' },
  '31173': { county: 'THURSTON ', state: 'NE' },
  '31175': { county: 'VALLEY ', state: 'NE' },
  '31177': { county: 'WASHINGTON ', state: 'NE' },
  '31179': { county: 'WAYNE ', state: 'NE' },
  '31181': { county: 'WEBSTER ', state: 'NE' },
  '31183': { county: 'WHEELER ', state: 'NE' },
  '31185': { county: 'YORK ', state: 'NE' },
  '32000': { county: 'NEVADA', state: 'NV' },
  '32001': { county: 'CHURCHILL ', state: 'NV' },
  '32003': { county: 'CLARK ', state: 'NV' },
  '32005': { county: 'DOUGLAS ', state: 'NV' },
  '32007': { county: 'ELKO ', state: 'NV' },
  '32009': { county: 'ESMERALDA ', state: 'NV' },
  '32011': { county: 'EUREKA ', state: 'NV' },
  '32013': { county: 'HUMBOLDT ', state: 'NV' },
  '32015': { county: 'LANDER ', state: 'NV' },
  '32017': { county: 'LINCOLN ', state: 'NV' },
  '32019': { county: 'LYON ', state: 'NV' },
  '32021': { county: 'MINERAL ', state: 'NV' },
  '32023': { county: 'NYE ', state: 'NV' },
  '32027': { county: 'PERSHING ', state: 'NV' },
  '32029': { county: 'STOREY ', state: 'NV' },
  '32031': { county: 'WASHOE ', state: 'NV' },
  '32033': { county: 'WHITE PINE ', state: 'NV' },
  '32510': { county: 'CARSON CITY', state: 'NV' },
  '33000': { county: 'NEW HAMPSHIRE', state: 'NH' },
  '33001': { county: 'BELKNAP ', state: 'NH' },
  '33003': { county: 'CARROLL ', state: 'NH' },
  '33005': { county: 'CHESHIRE ', state: 'NH' },
  '33007': { county: 'COOS ', state: 'NH' },
  '33009': { county: 'GRAFTON ', state: 'NH' },
  '33011': { county: 'HILLSBOROUGH ', state: 'NH' },
  '33013': { county: 'MERRIMACK ', state: 'NH' },
  '33015': { county: 'ROCKINGHAM ', state: 'NH' },
  '33017': { county: 'STRAFFORD ', state: 'NH' },
  '33019': { county: 'SULLIVAN ', state: 'NH' },
  '34000': { county: 'NEW JERSEY', state: 'NJ' },
  '34001': { county: 'ATLANTIC ', state: 'NJ' },
  '34003': { county: 'BERGEN ', state: 'NJ' },
  '34005': { county: 'BURLINGTON ', state: 'NJ' },
  '34007': { county: 'CAMDEN ', state: 'NJ' },
  '34009': { county: 'CAPE MAY ', state: 'NJ' },
  '34011': { county: 'CUMBERLAND ', state: 'NJ' },
  '34013': { county: 'ESSEX ', state: 'NJ' },
  '34015': { county: 'GLOUCESTER ', state: 'NJ' },
  '34017': { county: 'HUDSON ', state: 'NJ' },
  '34019': { county: 'HUNTERDON ', state: 'NJ' },
  '34021': { county: 'MERCER ', state: 'NJ' },
  '34023': { county: 'MIDDLESEX ', state: 'NJ' },
  '34025': { county: 'MONMOUTH ', state: 'NJ' },
  '34027': { county: 'MORRIS ', state: 'NJ' },
  '34029': { county: 'OCEAN ', state: 'NJ' },
  '34031': { county: 'PASSAIC ', state: 'NJ' },
  '34033': { county: 'SALEM ', state: 'NJ' },
  '34035': { county: 'SOMERSET ', state: 'NJ' },
  '34037': { county: 'SUSSEX ', state: 'NJ' },
  '34039': { county: 'UNION ', state: 'NJ' },
  '34041': { county: 'WARREN ', state: 'NJ' },
  '35000': { county: 'NEW MEXICO', state: 'NM' },
  '35001': { county: 'BERNALILLO ', state: 'NM' },
  '35003': { county: 'CATRON ', state: 'NM' },
  '35005': { county: 'CHAVES ', state: 'NM' },
  '35006': { county: 'CIBOLA ', state: 'NM' },
  '35007': { county: 'COLFAX ', state: 'NM' },
  '35009': { county: 'CURRY ', state: 'NM' },
  '35011': { county: 'DE BACA ', state: 'NM' },
  '35013': { county: 'DOÑA ANA ', state: 'NM' },
  '35015': { county: 'EDDY ', state: 'NM' },
  '35017': { county: 'GRANT ', state: 'NM' },
  '35019': { county: 'GUADALUPE ', state: 'NM' },
  '35021': { county: 'HARDING ', state: 'NM' },
  '35023': { county: 'HIDALGO ', state: 'NM' },
  '35025': { county: 'LEA ', state: 'NM' },
  '35027': { county: 'LINCOLN ', state: 'NM' },
  '35028': { county: 'LOS ALAMOS ', state: 'NM' },
  '35029': { county: 'LUNA ', state: 'NM' },
  '35031': { county: 'MCKINLEY ', state: 'NM' },
  '35033': { county: 'MORA ', state: 'NM' },
  '35035': { county: 'OTERO ', state: 'NM' },
  '35037': { county: 'QUAY ', state: 'NM' },
  '35039': { county: 'RIO ARRIBA ', state: 'NM' },
  '35041': { county: 'ROOSEVELT ', state: 'NM' },
  '35043': { county: 'SANDOVAL ', state: 'NM' },
  '35045': { county: 'SAN JUAN ', state: 'NM' },
  '35047': { county: 'SAN MIGUEL ', state: 'NM' },
  '35049': { county: 'SANTA FE ', state: 'NM' },
  '35051': { county: 'SIERRA ', state: 'NM' },
  '35053': { county: 'SOCORRO ', state: 'NM' },
  '35055': { county: 'TAOS ', state: 'NM' },
  '35057': { county: 'TORRANCE ', state: 'NM' },
  '35059': { county: 'UNION ', state: 'NM' },
  '35061': { county: 'VALENCIA ', state: 'NM' },
  '36000': { county: 'NEW YORK', state: 'NY' },
  '36001': { county: 'ALBANY ', state: 'NY' },
  '36003': { county: 'ALLEGANY ', state: 'NY' },
  '36005': { county: 'BRONX ', state: 'NY' },
  '36007': { county: 'BROOME ', state: 'NY' },
  '36009': { county: 'CATTARAUGUS ', state: 'NY' },
  '36011': { county: 'CAYUGA ', state: 'NY' },
  '36013': { county: 'CHAUTAUQUA ', state: 'NY' },
  '36015': { county: 'CHEMUNG ', state: 'NY' },
  '36017': { county: 'CHENANGO ', state: 'NY' },
  '36019': { county: 'CLINTON ', state: 'NY' },
  '36021': { county: 'COLUMBIA ', state: 'NY' },
  '36023': { county: 'CORTLAND ', state: 'NY' },
  '36025': { county: 'DELAWARE ', state: 'NY' },
  '36027': { county: 'DUTCHESS ', state: 'NY' },
  '36029': { county: 'ERIE ', state: 'NY' },
  '36031': { county: 'ESSEX ', state: 'NY' },
  '36033': { county: 'FRANKLIN ', state: 'NY' },
  '36035': { county: 'FULTON ', state: 'NY' },
  '36037': { county: 'GENESEE ', state: 'NY' },
  '36039': { county: 'GREENE ', state: 'NY' },
  '36041': { county: 'HAMILTON ', state: 'NY' },
  '36043': { county: 'HERKIMER ', state: 'NY' },
  '36045': { county: 'JEFFERSON ', state: 'NY' },
  '36047': { county: 'KINGS ', state: 'NY' },
  '36049': { county: 'LEWIS ', state: 'NY' },
  '36051': { county: 'LIVINGSTON ', state: 'NY' },
  '36053': { county: 'MADISON ', state: 'NY' },
  '36055': { county: 'MONROE ', state: 'NY' },
  '36057': { county: 'MONTGOMERY ', state: 'NY' },
  '36059': { county: 'NASSAU ', state: 'NY' },
  '36061': { county: 'NEW YORK ', state: 'NY' },
  '36063': { county: 'NIAGARA ', state: 'NY' },
  '36065': { county: 'ONEIDA ', state: 'NY' },
  '36067': { county: 'ONONDAGA ', state: 'NY' },
  '36069': { county: 'ONTARIO ', state: 'NY' },
  '36071': { county: 'ORANGE ', state: 'NY' },
  '36073': { county: 'ORLEANS ', state: 'NY' },
  '36075': { county: 'OSWEGO ', state: 'NY' },
  '36077': { county: 'OTSEGO ', state: 'NY' },
  '36079': { county: 'PUTNAM ', state: 'NY' },
  '36081': { county: 'QUEENS ', state: 'NY' },
  '36083': { county: 'RENSSELAER ', state: 'NY' },
  '36085': { county: 'RICHMOND ', state: 'NY' },
  '36087': { county: 'ROCKLAND ', state: 'NY' },
  '36089': { county: 'ST. LAWRENCE ', state: 'NY' },
  '36091': { county: 'SARATOGA ', state: 'NY' },
  '36093': { county: 'SCHENECTADY ', state: 'NY' },
  '36095': { county: 'SCHOHARIE ', state: 'NY' },
  '36097': { county: 'SCHUYLER ', state: 'NY' },
  '36099': { county: 'SENECA ', state: 'NY' },
  '36101': { county: 'STEUBEN ', state: 'NY' },
  '36103': { county: 'SUFFOLK ', state: 'NY' },
  '36105': { county: 'SULLIVAN ', state: 'NY' },
  '36107': { county: 'TIOGA ', state: 'NY' },
  '36109': { county: 'TOMPKINS ', state: 'NY' },
  '36111': { county: 'ULSTER ', state: 'NY' },
  '36113': { county: 'WARREN ', state: 'NY' },
  '36115': { county: 'WASHINGTON ', state: 'NY' },
  '36117': { county: 'WAYNE ', state: 'NY' },
  '36119': { county: 'WESTCHESTER ', state: 'NY' },
  '36121': { county: 'WYOMING ', state: 'NY' },
  '36123': { county: 'YATES ', state: 'NY' },
  '37000': { county: 'NORTH CAROLINA', state: 'NC' },
  '37001': { county: 'ALAMANCE ', state: 'NC' },
  '37003': { county: 'ALEXANDER ', state: 'NC' },
  '37005': { county: 'ALLEGHANY ', state: 'NC' },
  '37007': { county: 'ANSON ', state: 'NC' },
  '37009': { county: 'ASHE ', state: 'NC' },
  '37011': { county: 'AVERY ', state: 'NC' },
  '37013': { county: 'BEAUFORT ', state: 'NC' },
  '37015': { county: 'BERTIE ', state: 'NC' },
  '37017': { county: 'BLADEN ', state: 'NC' },
  '37019': { county: 'BRUNSWICK ', state: 'NC' },
  '37021': { county: 'BUNCOMBE ', state: 'NC' },
  '37023': { county: 'BURKE ', state: 'NC' },
  '37025': { county: 'CABARRUS ', state: 'NC' },
  '37027': { county: 'CALDWELL ', state: 'NC' },
  '37029': { county: 'CAMDEN ', state: 'NC' },
  '37031': { county: 'CARTERET ', state: 'NC' },
  '37033': { county: 'CASWELL ', state: 'NC' },
  '37035': { county: 'CATAWBA ', state: 'NC' },
  '37037': { county: 'CHATHAM ', state: 'NC' },
  '37039': { county: 'CHEROKEE ', state: 'NC' },
  '37041': { county: 'CHOWAN ', state: 'NC' },
  '37043': { county: 'CLAY ', state: 'NC' },
  '37045': { county: 'CLEVELAND ', state: 'NC' },
  '37047': { county: 'COLUMBUS ', state: 'NC' },
  '37049': { county: 'CRAVEN ', state: 'NC' },
  '37051': { county: 'CUMBERLAND ', state: 'NC' },
  '37053': { county: 'CURRITUCK ', state: 'NC' },
  '37055': { county: 'DARE ', state: 'NC' },
  '37057': { county: 'DAVIDSON ', state: 'NC' },
  '37059': { county: 'DAVIE ', state: 'NC' },
  '37061': { county: 'DUPLIN ', state: 'NC' },
  '37063': { county: 'DURHAM ', state: 'NC' },
  '37065': { county: 'EDGECOMBE ', state: 'NC' },
  '37067': { county: 'FORSYTH ', state: 'NC' },
  '37069': { county: 'FRANKLIN ', state: 'NC' },
  '37071': { county: 'GASTON ', state: 'NC' },
  '37073': { county: 'GATES ', state: 'NC' },
  '37075': { county: 'GRAHAM ', state: 'NC' },
  '37077': { county: 'GRANVILLE ', state: 'NC' },
  '37079': { county: 'GREENE ', state: 'NC' },
  '37081': { county: 'GUILFORD ', state: 'NC' },
  '37083': { county: 'HALIFAX ', state: 'NC' },
  '37085': { county: 'HARNETT ', state: 'NC' },
  '37087': { county: 'HAYWOOD ', state: 'NC' },
  '37089': { county: 'HENDERSON ', state: 'NC' },
  '37091': { county: 'HERTFORD ', state: 'NC' },
  '37093': { county: 'HOKE ', state: 'NC' },
  '37095': { county: 'HYDE ', state: 'NC' },
  '37097': { county: 'IREDELL ', state: 'NC' },
  '37099': { county: 'JACKSON ', state: 'NC' },
  '37101': { county: 'JOHNSTON ', state: 'NC' },
  '37103': { county: 'JONES ', state: 'NC' },
  '37105': { county: 'LEE ', state: 'NC' },
  '37107': { county: 'LENOIR ', state: 'NC' },
  '37109': { county: 'LINCOLN ', state: 'NC' },
  '37111': { county: 'MCDOWELL ', state: 'NC' },
  '37113': { county: 'MACON ', state: 'NC' },
  '37115': { county: 'MADISON ', state: 'NC' },
  '37117': { county: 'MARTIN ', state: 'NC' },
  '37119': { county: 'MECKLENBURG ', state: 'NC' },
  '37121': { county: 'MITCHELL ', state: 'NC' },
  '37123': { county: 'MONTGOMERY ', state: 'NC' },
  '37125': { county: 'MOORE ', state: 'NC' },
  '37127': { county: 'NASH ', state: 'NC' },
  '37129': { county: 'NEW HANOVER ', state: 'NC' },
  '37131': { county: 'NORTHAMPTON ', state: 'NC' },
  '37133': { county: 'ONSLOW ', state: 'NC' },
  '37135': { county: 'ORANGE ', state: 'NC' },
  '37137': { county: 'PAMLICO ', state: 'NC' },
  '37139': { county: 'PASQUOTANK ', state: 'NC' },
  '37141': { county: 'PENDER ', state: 'NC' },
  '37143': { county: 'PERQUIMANS ', state: 'NC' },
  '37145': { county: 'PERSON ', state: 'NC' },
  '37147': { county: 'PITT ', state: 'NC' },
  '37149': { county: 'POLK ', state: 'NC' },
  '37151': { county: 'RANDOLPH ', state: 'NC' },
  '37153': { county: 'RICHMOND ', state: 'NC' },
  '37155': { county: 'ROBESON ', state: 'NC' },
  '37157': { county: 'ROCKINGHAM ', state: 'NC' },
  '37159': { county: 'ROWAN ', state: 'NC' },
  '37161': { county: 'RUTHERFORD ', state: 'NC' },
  '37163': { county: 'SAMPSON ', state: 'NC' },
  '37165': { county: 'SCOTLAND ', state: 'NC' },
  '37167': { county: 'STANLY ', state: 'NC' },
  '37169': { county: 'STOKES ', state: 'NC' },
  '37171': { county: 'SURRY ', state: 'NC' },
  '37173': { county: 'SWAIN ', state: 'NC' },
  '37175': { county: 'TRANSYLVANIA ', state: 'NC' },
  '37177': { county: 'TYRRELL ', state: 'NC' },
  '37179': { county: 'UNION ', state: 'NC' },
  '37181': { county: 'VANCE ', state: 'NC' },
  '37183': { county: 'WAKE ', state: 'NC' },
  '37185': { county: 'WARREN ', state: 'NC' },
  '37187': { county: 'WASHINGTON ', state: 'NC' },
  '37189': { county: 'WATAUGA ', state: 'NC' },
  '37191': { county: 'WAYNE ', state: 'NC' },
  '37193': { county: 'WILKES ', state: 'NC' },
  '37195': { county: 'WILSON ', state: 'NC' },
  '37197': { county: 'YADKIN ', state: 'NC' },
  '37199': { county: 'YANCEY ', state: 'NC' },
  '38000': { county: 'NORTH DAKOTA', state: 'ND' },
  '38001': { county: 'ADAMS ', state: 'ND' },
  '38003': { county: 'BARNES ', state: 'ND' },
  '38005': { county: 'BENSON ', state: 'ND' },
  '38007': { county: 'BILLINGS ', state: 'ND' },
  '38009': { county: 'BOTTINEAU ', state: 'ND' },
  '38011': { county: 'BOWMAN ', state: 'ND' },
  '38013': { county: 'BURKE ', state: 'ND' },
  '38015': { county: 'BURLEIGH ', state: 'ND' },
  '38017': { county: 'CASS ', state: 'ND' },
  '38019': { county: 'CAVALIER ', state: 'ND' },
  '38021': { county: 'DICKEY ', state: 'ND' },
  '38023': { county: 'DIVIDE ', state: 'ND' },
  '38025': { county: 'DUNN ', state: 'ND' },
  '38027': { county: 'EDDY ', state: 'ND' },
  '38029': { county: 'EMMONS ', state: 'ND' },
  '38031': { county: 'FOSTER ', state: 'ND' },
  '38033': { county: 'GOLDEN VALLEY ', state: 'ND' },
  '38035': { county: 'GRAND FORKS ', state: 'ND' },
  '38037': { county: 'GRANT ', state: 'ND' },
  '38039': { county: 'GRIGGS ', state: 'ND' },
  '38041': { county: 'HETTINGER ', state: 'ND' },
  '38043': { county: 'KIDDER ', state: 'ND' },
  '38045': { county: 'LAMOURE ', state: 'ND' },
  '38047': { county: 'LOGAN ', state: 'ND' },
  '38049': { county: 'MCHENRY ', state: 'ND' },
  '38051': { county: 'MCINTOSH ', state: 'ND' },
  '38053': { county: 'MCKENZIE ', state: 'ND' },
  '38055': { county: 'MCLEAN ', state: 'ND' },
  '38057': { county: 'MERCER ', state: 'ND' },
  '38059': { county: 'MORTON ', state: 'ND' },
  '38061': { county: 'MOUNTRAIL ', state: 'ND' },
  '38063': { county: 'NELSON ', state: 'ND' },
  '38065': { county: 'OLIVER ', state: 'ND' },
  '38067': { county: 'PEMBINA ', state: 'ND' },
  '38069': { county: 'PIERCE ', state: 'ND' },
  '38071': { county: 'RAMSEY ', state: 'ND' },
  '38073': { county: 'RANSOM ', state: 'ND' },
  '38075': { county: 'RENVILLE ', state: 'ND' },
  '38077': { county: 'RICHLAND ', state: 'ND' },
  '38079': { county: 'ROLETTE ', state: 'ND' },
  '38081': { county: 'SARGENT ', state: 'ND' },
  '38083': { county: 'SHERIDAN ', state: 'ND' },
  '38085': { county: 'SIOUX ', state: 'ND' },
  '38087': { county: 'SLOPE ', state: 'ND' },
  '38089': { county: 'STARK ', state: 'ND' },
  '38091': { county: 'STEELE ', state: 'ND' },
  '38093': { county: 'STUTSMAN ', state: 'ND' },
  '38095': { county: 'TOWNER ', state: 'ND' },
  '38097': { county: 'TRAILL ', state: 'ND' },
  '38099': { county: 'WALSH ', state: 'ND' },
  '38101': { county: 'WARD ', state: 'ND' },
  '38103': { county: 'WELLS ', state: 'ND' },
  '38105': { county: 'WILLIAMS ', state: 'ND' },
  '39000': { county: 'OHIO', state: 'OH' },
  '39001': { county: 'ADAMS ', state: 'OH' },
  '39003': { county: 'ALLEN ', state: 'OH' },
  '39005': { county: 'ASHLAND ', state: 'OH' },
  '39007': { county: 'ASHTABULA ', state: 'OH' },
  '39009': { county: 'ATHENS ', state: 'OH' },
  '39011': { county: 'AUGLAIZE ', state: 'OH' },
  '39013': { county: 'BELMONT ', state: 'OH' },
  '39015': { county: 'BROWN ', state: 'OH' },
  '39017': { county: 'BUTLER ', state: 'OH' },
  '39019': { county: 'CARROLL ', state: 'OH' },
  '39021': { county: 'CHAMPAIGN ', state: 'OH' },
  '39023': { county: 'CLARK ', state: 'OH' },
  '39025': { county: 'CLERMONT ', state: 'OH' },
  '39027': { county: 'CLINTON ', state: 'OH' },
  '39029': { county: 'COLUMBIANA ', state: 'OH' },
  '39031': { county: 'COSHOCTON ', state: 'OH' },
  '39033': { county: 'CRAWFORD ', state: 'OH' },
  '39035': { county: 'CUYAHOGA ', state: 'OH' },
  '39037': { county: 'DARKE ', state: 'OH' },
  '39039': { county: 'DEFIANCE ', state: 'OH' },
  '39041': { county: 'DELAWARE ', state: 'OH' },
  '39043': { county: 'ERIE ', state: 'OH' },
  '39045': { county: 'FAIRFIELD ', state: 'OH' },
  '39047': { county: 'FAYETTE ', state: 'OH' },
  '39049': { county: 'FRANKLIN ', state: 'OH' },
  '39051': { county: 'FULTON ', state: 'OH' },
  '39053': { county: 'GALLIA ', state: 'OH' },
  '39055': { county: 'GEAUGA ', state: 'OH' },
  '39057': { county: 'GREENE ', state: 'OH' },
  '39059': { county: 'GUERNSEY ', state: 'OH' },
  '39061': { county: 'HAMILTON ', state: 'OH' },
  '39063': { county: 'HANCOCK ', state: 'OH' },
  '39065': { county: 'HARDIN ', state: 'OH' },
  '39067': { county: 'HARRISON ', state: 'OH' },
  '39069': { county: 'HENRY ', state: 'OH' },
  '39071': { county: 'HIGHLAND ', state: 'OH' },
  '39073': { county: 'HOCKING ', state: 'OH' },
  '39075': { county: 'HOLMES ', state: 'OH' },
  '39077': { county: 'HURON ', state: 'OH' },
  '39079': { county: 'JACKSON ', state: 'OH' },
  '39081': { county: 'JEFFERSON ', state: 'OH' },
  '39083': { county: 'KNOX ', state: 'OH' },
  '39085': { county: 'LAKE ', state: 'OH' },
  '39087': { county: 'LAWRENCE ', state: 'OH' },
  '39089': { county: 'LICKING ', state: 'OH' },
  '39091': { county: 'LOGAN ', state: 'OH' },
  '39093': { county: 'LORAIN ', state: 'OH' },
  '39095': { county: 'LUCAS ', state: 'OH' },
  '39097': { county: 'MADISON ', state: 'OH' },
  '39099': { county: 'MAHONING ', state: 'OH' },
  '39101': { county: 'MARION ', state: 'OH' },
  '39103': { county: 'MEDINA ', state: 'OH' },
  '39105': { county: 'MEIGS ', state: 'OH' },
  '39107': { county: 'MERCER ', state: 'OH' },
  '39109': { county: 'MIAMI ', state: 'OH' },
  '39111': { county: 'MONROE ', state: 'OH' },
  '39113': { county: 'MONTGOMERY ', state: 'OH' },
  '39115': { county: 'MORGAN ', state: 'OH' },
  '39117': { county: 'MORROW ', state: 'OH' },
  '39119': { county: 'MUSKINGUM ', state: 'OH' },
  '39121': { county: 'NOBLE ', state: 'OH' },
  '39123': { county: 'OTTAWA ', state: 'OH' },
  '39125': { county: 'PAULDING ', state: 'OH' },
  '39127': { county: 'PERRY ', state: 'OH' },
  '39129': { county: 'PICKAWAY ', state: 'OH' },
  '39131': { county: 'PIKE ', state: 'OH' },
  '39133': { county: 'PORTAGE ', state: 'OH' },
  '39135': { county: 'PREBLE ', state: 'OH' },
  '39137': { county: 'PUTNAM ', state: 'OH' },
  '39139': { county: 'RICHLAND ', state: 'OH' },
  '39141': { county: 'ROSS ', state: 'OH' },
  '39143': { county: 'SANDUSKY ', state: 'OH' },
  '39145': { county: 'SCIOTO ', state: 'OH' },
  '39147': { county: 'SENECA ', state: 'OH' },
  '39149': { county: 'SHELBY ', state: 'OH' },
  '39151': { county: 'STARK ', state: 'OH' },
  '39153': { county: 'SUMMIT ', state: 'OH' },
  '39155': { county: 'TRUMBULL ', state: 'OH' },
  '39157': { county: 'TUSCARAWAS ', state: 'OH' },
  '39159': { county: 'UNION ', state: 'OH' },
  '39161': { county: 'VAN WERT ', state: 'OH' },
  '39163': { county: 'VINTON ', state: 'OH' },
  '39165': { county: 'WARREN ', state: 'OH' },
  '39167': { county: 'WASHINGTON ', state: 'OH' },
  '39169': { county: 'WAYNE ', state: 'OH' },
  '39171': { county: 'WILLIAMS ', state: 'OH' },
  '39173': { county: 'WOOD ', state: 'OH' },
  '39175': { county: 'WYANDOT ', state: 'OH' },
  '40000': { county: 'OKLAHOMA', state: 'OK' },
  '40001': { county: 'ADAIR ', state: 'OK' },
  '40003': { county: 'ALFALFA ', state: 'OK' },
  '40005': { county: 'ATOKA ', state: 'OK' },
  '40007': { county: 'BEAVER ', state: 'OK' },
  '40009': { county: 'BECKHAM ', state: 'OK' },
  '40011': { county: 'BLAINE ', state: 'OK' },
  '40013': { county: 'BRYAN ', state: 'OK' },
  '40015': { county: 'CADDO ', state: 'OK' },
  '40017': { county: 'CANADIAN ', state: 'OK' },
  '40019': { county: 'CARTER ', state: 'OK' },
  '40021': { county: 'CHEROKEE ', state: 'OK' },
  '40023': { county: 'CHOCTAW ', state: 'OK' },
  '40025': { county: 'CIMARRON ', state: 'OK' },
  '40027': { county: 'CLEVELAND ', state: 'OK' },
  '40029': { county: 'COAL ', state: 'OK' },
  '40031': { county: 'COMANCHE ', state: 'OK' },
  '40033': { county: 'COTTON ', state: 'OK' },
  '40035': { county: 'CRAIG ', state: 'OK' },
  '40037': { county: 'CREEK ', state: 'OK' },
  '40039': { county: 'CUSTER ', state: 'OK' },
  '40041': { county: 'DELAWARE ', state: 'OK' },
  '40043': { county: 'DEWEY ', state: 'OK' },
  '40045': { county: 'ELLIS ', state: 'OK' },
  '40047': { county: 'GARFIELD ', state: 'OK' },
  '40049': { county: 'GARVIN ', state: 'OK' },
  '40051': { county: 'GRADY ', state: 'OK' },
  '40053': { county: 'GRANT ', state: 'OK' },
  '40055': { county: 'GREER ', state: 'OK' },
  '40057': { county: 'HARMON ', state: 'OK' },
  '40059': { county: 'HARPER ', state: 'OK' },
  '40061': { county: 'HASKELL ', state: 'OK' },
  '40063': { county: 'HUGHES ', state: 'OK' },
  '40065': { county: 'JACKSON ', state: 'OK' },
  '40067': { county: 'JEFFERSON ', state: 'OK' },
  '40069': { county: 'JOHNSTON ', state: 'OK' },
  '40071': { county: 'KAY ', state: 'OK' },
  '40073': { county: 'KINGFISHER ', state: 'OK' },
  '40075': { county: 'KIOWA ', state: 'OK' },
  '40077': { county: 'LATIMER ', state: 'OK' },
  '40079': { county: 'LE FLORE ', state: 'OK' },
  '40081': { county: 'LINCOLN ', state: 'OK' },
  '40083': { county: 'LOGAN ', state: 'OK' },
  '40085': { county: 'LOVE ', state: 'OK' },
  '40087': { county: 'MCCLAIN ', state: 'OK' },
  '40089': { county: 'MCCURTAIN ', state: 'OK' },
  '40091': { county: 'MCINTOSH ', state: 'OK' },
  '40093': { county: 'MAJOR ', state: 'OK' },
  '40095': { county: 'MARSHALL ', state: 'OK' },
  '40097': { county: 'MAYES ', state: 'OK' },
  '40099': { county: 'MURRAY ', state: 'OK' },
  '40101': { county: 'MUSKOGEE ', state: 'OK' },
  '40103': { county: 'NOBLE ', state: 'OK' },
  '40105': { county: 'NOWATA ', state: 'OK' },
  '40107': { county: 'OKFUSKEE ', state: 'OK' },
  '40109': { county: 'OKLAHOMA ', state: 'OK' },
  '40111': { county: 'OKMULGEE ', state: 'OK' },
  '40113': { county: 'OSAGE ', state: 'OK' },
  '40115': { county: 'OTTAWA ', state: 'OK' },
  '40117': { county: 'PAWNEE ', state: 'OK' },
  '40119': { county: 'PAYNE ', state: 'OK' },
  '40121': { county: 'PITTSBURG ', state: 'OK' },
  '40123': { county: 'PONTOTOC ', state: 'OK' },
  '40125': { county: 'POTTAWATOMIE ', state: 'OK' },
  '40127': { county: 'PUSHMATAHA ', state: 'OK' },
  '40129': { county: 'ROGER MILLS ', state: 'OK' },
  '40131': { county: 'ROGERS ', state: 'OK' },
  '40133': { county: 'SEMINOLE ', state: 'OK' },
  '40135': { county: 'SEQUOYAH ', state: 'OK' },
  '40137': { county: 'STEPHENS ', state: 'OK' },
  '40139': { county: 'TEXAS ', state: 'OK' },
  '40141': { county: 'TILLMAN ', state: 'OK' },
  '40143': { county: 'TULSA ', state: 'OK' },
  '40145': { county: 'WAGONER ', state: 'OK' },
  '40147': { county: 'WASHINGTON ', state: 'OK' },
  '40149': { county: 'WASHITA ', state: 'OK' },
  '40151': { county: 'WOODS ', state: 'OK' },
  '40153': { county: 'WOODWARD ', state: 'OK' },
  '41000': { county: 'OREGON', state: 'OR' },
  '41001': { county: 'BAKER ', state: 'OR' },
  '41003': { county: 'BENTON ', state: 'OR' },
  '41005': { county: 'CLACKAMAS ', state: 'OR' },
  '41007': { county: 'CLATSOP ', state: 'OR' },
  '41009': { county: 'COLUMBIA ', state: 'OR' },
  '41011': { county: 'COOS ', state: 'OR' },
  '41013': { county: 'CROOK ', state: 'OR' },
  '41015': { county: 'CURRY ', state: 'OR' },
  '41017': { county: 'DESCHUTES ', state: 'OR' },
  '41019': { county: 'DOUGLAS ', state: 'OR' },
  '41021': { county: 'GILLIAM ', state: 'OR' },
  '41023': { county: 'GRANT ', state: 'OR' },
  '41025': { county: 'HARNEY ', state: 'OR' },
  '41027': { county: 'HOOD RIVER ', state: 'OR' },
  '41029': { county: 'JACKSON ', state: 'OR' },
  '41031': { county: 'JEFFERSON ', state: 'OR' },
  '41033': { county: 'JOSEPHINE ', state: 'OR' },
  '41035': { county: 'KLAMATH ', state: 'OR' },
  '41037': { county: 'LAKE ', state: 'OR' },
  '41039': { county: 'LANE ', state: 'OR' },
  '41041': { county: 'LINCOLN ', state: 'OR' },
  '41043': { county: 'LINN ', state: 'OR' },
  '41045': { county: 'MALHEUR ', state: 'OR' },
  '41047': { county: 'MARION ', state: 'OR' },
  '41049': { county: 'MORROW ', state: 'OR' },
  '41051': { county: 'MULTNOMAH ', state: 'OR' },
  '41053': { county: 'POLK ', state: 'OR' },
  '41055': { county: 'SHERMAN ', state: 'OR' },
  '41057': { county: 'TILLAMOOK ', state: 'OR' },
  '41059': { county: 'UMATILLA ', state: 'OR' },
  '41061': { county: 'UNION ', state: 'OR' },
  '41063': { county: 'WALLOWA ', state: 'OR' },
  '41065': { county: 'WASCO ', state: 'OR' },
  '41067': { county: 'WASHINGTON ', state: 'OR' },
  '41069': { county: 'WHEELER ', state: 'OR' },
  '41071': { county: 'YAMHILL ', state: 'OR' },
  '42000': { county: 'PENNSYLVANIA', state: 'PA' },
  '42001': { county: 'ADAMS ', state: 'PA' },
  '42003': { county: 'ALLEGHENY ', state: 'PA' },
  '42005': { county: 'ARMSTRONG ', state: 'PA' },
  '42007': { county: 'BEAVER ', state: 'PA' },
  '42009': { county: 'BEDFORD ', state: 'PA' },
  '42011': { county: 'BERKS ', state: 'PA' },
  '42013': { county: 'BLAIR ', state: 'PA' },
  '42015': { county: 'BRADFORD ', state: 'PA' },
  '42017': { county: 'BUCKS ', state: 'PA' },
  '42019': { county: 'BUTLER ', state: 'PA' },
  '42021': { county: 'CAMBRIA ', state: 'PA' },
  '42023': { county: 'CAMERON ', state: 'PA' },
  '42025': { county: 'CARBON ', state: 'PA' },
  '42027': { county: 'CENTRE ', state: 'PA' },
  '42029': { county: 'CHESTER ', state: 'PA' },
  '42031': { county: 'CLARION ', state: 'PA' },
  '42033': { county: 'CLEARFIELD ', state: 'PA' },
  '42035': { county: 'CLINTON ', state: 'PA' },
  '42037': { county: 'COLUMBIA ', state: 'PA' },
  '42039': { county: 'CRAWFORD ', state: 'PA' },
  '42041': { county: 'CUMBERLAND ', state: 'PA' },
  '42043': { county: 'DAUPHIN ', state: 'PA' },
  '42045': { county: 'DELAWARE ', state: 'PA' },
  '42047': { county: 'ELK ', state: 'PA' },
  '42049': { county: 'ERIE ', state: 'PA' },
  '42051': { county: 'FAYETTE ', state: 'PA' },
  '42053': { county: 'FOREST ', state: 'PA' },
  '42055': { county: 'FRANKLIN ', state: 'PA' },
  '42057': { county: 'FULTON ', state: 'PA' },
  '42059': { county: 'GREENE ', state: 'PA' },
  '42061': { county: 'HUNTINGDON ', state: 'PA' },
  '42063': { county: 'INDIANA ', state: 'PA' },
  '42065': { county: 'JEFFERSON ', state: 'PA' },
  '42067': { county: 'JUNIATA ', state: 'PA' },
  '42069': { county: 'LACKAWANNA ', state: 'PA' },
  '42071': { county: 'LANCASTER ', state: 'PA' },
  '42073': { county: 'LAWRENCE ', state: 'PA' },
  '42075': { county: 'LEBANON ', state: 'PA' },
  '42077': { county: 'LEHIGH ', state: 'PA' },
  '42079': { county: 'LUZERNE ', state: 'PA' },
  '42081': { county: 'LYCOMING ', state: 'PA' },
  '42083': { county: 'MCKEAN ', state: 'PA' },
  '42085': { county: 'MERCER ', state: 'PA' },
  '42087': { county: 'MIFFLIN ', state: 'PA' },
  '42089': { county: 'MONROE ', state: 'PA' },
  '42091': { county: 'MONTGOMERY ', state: 'PA' },
  '42093': { county: 'MONTOUR ', state: 'PA' },
  '42095': { county: 'NORTHAMPTON ', state: 'PA' },
  '42097': { county: 'NORTHUMBERLAND ', state: 'PA' },
  '42099': { county: 'PERRY ', state: 'PA' },
  '42101': { county: 'PHILADELPHIA ', state: 'PA' },
  '42103': { county: 'PIKE ', state: 'PA' },
  '42105': { county: 'POTTER ', state: 'PA' },
  '42107': { county: 'SCHUYLKILL ', state: 'PA' },
  '42109': { county: 'SNYDER ', state: 'PA' },
  '42111': { county: 'SOMERSET ', state: 'PA' },
  '42113': { county: 'SULLIVAN ', state: 'PA' },
  '42115': { county: 'SUSQUEHANNA ', state: 'PA' },
  '42117': { county: 'TIOGA ', state: 'PA' },
  '42119': { county: 'UNION ', state: 'PA' },
  '42121': { county: 'VENANGO ', state: 'PA' },
  '42123': { county: 'WARREN ', state: 'PA' },
  '42125': { county: 'WASHINGTON ', state: 'PA' },
  '42127': { county: 'WAYNE ', state: 'PA' },
  '42129': { county: 'WESTMORELAND ', state: 'PA' },
  '42131': { county: 'WYOMING ', state: 'PA' },
  '42133': { county: 'YORK ', state: 'PA' },
  '44000': { county: 'RHODE ISLAND', state: 'RI' },
  '44001': { county: 'BRISTOL ', state: 'RI' },
  '44003': { county: 'KENT ', state: 'RI' },
  '44005': { county: 'NEWPORT ', state: 'RI' },
  '44007': { county: 'PROVIDENCE ', state: 'RI' },
  '44009': { county: 'WASHINGTON ', state: 'RI' },
  '45000': { county: 'SOUTH CAROLINA', state: 'SC' },
  '45001': { county: 'ABBEVILLE ', state: 'SC' },
  '45003': { county: 'AIKEN ', state: 'SC' },
  '45005': { county: 'ALLENDALE ', state: 'SC' },
  '45007': { county: 'ANDERSON ', state: 'SC' },
  '45009': { county: 'BAMBERG ', state: 'SC' },
  '45011': { county: 'BARNWELL ', state: 'SC' },
  '45013': { county: 'BEAUFORT ', state: 'SC' },
  '45015': { county: 'BERKELEY ', state: 'SC' },
  '45017': { county: 'CALHOUN ', state: 'SC' },
  '45019': { county: 'CHARLESTON ', state: 'SC' },
  '45021': { county: 'CHEROKEE ', state: 'SC' },
  '45023': { county: 'CHESTER ', state: 'SC' },
  '45025': { county: 'CHESTERFIELD ', state: 'SC' },
  '45027': { county: 'CLARENDON ', state: 'SC' },
  '45029': { county: 'COLLETON ', state: 'SC' },
  '45031': { county: 'DARLINGTON ', state: 'SC' },
  '45033': { county: 'DILLON ', state: 'SC' },
  '45035': { county: 'DORCHESTER ', state: 'SC' },
  '45037': { county: 'EDGEFIELD ', state: 'SC' },
  '45039': { county: 'FAIRFIELD ', state: 'SC' },
  '45041': { county: 'FLORENCE ', state: 'SC' },
  '45043': { county: 'GEORGETOWN ', state: 'SC' },
  '45045': { county: 'GREENVILLE ', state: 'SC' },
  '45047': { county: 'GREENWOOD ', state: 'SC' },
  '45049': { county: 'HAMPTON ', state: 'SC' },
  '45051': { county: 'HORRY ', state: 'SC' },
  '45053': { county: 'JASPER ', state: 'SC' },
  '45055': { county: 'KERSHAW ', state: 'SC' },
  '45057': { county: 'LANCASTER ', state: 'SC' },
  '45059': { county: 'LAURENS ', state: 'SC' },
  '45061': { county: 'LEE ', state: 'SC' },
  '45063': { county: 'LEXINGTON ', state: 'SC' },
  '45065': { county: 'MCCORMICK ', state: 'SC' },
  '45067': { county: 'MARION ', state: 'SC' },
  '45069': { county: 'MARLBORO ', state: 'SC' },
  '45071': { county: 'NEWBERRY ', state: 'SC' },
  '45073': { county: 'OCONEE ', state: 'SC' },
  '45075': { county: 'ORANGEBURG ', state: 'SC' },
  '45077': { county: 'PICKENS ', state: 'SC' },
  '45079': { county: 'RICHLAND ', state: 'SC' },
  '45081': { county: 'SALUDA ', state: 'SC' },
  '45083': { county: 'SPARTANBURG ', state: 'SC' },
  '45085': { county: 'SUMTER ', state: 'SC' },
  '45087': { county: 'UNION ', state: 'SC' },
  '45089': { county: 'WILLIAMSBURG ', state: 'SC' },
  '45091': { county: 'YORK ', state: 'SC' },
  '46000': { county: 'SOUTH DAKOTA', state: 'SD' },
  '46003': { county: 'AURORA ', state: 'SD' },
  '46005': { county: 'BEADLE ', state: 'SD' },
  '46007': { county: 'BENNETT ', state: 'SD' },
  '46009': { county: 'BON HOMME ', state: 'SD' },
  '46011': { county: 'BROOKINGS ', state: 'SD' },
  '46013': { county: 'BROWN ', state: 'SD' },
  '46015': { county: 'BRULE ', state: 'SD' },
  '46017': { county: 'BUFFALO ', state: 'SD' },
  '46019': { county: 'BUTTE ', state: 'SD' },
  '46021': { county: 'CAMPBELL ', state: 'SD' },
  '46023': { county: 'CHARLES MIX ', state: 'SD' },
  '46025': { county: 'CLARK ', state: 'SD' },
  '46027': { county: 'CLAY ', state: 'SD' },
  '46029': { county: 'CODINGTON ', state: 'SD' },
  '46031': { county: 'CORSON ', state: 'SD' },
  '46033': { county: 'CUSTER ', state: 'SD' },
  '46035': { county: 'DAVISON ', state: 'SD' },
  '46037': { county: 'DAY ', state: 'SD' },
  '46039': { county: 'DEUEL ', state: 'SD' },
  '46041': { county: 'DEWEY ', state: 'SD' },
  '46043': { county: 'DOUGLAS ', state: 'SD' },
  '46045': { county: 'EDMUNDS ', state: 'SD' },
  '46047': { county: 'FALL RIVER ', state: 'SD' },
  '46049': { county: 'FAULK ', state: 'SD' },
  '46051': { county: 'GRANT ', state: 'SD' },
  '46053': { county: 'GREGORY ', state: 'SD' },
  '46055': { county: 'HAAKON ', state: 'SD' },
  '46057': { county: 'HAMLIN ', state: 'SD' },
  '46059': { county: 'HAND ', state: 'SD' },
  '46061': { county: 'HANSON ', state: 'SD' },
  '46063': { county: 'HARDING ', state: 'SD' },
  '46065': { county: 'HUGHES ', state: 'SD' },
  '46067': { county: 'HUTCHINSON ', state: 'SD' },
  '46069': { county: 'HYDE ', state: 'SD' },
  '46071': { county: 'JACKSON ', state: 'SD' },
  '46073': { county: 'JERAULD ', state: 'SD' },
  '46075': { county: 'JONES ', state: 'SD' },
  '46077': { county: 'KINGSBURY ', state: 'SD' },
  '46079': { county: 'LAKE ', state: 'SD' },
  '46081': { county: 'LAWRENCE ', state: 'SD' },
  '46083': { county: 'LINCOLN ', state: 'SD' },
  '46085': { county: 'LYMAN ', state: 'SD' },
  '46087': { county: 'MCCOOK ', state: 'SD' },
  '46089': { county: 'MCPHERSON ', state: 'SD' },
  '46091': { county: 'MARSHALL ', state: 'SD' },
  '46093': { county: 'MEADE ', state: 'SD' },
  '46095': { county: 'MELLETTE ', state: 'SD' },
  '46097': { county: 'MINER ', state: 'SD' },
  '46099': { county: 'MINNEHAHA ', state: 'SD' },
  '46101': { county: 'MOODY ', state: 'SD' },
  '46102': { county: 'OGLALA LAKOTA ', state: 'SD' },
  '46103': { county: 'PENNINGTON ', state: 'SD' },
  '46105': { county: 'PERKINS ', state: 'SD' },
  '46107': { county: 'POTTER ', state: 'SD' },
  '46109': { county: 'ROBERTS ', state: 'SD' },
  '46111': { county: 'SANBORN ', state: 'SD' },
  '46113': { county: 'SHANNON ', state: 'SD' },
  '46115': { county: 'SPINK ', state: 'SD' },
  '46117': { county: 'STANLEY ', state: 'SD' },
  '46119': { county: 'SULLY ', state: 'SD' },
  '46121': { county: 'TODD ', state: 'SD' },
  '46123': { county: 'TRIPP ', state: 'SD' },
  '46125': { county: 'TURNER ', state: 'SD' },
  '46127': { county: 'UNION ', state: 'SD' },
  '46129': { county: 'WALWORTH ', state: 'SD' },
  '46135': { county: 'YANKTON ', state: 'SD' },
  '46137': { county: 'ZIEBACH ', state: 'SD' },
  '47000': { county: 'TENNESSEE', state: 'TN' },
  '47001': { county: 'ANDERSON ', state: 'TN' },
  '47003': { county: 'BEDFORD ', state: 'TN' },
  '47005': { county: 'BENTON ', state: 'TN' },
  '47007': { county: 'BLEDSOE ', state: 'TN' },
  '47009': { county: 'BLOUNT ', state: 'TN' },
  '47011': { county: 'BRADLEY ', state: 'TN' },
  '47013': { county: 'CAMPBELL ', state: 'TN' },
  '47015': { county: 'CANNON ', state: 'TN' },
  '47017': { county: 'CARROLL ', state: 'TN' },
  '47019': { county: 'CARTER ', state: 'TN' },
  '47021': { county: 'CHEATHAM ', state: 'TN' },
  '47023': { county: 'CHESTER ', state: 'TN' },
  '47025': { county: 'CLAIBORNE ', state: 'TN' },
  '47027': { county: 'CLAY ', state: 'TN' },
  '47029': { county: 'COCKE ', state: 'TN' },
  '47031': { county: 'COFFEE ', state: 'TN' },
  '47033': { county: 'CROCKETT ', state: 'TN' },
  '47035': { county: 'CUMBERLAND ', state: 'TN' },
  '47037': { county: 'DAVIDSON ', state: 'TN' },
  '47039': { county: 'DECATUR ', state: 'TN' },
  '47041': { county: 'DEKALB ', state: 'TN' },
  '47043': { county: 'DICKSON ', state: 'TN' },
  '47045': { county: 'DYER ', state: 'TN' },
  '47047': { county: 'FAYETTE ', state: 'TN' },
  '47049': { county: 'FENTRESS ', state: 'TN' },
  '47051': { county: 'FRANKLIN ', state: 'TN' },
  '47053': { county: 'GIBSON ', state: 'TN' },
  '47055': { county: 'GILES ', state: 'TN' },
  '47057': { county: 'GRAINGER ', state: 'TN' },
  '47059': { county: 'GREENE ', state: 'TN' },
  '47061': { county: 'GRUNDY ', state: 'TN' },
  '47063': { county: 'HAMBLEN ', state: 'TN' },
  '47065': { county: 'HAMILTON ', state: 'TN' },
  '47067': { county: 'HANCOCK ', state: 'TN' },
  '47069': { county: 'HARDEMAN ', state: 'TN' },
  '47071': { county: 'HARDIN ', state: 'TN' },
  '47073': { county: 'HAWKINS ', state: 'TN' },
  '47075': { county: 'HAYWOOD ', state: 'TN' },
  '47077': { county: 'HENDERSON ', state: 'TN' },
  '47079': { county: 'HENRY ', state: 'TN' },
  '47081': { county: 'HICKMAN ', state: 'TN' },
  '47083': { county: 'HOUSTON ', state: 'TN' },
  '47085': { county: 'HUMPHREYS ', state: 'TN' },
  '47087': { county: 'JACKSON ', state: 'TN' },
  '47089': { county: 'JEFFERSON ', state: 'TN' },
  '47091': { county: 'JOHNSON ', state: 'TN' },
  '47093': { county: 'KNOX ', state: 'TN' },
  '47095': { county: 'LAKE ', state: 'TN' },
  '47097': { county: 'LAUDERDALE ', state: 'TN' },
  '47099': { county: 'LAWRENCE ', state: 'TN' },
  '47101': { county: 'LEWIS ', state: 'TN' },
  '47103': { county: 'LINCOLN ', state: 'TN' },
  '47105': { county: 'LOUDON ', state: 'TN' },
  '47107': { county: 'MCMINN ', state: 'TN' },
  '47109': { county: 'MCNAIRY ', state: 'TN' },
  '47111': { county: 'MACON ', state: 'TN' },
  '47113': { county: 'MADISON ', state: 'TN' },
  '47115': { county: 'MARION ', state: 'TN' },
  '47117': { county: 'MARSHALL ', state: 'TN' },
  '47119': { county: 'MAURY ', state: 'TN' },
  '47121': { county: 'MEIGS ', state: 'TN' },
  '47123': { county: 'MONROE ', state: 'TN' },
  '47125': { county: 'MONTGOMERY ', state: 'TN' },
  '47127': { county: 'MOORE ', state: 'TN' },
  '47129': { county: 'MORGAN ', state: 'TN' },
  '47131': { county: 'OBION ', state: 'TN' },
  '47133': { county: 'OVERTON ', state: 'TN' },
  '47135': { county: 'PERRY ', state: 'TN' },
  '47137': { county: 'PICKETT ', state: 'TN' },
  '47139': { county: 'POLK ', state: 'TN' },
  '47141': { county: 'PUTNAM ', state: 'TN' },
  '47143': { county: 'RHEA ', state: 'TN' },
  '47145': { county: 'ROANE ', state: 'TN' },
  '47147': { county: 'ROBERTSON ', state: 'TN' },
  '47149': { county: 'RUTHERFORD ', state: 'TN' },
  '47151': { county: 'SCOTT ', state: 'TN' },
  '47153': { county: 'SEQUATCHIE ', state: 'TN' },
  '47155': { county: 'SEVIER ', state: 'TN' },
  '47157': { county: 'SHELBY ', state: 'TN' },
  '47159': { county: 'SMITH ', state: 'TN' },
  '47161': { county: 'STEWART ', state: 'TN' },
  '47163': { county: 'SULLIVAN ', state: 'TN' },
  '47165': { county: 'SUMNER ', state: 'TN' },
  '47167': { county: 'TIPTON ', state: 'TN' },
  '47169': { county: 'TROUSDALE ', state: 'TN' },
  '47171': { county: 'UNICOI ', state: 'TN' },
  '47173': { county: 'UNION ', state: 'TN' },
  '47175': { county: 'VAN BUREN ', state: 'TN' },
  '47177': { county: 'WARREN ', state: 'TN' },
  '47179': { county: 'WASHINGTON ', state: 'TN' },
  '47181': { county: 'WAYNE ', state: 'TN' },
  '47183': { county: 'WEAKLEY ', state: 'TN' },
  '47185': { county: 'WHITE ', state: 'TN' },
  '47187': { county: 'WILLIAMSON ', state: 'TN' },
  '47189': { county: 'WILSON ', state: 'TN' },
  '48000': { county: 'TEXAS', state: 'TX' },
  '48001': { county: 'ANDERSON ', state: 'TX' },
  '48003': { county: 'ANDREWS ', state: 'TX' },
  '48005': { county: 'ANGELINA ', state: 'TX' },
  '48007': { county: 'ARANSAS ', state: 'TX' },
  '48009': { county: 'ARCHER ', state: 'TX' },
  '48011': { county: 'ARMSTRONG ', state: 'TX' },
  '48013': { county: 'ATASCOSA ', state: 'TX' },
  '48015': { county: 'AUSTIN ', state: 'TX' },
  '48017': { county: 'BAILEY ', state: 'TX' },
  '48019': { county: 'BANDERA ', state: 'TX' },
  '48021': { county: 'BASTROP ', state: 'TX' },
  '48023': { county: 'BAYLOR ', state: 'TX' },
  '48025': { county: 'BEE ', state: 'TX' },
  '48027': { county: 'BELL ', state: 'TX' },
  '48029': { county: 'BEXAR ', state: 'TX' },
  '48031': { county: 'BLANCO ', state: 'TX' },
  '48033': { county: 'BORDEN ', state: 'TX' },
  '48035': { county: 'BOSQUE ', state: 'TX' },
  '48037': { county: 'BOWIE ', state: 'TX' },
  '48039': { county: 'BRAZORIA ', state: 'TX' },
  '48041': { county: 'BRAZOS ', state: 'TX' },
  '48043': { county: 'BREWSTER ', state: 'TX' },
  '48045': { county: 'BRISCOE ', state: 'TX' },
  '48047': { county: 'BROOKS ', state: 'TX' },
  '48049': { county: 'BROWN ', state: 'TX' },
  '48051': { county: 'BURLESON ', state: 'TX' },
  '48053': { county: 'BURNET ', state: 'TX' },
  '48055': { county: 'CALDWELL ', state: 'TX' },
  '48057': { county: 'CALHOUN ', state: 'TX' },
  '48059': { county: 'CALLAHAN ', state: 'TX' },
  '48061': { county: 'CAMERON ', state: 'TX' },
  '48063': { county: 'CAMP ', state: 'TX' },
  '48065': { county: 'CARSON ', state: 'TX' },
  '48067': { county: 'CASS ', state: 'TX' },
  '48069': { county: 'CASTRO ', state: 'TX' },
  '48071': { county: 'CHAMBERS ', state: 'TX' },
  '48073': { county: 'CHEROKEE ', state: 'TX' },
  '48075': { county: 'CHILDRESS ', state: 'TX' },
  '48077': { county: 'CLAY ', state: 'TX' },
  '48079': { county: 'COCHRAN ', state: 'TX' },
  '48081': { county: 'COKE ', state: 'TX' },
  '48083': { county: 'COLEMAN ', state: 'TX' },
  '48085': { county: 'COLLIN ', state: 'TX' },
  '48087': { county: 'COLLINGSWORTH ', state: 'TX' },
  '48089': { county: 'COLORADO ', state: 'TX' },
  '48091': { county: 'COMAL ', state: 'TX' },
  '48093': { county: 'COMANCHE ', state: 'TX' },
  '48095': { county: 'CONCHO ', state: 'TX' },
  '48097': { county: 'COOKE ', state: 'TX' },
  '48099': { county: 'CORYELL ', state: 'TX' },
  '48101': { county: 'COTTLE ', state: 'TX' },
  '48103': { county: 'CRANE ', state: 'TX' },
  '48105': { county: 'CROCKETT ', state: 'TX' },
  '48107': { county: 'CROSBY ', state: 'TX' },
  '48109': { county: 'CULBERSON ', state: 'TX' },
  '48111': { county: 'DALLAM ', state: 'TX' },
  '48113': { county: 'DALLAS ', state: 'TX' },
  '48115': { county: 'DAWSON ', state: 'TX' },
  '48117': { county: 'DEAF SMITH ', state: 'TX' },
  '48119': { county: 'DELTA ', state: 'TX' },
  '48121': { county: 'DENTON ', state: 'TX' },
  '48123': { county: 'DEWITT ', state: 'TX' },
  '48125': { county: 'DICKENS ', state: 'TX' },
  '48127': { county: 'DIMMIT ', state: 'TX' },
  '48129': { county: 'DONLEY ', state: 'TX' },
  '48131': { county: 'DUVAL ', state: 'TX' },
  '48133': { county: 'EASTLAND ', state: 'TX' },
  '48135': { county: 'ECTOR ', state: 'TX' },
  '48137': { county: 'EDWARDS ', state: 'TX' },
  '48139': { county: 'ELLIS ', state: 'TX' },
  '48141': { county: 'EL PASO ', state: 'TX' },
  '48143': { county: 'ERATH ', state: 'TX' },
  '48145': { county: 'FALLS ', state: 'TX' },
  '48147': { county: 'FANNIN ', state: 'TX' },
  '48149': { county: 'FAYETTE ', state: 'TX' },
  '48151': { county: 'FISHER ', state: 'TX' },
  '48153': { county: 'FLOYD ', state: 'TX' },
  '48155': { county: 'FOARD ', state: 'TX' },
  '48157': { county: 'FORT BEND ', state: 'TX' },
  '48159': { county: 'FRANKLIN ', state: 'TX' },
  '48161': { county: 'FREESTONE ', state: 'TX' },
  '48163': { county: 'FRIO ', state: 'TX' },
  '48165': { county: 'GAINES ', state: 'TX' },
  '48167': { county: 'GALVESTON ', state: 'TX' },
  '48169': { county: 'GARZA ', state: 'TX' },
  '48171': { county: 'GILLESPIE ', state: 'TX' },
  '48173': { county: 'GLASSCOCK ', state: 'TX' },
  '48175': { county: 'GOLIAD ', state: 'TX' },
  '48177': { county: 'GONZALES ', state: 'TX' },
  '48179': { county: 'GRAY ', state: 'TX' },
  '48181': { county: 'GRAYSON ', state: 'TX' },
  '48183': { county: 'GREGG ', state: 'TX' },
  '48185': { county: 'GRIMES ', state: 'TX' },
  '48187': { county: 'GUADALUPE ', state: 'TX' },
  '48189': { county: 'HALE ', state: 'TX' },
  '48191': { county: 'HALL ', state: 'TX' },
  '48193': { county: 'HAMILTON ', state: 'TX' },
  '48195': { county: 'HANSFORD ', state: 'TX' },
  '48197': { county: 'HARDEMAN ', state: 'TX' },
  '48199': { county: 'HARDIN ', state: 'TX' },
  '48201': { county: 'HARRIS ', state: 'TX' },
  '48203': { county: 'HARRISON ', state: 'TX' },
  '48205': { county: 'HARTLEY ', state: 'TX' },
  '48207': { county: 'HASKELL ', state: 'TX' },
  '48209': { county: 'HAYS ', state: 'TX' },
  '48211': { county: 'HEMPHILL ', state: 'TX' },
  '48213': { county: 'HENDERSON ', state: 'TX' },
  '48215': { county: 'HIDALGO ', state: 'TX' },
  '48217': { county: 'HILL ', state: 'TX' },
  '48219': { county: 'HOCKLEY ', state: 'TX' },
  '48221': { county: 'HOOD ', state: 'TX' },
  '48223': { county: 'HOPKINS ', state: 'TX' },
  '48225': { county: 'HOUSTON ', state: 'TX' },
  '48227': { county: 'HOWARD ', state: 'TX' },
  '48229': { county: 'HUDSPETH ', state: 'TX' },
  '48231': { county: 'HUNT ', state: 'TX' },
  '48233': { county: 'HUTCHINSON ', state: 'TX' },
  '48235': { county: 'IRION ', state: 'TX' },
  '48237': { county: 'JACK ', state: 'TX' },
  '48239': { county: 'JACKSON ', state: 'TX' },
  '48241': { county: 'JASPER ', state: 'TX' },
  '48243': { county: 'JEFF DAVIS ', state: 'TX' },
  '48245': { county: 'JEFFERSON ', state: 'TX' },
  '48247': { county: 'JIM HOGG ', state: 'TX' },
  '48249': { county: 'JIM WELLS ', state: 'TX' },
  '48251': { county: 'JOHNSON ', state: 'TX' },
  '48253': { county: 'JONES ', state: 'TX' },
  '48255': { county: 'KARNES ', state: 'TX' },
  '48257': { county: 'KAUFMAN ', state: 'TX' },
  '48259': { county: 'KENDALL ', state: 'TX' },
  '48261': { county: 'KENEDY ', state: 'TX' },
  '48263': { county: 'KENT ', state: 'TX' },
  '48265': { county: 'KERR ', state: 'TX' },
  '48267': { county: 'KIMBLE ', state: 'TX' },
  '48269': { county: 'KING ', state: 'TX' },
  '48271': { county: 'KINNEY ', state: 'TX' },
  '48273': { county: 'KLEBERG ', state: 'TX' },
  '48275': { county: 'KNOX ', state: 'TX' },
  '48277': { county: 'LAMAR ', state: 'TX' },
  '48279': { county: 'LAMB ', state: 'TX' },
  '48281': { county: 'LAMPASAS ', state: 'TX' },
  '48283': { county: 'LA SALLE ', state: 'TX' },
  '48285': { county: 'LAVACA ', state: 'TX' },
  '48287': { county: 'LEE ', state: 'TX' },
  '48289': { county: 'LEON ', state: 'TX' },
  '48291': { county: 'LIBERTY ', state: 'TX' },
  '48293': { county: 'LIMESTONE ', state: 'TX' },
  '48295': { county: 'LIPSCOMB ', state: 'TX' },
  '48297': { county: 'LIVE OAK ', state: 'TX' },
  '48299': { county: 'LLANO ', state: 'TX' },
  '48301': { county: 'LOVING ', state: 'TX' },
  '48303': { county: 'LUBBOCK ', state: 'TX' },
  '48305': { county: 'LYNN ', state: 'TX' },
  '48307': { county: 'MCCULLOCH ', state: 'TX' },
  '48309': { county: 'MCLENNAN ', state: 'TX' },
  '48311': { county: 'MCMULLEN ', state: 'TX' },
  '48313': { county: 'MADISON ', state: 'TX' },
  '48315': { county: 'MARION ', state: 'TX' },
  '48317': { county: 'MARTIN ', state: 'TX' },
  '48319': { county: 'MASON ', state: 'TX' },
  '48321': { county: 'MATAGORDA ', state: 'TX' },
  '48323': { county: 'MAVERICK ', state: 'TX' },
  '48325': { county: 'MEDINA ', state: 'TX' },
  '48327': { county: 'MENARD ', state: 'TX' },
  '48329': { county: 'MIDLAND ', state: 'TX' },
  '48331': { county: 'MILAM ', state: 'TX' },
  '48333': { county: 'MILLS ', state: 'TX' },
  '48335': { county: 'MITCHELL ', state: 'TX' },
  '48337': { county: 'MONTAGUE ', state: 'TX' },
  '48339': { county: 'MONTGOMERY ', state: 'TX' },
  '48341': { county: 'MOORE ', state: 'TX' },
  '48343': { county: 'MORRIS ', state: 'TX' },
  '48345': { county: 'MOTLEY ', state: 'TX' },
  '48347': { county: 'NACOGDOCHES ', state: 'TX' },
  '48349': { county: 'NAVARRO ', state: 'TX' },
  '48351': { county: 'NEWTON ', state: 'TX' },
  '48353': { county: 'NOLAN ', state: 'TX' },
  '48355': { county: 'NUECES ', state: 'TX' },
  '48357': { county: 'OCHILTREE ', state: 'TX' },
  '48359': { county: 'OLDHAM ', state: 'TX' },
  '48361': { county: 'ORANGE ', state: 'TX' },
  '48363': { county: 'PALO PINTO ', state: 'TX' },
  '48365': { county: 'PANOLA ', state: 'TX' },
  '48367': { county: 'PARKER ', state: 'TX' },
  '48369': { county: 'PARMER ', state: 'TX' },
  '48371': { county: 'PECOS ', state: 'TX' },
  '48373': { county: 'POLK ', state: 'TX' },
  '48375': { county: 'POTTER ', state: 'TX' },
  '48377': { county: 'PRESIDIO ', state: 'TX' },
  '48379': { county: 'RAINS ', state: 'TX' },
  '48381': { county: 'RANDALL ', state: 'TX' },
  '48383': { county: 'REAGAN ', state: 'TX' },
  '48385': { county: 'REAL ', state: 'TX' },
  '48387': { county: 'RED RIVER ', state: 'TX' },
  '48389': { county: 'REEVES ', state: 'TX' },
  '48391': { county: 'REFUGIO ', state: 'TX' },
  '48393': { county: 'ROBERTS ', state: 'TX' },
  '48395': { county: 'ROBERTSON ', state: 'TX' },
  '48397': { county: 'ROCKWALL ', state: 'TX' },
  '48399': { county: 'RUNNELS ', state: 'TX' },
  '48401': { county: 'RUSK ', state: 'TX' },
  '48403': { county: 'SABINE ', state: 'TX' },
  '48405': { county: 'SAN AUGUSTINE ', state: 'TX' },
  '48407': { county: 'SAN JACINTO ', state: 'TX' },
  '48409': { county: 'SAN PATRICIO ', state: 'TX' },
  '48411': { county: 'SAN SABA ', state: 'TX' },
  '48413': { county: 'SCHLEICHER ', state: 'TX' },
  '48415': { county: 'SCURRY ', state: 'TX' },
  '48417': { county: 'SHACKELFORD ', state: 'TX' },
  '48419': { county: 'SHELBY ', state: 'TX' },
  '48421': { county: 'SHERMAN ', state: 'TX' },
  '48423': { county: 'SMITH ', state: 'TX' },
  '48425': { county: 'SOMERVELL ', state: 'TX' },
  '48427': { county: 'STARR ', state: 'TX' },
  '48429': { county: 'STEPHENS ', state: 'TX' },
  '48431': { county: 'STERLING ', state: 'TX' },
  '48433': { county: 'STONEWALL ', state: 'TX' },
  '48435': { county: 'SUTTON ', state: 'TX' },
  '48437': { county: 'SWISHER ', state: 'TX' },
  '48439': { county: 'TARRANT ', state: 'TX' },
  '48441': { county: 'TAYLOR ', state: 'TX' },
  '48443': { county: 'TERRELL ', state: 'TX' },
  '48445': { county: 'TERRY ', state: 'TX' },
  '48447': { county: 'THROCKMORTON ', state: 'TX' },
  '48449': { county: 'TITUS ', state: 'TX' },
  '48451': { county: 'TOM GREEN ', state: 'TX' },
  '48453': { county: 'TRAVIS ', state: 'TX' },
  '48455': { county: 'TRINITY ', state: 'TX' },
  '48457': { county: 'TYLER ', state: 'TX' },
  '48459': { county: 'UPSHUR ', state: 'TX' },
  '48461': { county: 'UPTON ', state: 'TX' },
  '48463': { county: 'UVALDE ', state: 'TX' },
  '48465': { county: 'VAL VERDE ', state: 'TX' },
  '48467': { county: 'VAN ZANDT ', state: 'TX' },
  '48469': { county: 'VICTORIA ', state: 'TX' },
  '48471': { county: 'WALKER ', state: 'TX' },
  '48473': { county: 'WALLER ', state: 'TX' },
  '48475': { county: 'WARD ', state: 'TX' },
  '48477': { county: 'WASHINGTON ', state: 'TX' },
  '48479': { county: 'WEBB ', state: 'TX' },
  '48481': { county: 'WHARTON ', state: 'TX' },
  '48483': { county: 'WHEELER ', state: 'TX' },
  '48485': { county: 'WICHITA ', state: 'TX' },
  '48487': { county: 'WILBARGER ', state: 'TX' },
  '48489': { county: 'WILLACY ', state: 'TX' },
  '48491': { county: 'WILLIAMSON ', state: 'TX' },
  '48493': { county: 'WILSON ', state: 'TX' },
  '48495': { county: 'WINKLER ', state: 'TX' },
  '48497': { county: 'WISE ', state: 'TX' },
  '48499': { county: 'WOOD ', state: 'TX' },
  '48501': { county: 'YOAKUM ', state: 'TX' },
  '48503': { county: 'YOUNG ', state: 'TX' },
  '48505': { county: 'ZAPATA ', state: 'TX' },
  '48507': { county: 'ZAVALA ', state: 'TX' },
  '49000': { county: 'UTAH', state: 'UT' },
  '49001': { county: 'BEAVER ', state: 'UT' },
  '49003': { county: 'BOX ELDER ', state: 'UT' },
  '49005': { county: 'CACHE ', state: 'UT' },
  '49007': { county: 'CARBON ', state: 'UT' },
  '49009': { county: 'DAGGETT ', state: 'UT' },
  '49011': { county: 'DAVIS ', state: 'UT' },
  '49013': { county: 'DUCHESNE ', state: 'UT' },
  '49015': { county: 'EMERY ', state: 'UT' },
  '49017': { county: 'GARFIELD ', state: 'UT' },
  '49019': { county: 'GRAND ', state: 'UT' },
  '49021': { county: 'IRON ', state: 'UT' },
  '49023': { county: 'JUAB ', state: 'UT' },
  '49025': { county: 'KANE ', state: 'UT' },
  '49027': { county: 'MILLARD ', state: 'UT' },
  '49029': { county: 'MORGAN ', state: 'UT' },
  '49031': { county: 'PIUTE ', state: 'UT' },
  '49033': { county: 'RICH ', state: 'UT' },
  '49035': { county: 'SALT LAKE ', state: 'UT' },
  '49037': { county: 'SAN JUAN ', state: 'UT' },
  '49039': { county: 'SANPETE ', state: 'UT' },
  '49041': { county: 'SEVIER ', state: 'UT' },
  '49043': { county: 'SUMMIT ', state: 'UT' },
  '49045': { county: 'TOOELE ', state: 'UT' },
  '49047': { county: 'UINTAH ', state: 'UT' },
  '49049': { county: 'UTAH ', state: 'UT' },
  '49051': { county: 'WASATCH ', state: 'UT' },
  '49053': { county: 'WASHINGTON ', state: 'UT' },
  '49055': { county: 'WAYNE ', state: 'UT' },
  '49057': { county: 'WEBER ', state: 'UT' },
  '50000': { county: 'VERMONT', state: 'VT' },
  '50001': { county: 'ADDISON ', state: 'VT' },
  '50003': { county: 'BENNINGTON ', state: 'VT' },
  '50005': { county: 'CALEDONIA ', state: 'VT' },
  '50007': { county: 'CHITTENDEN ', state: 'VT' },
  '50009': { county: 'ESSEX ', state: 'VT' },
  '50011': { county: 'FRANKLIN ', state: 'VT' },
  '50013': { county: 'GRAND ISLE ', state: 'VT' },
  '50015': { county: 'LAMOILLE ', state: 'VT' },
  '50017': { county: 'ORANGE ', state: 'VT' },
  '50019': { county: 'ORLEANS ', state: 'VT' },
  '50021': { county: 'RUTLAND ', state: 'VT' },
  '50023': { county: 'WASHINGTON ', state: 'VT' },
  '50025': { county: 'WINDHAM ', state: 'VT' },
  '50027': { county: 'WINDSOR ', state: 'VT' },
  '51000': { county: 'VIRGINIA', state: 'VA' },
  '51001': { county: 'ACCOMACK ', state: 'VA' },
  '51003': { county: 'ALBEMARLE ', state: 'VA' },
  '51005': { county: 'ALLEGHANY ', state: 'VA' },
  '51007': { county: 'AMELIA ', state: 'VA' },
  '51009': { county: 'AMHERST ', state: 'VA' },
  '51011': { county: 'APPOMATTOX ', state: 'VA' },
  '51013': { county: 'ARLINGTON ', state: 'VA' },
  '51015': { county: 'AUGUSTA ', state: 'VA' },
  '51017': { county: 'BATH ', state: 'VA' },
  '51019': { county: 'BEDFORD ', state: 'VA' },
  '51021': { county: 'BLAND ', state: 'VA' },
  '51023': { county: 'BOTETOURT ', state: 'VA' },
  '51025': { county: 'BRUNSWICK ', state: 'VA' },
  '51027': { county: 'BUCHANAN ', state: 'VA' },
  '51029': { county: 'BUCKINGHAM ', state: 'VA' },
  '51031': { county: 'CAMPBELL ', state: 'VA' },
  '51033': { county: 'CAROLINE ', state: 'VA' },
  '51035': { county: 'CARROLL ', state: 'VA' },
  '51036': { county: 'CHARLES CITY ', state: 'VA' },
  '51037': { county: 'CHARLOTTE ', state: 'VA' },
  '51041': { county: 'CHESTERFIELD ', state: 'VA' },
  '51043': { county: 'CLARKE ', state: 'VA' },
  '51045': { county: 'CRAIG ', state: 'VA' },
  '51047': { county: 'CULPEPER ', state: 'VA' },
  '51049': { county: 'CUMBERLAND ', state: 'VA' },
  '51051': { county: 'DICKENSON ', state: 'VA' },
  '51053': { county: 'DINWIDDIE ', state: 'VA' },
  '51057': { county: 'ESSEX ', state: 'VA' },
  '51059': { county: 'FAIRFAX ', state: 'VA' },
  '51061': { county: 'FAUQUIER ', state: 'VA' },
  '51063': { county: 'FLOYD ', state: 'VA' },
  '51065': { county: 'FLUVANNA ', state: 'VA' },
  '51067': { county: 'FRANKLIN ', state: 'VA' },
  '51069': { county: 'FREDERICK ', state: 'VA' },
  '51071': { county: 'GILES ', state: 'VA' },
  '51073': { county: 'GLOUCESTER ', state: 'VA' },
  '51075': { county: 'GOOCHLAND ', state: 'VA' },
  '51077': { county: 'GRAYSON ', state: 'VA' },
  '51079': { county: 'GREENE ', state: 'VA' },
  '51081': { county: 'GREENSVILLE ', state: 'VA' },
  '51083': { county: 'HALIFAX ', state: 'VA' },
  '51085': { county: 'HANOVER ', state: 'VA' },
  '51087': { county: 'HENRICO ', state: 'VA' },
  '51089': { county: 'HENRY ', state: 'VA' },
  '51091': { county: 'HIGHLAND ', state: 'VA' },
  '51093': { county: 'ISLE OF WIGHT ', state: 'VA' },
  '51095': { county: 'JAMES CITY ', state: 'VA' },
  '51097': { county: 'KING AND QUEEN ', state: 'VA' },
  '51099': { county: 'KING GEORGE ', state: 'VA' },
  '51101': { county: 'KING WILLIAM ', state: 'VA' },
  '51103': { county: 'LANCASTER ', state: 'VA' },
  '51105': { county: 'LEE ', state: 'VA' },
  '51107': { county: 'LOUDOUN ', state: 'VA' },
  '51109': { county: 'LOUISA ', state: 'VA' },
  '51111': { county: 'LUNENBURG ', state: 'VA' },
  '51113': { county: 'MADISON ', state: 'VA' },
  '51115': { county: 'MATHEWS ', state: 'VA' },
  '51117': { county: 'MECKLENBURG ', state: 'VA' },
  '51119': { county: 'MIDDLESEX ', state: 'VA' },
  '51121': { county: 'MONTGOMERY ', state: 'VA' },
  '51125': { county: 'NELSON ', state: 'VA' },
  '51127': { county: 'NEW KENT ', state: 'VA' },
  '51131': { county: 'NORTHAMPTON ', state: 'VA' },
  '51133': { county: 'NORTHUMBERLAND ', state: 'VA' },
  '51135': { county: 'NOTTOWAY ', state: 'VA' },
  '51137': { county: 'ORANGE ', state: 'VA' },
  '51139': { county: 'PAGE ', state: 'VA' },
  '51141': { county: 'PATRICK ', state: 'VA' },
  '51143': { county: 'PITTSYLVANIA ', state: 'VA' },
  '51145': { county: 'POWHATAN ', state: 'VA' },
  '51147': { county: 'PRINCE EDWARD ', state: 'VA' },
  '51149': { county: 'PRINCE GEORGE ', state: 'VA' },
  '51153': { county: 'PRINCE WILLIAM ', state: 'VA' },
  '51155': { county: 'PULASKI ', state: 'VA' },
  '51157': { county: 'RAPPAHANNOCK ', state: 'VA' },
  '51159': { county: 'RICHMOND ', state: 'VA' },
  '51161': { county: 'ROANOKE ', state: 'VA' },
  '51163': { county: 'ROCKBRIDGE ', state: 'VA' },
  '51165': { county: 'ROCKINGHAM ', state: 'VA' },
  '51167': { county: 'RUSSELL ', state: 'VA' },
  '51169': { county: 'SCOTT ', state: 'VA' },
  '51171': { county: 'SHENANDOAH ', state: 'VA' },
  '51173': { county: 'SMYTH ', state: 'VA' },
  '51175': { county: 'SOUTHAMPTON ', state: 'VA' },
  '51177': { county: 'SPOTSYLVANIA ', state: 'VA' },
  '51179': { county: 'STAFFORD ', state: 'VA' },
  '51181': { county: 'SURRY ', state: 'VA' },
  '51183': { county: 'SUSSEX ', state: 'VA' },
  '51185': { county: 'TAZEWELL ', state: 'VA' },
  '51187': { county: 'WARREN ', state: 'VA' },
  '51191': { county: 'WASHINGTON ', state: 'VA' },
  '51193': { county: 'WESTMORELAND ', state: 'VA' },
  '51195': { county: 'WISE ', state: 'VA' },
  '51197': { county: 'WYTHE ', state: 'VA' },
  '51199': { county: 'YORK ', state: 'VA' },
  '51510': { county: 'ALEXANDRIA CITY', state: 'VA' },
  '51515': { county: 'BEDFORD CITY', state: 'VA' },
  '51520': { county: 'BRISTOL CITY', state: 'VA' },
  '51530': { county: 'BUENA VISTA CITY', state: 'VA' },
  '51540': { county: 'CHARLOTTESVILLE CITY', state: 'VA' },
  '51550': { county: 'CHESAPEAKE CITY', state: 'VA' },
  '51570': { county: 'COLONIAL HEIGHTS CITY', state: 'VA' },
  '51580': { county: 'COVINGTON CITY', state: 'VA' },
  '51590': { county: 'DANVILLE CITY', state: 'VA' },
  '51595': { county: 'EMPORIA CITY', state: 'VA' },
  '51600': { county: 'FAIRFAX CITY', state: 'VA' },
  '51610': { county: 'FALLS CHURCH CITY', state: 'VA' },
  '51620': { county: 'FRANKLIN CITY', state: 'VA' },
  '51630': { county: 'FREDERICKSBURG CITY', state: 'VA' },
  '51640': { county: 'GALAX CITY', state: 'VA' },
  '51650': { county: 'HAMPTON CITY', state: 'VA' },
  '51660': { county: 'HARRISONBURG CITY', state: 'VA' },
  '51670': { county: 'HOPEWELL CITY', state: 'VA' },
  '51678': { county: 'LEXINGTON CITY', state: 'VA' },
  '51680': { county: 'LYNCHBURG CITY', state: 'VA' },
  '51683': { county: 'MANASSAS CITY', state: 'VA' },
  '51685': { county: 'MANASSAS PARK CITY', state: 'VA' },
  '51690': { county: 'MARTINSVILLE CITY', state: 'VA' },
  '51700': { county: 'NEWPORT NEWS CITY', state: 'VA' },
  '51710': { county: 'NORFOLK CITY', state: 'VA' },
  '51720': { county: 'NORTON CITY', state: 'VA' },
  '51730': { county: 'PETERSBURG CITY', state: 'VA' },
  '51735': { county: 'POQUOSON CITY', state: 'VA' },
  '51740': { county: 'PORTSMOUTH CITY', state: 'VA' },
  '51750': { county: 'RADFORD CITY', state: 'VA' },
  '51760': { county: 'RICHMOND CITY', state: 'VA' },
  '51770': { county: 'ROANOKE CITY', state: 'VA' },
  '51775': { county: 'SALEM CITY', state: 'VA' },
  '51790': { county: 'STAUNTON CITY', state: 'VA' },
  '51800': { county: 'SUFFOLK CITY', state: 'VA' },
  '51810': { county: 'VIRGINIA BEACH CITY', state: 'VA' },
  '51820': { county: 'WAYNESBORO CITY', state: 'VA' },
  '51830': { county: 'WILLIAMSBURG CITY', state: 'VA' },
  '51840': { county: 'WINCHESTER CITY', state: 'VA' },
  '53000': { county: 'WASHINGTON', state: 'WA' },
  '53001': { county: 'ADAMS ', state: 'WA' },
  '53003': { county: 'ASOTIN ', state: 'WA' },
  '53005': { county: 'BENTON ', state: 'WA' },
  '53007': { county: 'CHELAN ', state: 'WA' },
  '53009': { county: 'CLALLAM ', state: 'WA' },
  '53011': { county: 'CLARK ', state: 'WA' },
  '53013': { county: 'COLUMBIA ', state: 'WA' },
  '53015': { county: 'COWLITZ ', state: 'WA' },
  '53017': { county: 'DOUGLAS ', state: 'WA' },
  '53019': { county: 'FERRY ', state: 'WA' },
  '53021': { county: 'FRANKLIN ', state: 'WA' },
  '53023': { county: 'GARFIELD ', state: 'WA' },
  '53025': { county: 'GRANT ', state: 'WA' },
  '53027': { county: 'GRAYS HARBOR ', state: 'WA' },
  '53029': { county: 'ISLAND ', state: 'WA' },
  '53031': { county: 'JEFFERSON ', state: 'WA' },
  '53033': { county: 'KING ', state: 'WA' },
  '53035': { county: 'KITSAP ', state: 'WA' },
  '53037': { county: 'KITTITAS ', state: 'WA' },
  '53039': { county: 'KLICKITAT ', state: 'WA' },
  '53041': { county: 'LEWIS ', state: 'WA' },
  '53043': { county: 'LINCOLN ', state: 'WA' },
  '53045': { county: 'MASON ', state: 'WA' },
  '53047': { county: 'OKANOGAN ', state: 'WA' },
  '53049': { county: 'PACIFIC ', state: 'WA' },
  '53051': { county: 'PEND OREILLE ', state: 'WA' },
  '53053': { county: 'PIERCE ', state: 'WA' },
  '53055': { county: 'SAN JUAN ', state: 'WA' },
  '53057': { county: 'SKAGIT ', state: 'WA' },
  '53059': { county: 'SKAMANIA ', state: 'WA' },
  '53061': { county: 'SNOHOMISH ', state: 'WA' },
  '53063': { county: 'SPOKANE ', state: 'WA' },
  '53065': { county: 'STEVENS ', state: 'WA' },
  '53067': { county: 'THURSTON ', state: 'WA' },
  '53069': { county: 'WAHKIAKUM ', state: 'WA' },
  '53071': { county: 'WALLA WALLA ', state: 'WA' },
  '53073': { county: 'WHATCOM ', state: 'WA' },
  '53075': { county: 'WHITMAN ', state: 'WA' },
  '53077': { county: 'YAKIMA ', state: 'WA' },
  '54000': { county: 'WEST VIRGINIA', state: 'WV' },
  '54001': { county: 'BARBOUR ', state: 'WV' },
  '54003': { county: 'BERKELEY ', state: 'WV' },
  '54005': { county: 'BOONE ', state: 'WV' },
  '54007': { county: 'BRAXTON ', state: 'WV' },
  '54009': { county: 'BROOKE ', state: 'WV' },
  '54011': { county: 'CABELL ', state: 'WV' },
  '54013': { county: 'CALHOUN ', state: 'WV' },
  '54015': { county: 'CLAY ', state: 'WV' },
  '54017': { county: 'DODDRIDGE ', state: 'WV' },
  '54019': { county: 'FAYETTE ', state: 'WV' },
  '54021': { county: 'GILMER ', state: 'WV' },
  '54023': { county: 'GRANT ', state: 'WV' },
  '54025': { county: 'GREENBRIER ', state: 'WV' },
  '54027': { county: 'HAMPSHIRE ', state: 'WV' },
  '54029': { county: 'HANCOCK ', state: 'WV' },
  '54031': { county: 'HARDY ', state: 'WV' },
  '54033': { county: 'HARRISON ', state: 'WV' },
  '54035': { county: 'JACKSON ', state: 'WV' },
  '54037': { county: 'JEFFERSON ', state: 'WV' },
  '54039': { county: 'KANAWHA ', state: 'WV' },
  '54041': { county: 'LEWIS ', state: 'WV' },
  '54043': { county: 'LINCOLN ', state: 'WV' },
  '54045': { county: 'LOGAN ', state: 'WV' },
  '54047': { county: 'MCDOWELL ', state: 'WV' },
  '54049': { county: 'MARION ', state: 'WV' },
  '54051': { county: 'MARSHALL ', state: 'WV' },
  '54053': { county: 'MASON ', state: 'WV' },
  '54055': { county: 'MERCER ', state: 'WV' },
  '54057': { county: 'MINERAL ', state: 'WV' },
  '54059': { county: 'MINGO ', state: 'WV' },
  '54061': { county: 'MONONGALIA ', state: 'WV' },
  '54063': { county: 'MONROE ', state: 'WV' },
  '54065': { county: 'MORGAN ', state: 'WV' },
  '54067': { county: 'NICHOLAS ', state: 'WV' },
  '54069': { county: 'OHIO ', state: 'WV' },
  '54071': { county: 'PENDLETON ', state: 'WV' },
  '54073': { county: 'PLEASANTS ', state: 'WV' },
  '54075': { county: 'POCAHONTAS ', state: 'WV' },
  '54077': { county: 'PRESTON ', state: 'WV' },
  '54079': { county: 'PUTNAM ', state: 'WV' },
  '54081': { county: 'RALEIGH ', state: 'WV' },
  '54083': { county: 'RANDOLPH ', state: 'WV' },
  '54085': { county: 'RITCHIE ', state: 'WV' },
  '54087': { county: 'ROANE ', state: 'WV' },
  '54089': { county: 'SUMMERS ', state: 'WV' },
  '54091': { county: 'TAYLOR ', state: 'WV' },
  '54093': { county: 'TUCKER ', state: 'WV' },
  '54095': { county: 'TYLER ', state: 'WV' },
  '54097': { county: 'UPSHUR ', state: 'WV' },
  '54099': { county: 'WAYNE ', state: 'WV' },
  '54101': { county: 'WEBSTER ', state: 'WV' },
  '54103': { county: 'WETZEL ', state: 'WV' },
  '54105': { county: 'WIRT ', state: 'WV' },
  '54107': { county: 'WOOD ', state: 'WV' },
  '54109': { county: 'WYOMING ', state: 'WV' },
  '55000': { county: 'WISCONSIN', state: 'WI' },
  '55001': { county: 'ADAMS ', state: 'WI' },
  '55003': { county: 'ASHLAND ', state: 'WI' },
  '55005': { county: 'BARRON ', state: 'WI' },
  '55007': { county: 'BAYFIELD ', state: 'WI' },
  '55009': { county: 'BROWN ', state: 'WI' },
  '55011': { county: 'BUFFALO ', state: 'WI' },
  '55013': { county: 'BURNETT ', state: 'WI' },
  '55015': { county: 'CALUMET ', state: 'WI' },
  '55017': { county: 'CHIPPEWA ', state: 'WI' },
  '55019': { county: 'CLARK ', state: 'WI' },
  '55021': { county: 'COLUMBIA ', state: 'WI' },
  '55023': { county: 'CRAWFORD ', state: 'WI' },
  '55025': { county: 'DANE ', state: 'WI' },
  '55027': { county: 'DODGE ', state: 'WI' },
  '55029': { county: 'DOOR ', state: 'WI' },
  '55031': { county: 'DOUGLAS ', state: 'WI' },
  '55033': { county: 'DUNN ', state: 'WI' },
  '55035': { county: 'EAU CLAIRE ', state: 'WI' },
  '55037': { county: 'FLORENCE ', state: 'WI' },
  '55039': { county: 'FOND DU LAC ', state: 'WI' },
  '55041': { county: 'FOREST ', state: 'WI' },
  '55043': { county: 'GRANT ', state: 'WI' },
  '55045': { county: 'GREEN ', state: 'WI' },
  '55047': { county: 'GREEN LAKE ', state: 'WI' },
  '55049': { county: 'IOWA ', state: 'WI' },
  '55051': { county: 'IRON ', state: 'WI' },
  '55053': { county: 'JACKSON ', state: 'WI' },
  '55055': { county: 'JEFFERSON ', state: 'WI' },
  '55057': { county: 'JUNEAU ', state: 'WI' },
  '55059': { county: 'KENOSHA ', state: 'WI' },
  '55061': { county: 'KEWAUNEE ', state: 'WI' },
  '55063': { county: 'LA CROSSE ', state: 'WI' },
  '55065': { county: 'LAFAYETTE ', state: 'WI' },
  '55067': { county: 'LANGLADE ', state: 'WI' },
  '55069': { county: 'LINCOLN ', state: 'WI' },
  '55071': { county: 'MANITOWOC ', state: 'WI' },
  '55073': { county: 'MARATHON ', state: 'WI' },
  '55075': { county: 'MARINETTE ', state: 'WI' },
  '55077': { county: 'MARQUETTE ', state: 'WI' },
  '55078': { county: 'MENOMINEE ', state: 'WI' },
  '55079': { county: 'MILWAUKEE ', state: 'WI' },
  '55081': { county: 'MONROE ', state: 'WI' },
  '55083': { county: 'OCONTO ', state: 'WI' },
  '55085': { county: 'ONEIDA ', state: 'WI' },
  '55087': { county: 'OUTAGAMIE ', state: 'WI' },
  '55089': { county: 'OZAUKEE ', state: 'WI' },
  '55091': { county: 'PEPIN ', state: 'WI' },
  '55093': { county: 'PIERCE ', state: 'WI' },
  '55095': { county: 'POLK ', state: 'WI' },
  '55097': { county: 'PORTAGE ', state: 'WI' },
  '55099': { county: 'PRICE ', state: 'WI' },
  '55101': { county: 'RACINE ', state: 'WI' },
  '55103': { county: 'RICHLAND ', state: 'WI' },
  '55105': { county: 'ROCK ', state: 'WI' },
  '55107': { county: 'RUSK ', state: 'WI' },
  '55109': { county: 'ST. CROIX ', state: 'WI' },
  '55111': { county: 'SAUK ', state: 'WI' },
  '55113': { county: 'SAWYER ', state: 'WI' },
  '55115': { county: 'SHAWANO ', state: 'WI' },
  '55117': { county: 'SHEBOYGAN ', state: 'WI' },
  '55119': { county: 'TAYLOR ', state: 'WI' },
  '55121': { county: 'TREMPEALEAU ', state: 'WI' },
  '55123': { county: 'VERNON ', state: 'WI' },
  '55125': { county: 'VILAS ', state: 'WI' },
  '55127': { county: 'WALWORTH ', state: 'WI' },
  '55129': { county: 'WASHBURN ', state: 'WI' },
  '55131': { county: 'WASHINGTON ', state: 'WI' },
  '55133': { county: 'WAUKESHA ', state: 'WI' },
  '55135': { county: 'WAUPACA ', state: 'WI' },
  '55137': { county: 'WAUSHARA ', state: 'WI' },
  '55139': { county: 'WINNEBAGO ', state: 'WI' },
  '55141': { county: 'WOOD ', state: 'WI' },
  '56000': { county: 'WYOMING', state: 'WY' },
  '56001': { county: 'ALBANY ', state: 'WY' },
  '56003': { county: 'BIG HORN ', state: 'WY' },
  '56005': { county: 'CAMPBELL ', state: 'WY' },
  '56007': { county: 'CARBON ', state: 'WY' },
  '56009': { county: 'CONVERSE ', state: 'WY' },
  '56011': { county: 'CROOK ', state: 'WY' },
  '56013': { county: 'FREMONT ', state: 'WY' },
  '56015': { county: 'GOSHEN ', state: 'WY' },
  '56017': { county: 'HOT SPRINGS ', state: 'WY' },
  '56019': { county: 'JOHNSON ', state: 'WY' },
  '56021': { county: 'LARAMIE ', state: 'WY' },
  '56023': { county: 'LINCOLN ', state: 'WY' },
  '56025': { county: 'NATRONA ', state: 'WY' },
  '56027': { county: 'NIOBRARA ', state: 'WY' },
  '56029': { county: 'PARK ', state: 'WY' },
  '56031': { county: 'PLATTE ', state: 'WY' },
  '56033': { county: 'SHERIDAN ', state: 'WY' },
  '56035': { county: 'SUBLETTE ', state: 'WY' },
  '56037': { county: 'SWEETWATER ', state: 'WY' },
  '56039': { county: 'TETON ', state: 'WY' },
  '56041': { county: 'UINTA ', state: 'WY' },
  '56043': { county: 'WASHAKIE ', state: 'WY' },
  '56045': { county: 'WESTON ', state: 'WY' },
  '72000': { county: 'PUERTO RICO', state: 'PR' },
  '72001': { county: 'ADJUNTAS MUNICIPIO', state: 'PR' },
  '72003': { county: 'AGUADA MUNICIPIO', state: 'PR' },
  '72005': { county: 'AGUADILLA MUNICIPIO', state: 'PR' },
  '72007': { county: 'AGUAS BUENAS MUNICIPIO', state: 'PR' },
  '72009': { county: 'AIBONITO MUNICIPIO', state: 'PR' },
  '72011': { county: 'AÑASCO MUNICIPIO', state: 'PR' },
  '72013': { county: 'ARECIBO MUNICIPIO', state: 'PR' },
  '72015': { county: 'ARROYO MUNICIPIO', state: 'PR' },
  '72017': { county: 'BARCELONETA MUNICIPIO', state: 'PR' },
  '72019': { county: 'BARRANQUITAS MUNICIPIO', state: 'PR' },
  '72021': { county: 'BAYAMÓN MUNICIPIO', state: 'PR' },
  '72023': { county: 'CABO ROJO MUNICIPIO', state: 'PR' },
  '72025': { county: 'CAGUAS MUNICIPIO', state: 'PR' },
  '72027': { county: 'CAMUY MUNICIPIO', state: 'PR' },
  '72029': { county: 'CANÓVANAS MUNICIPIO', state: 'PR' },
  '72031': { county: 'CAROLINA MUNICIPIO', state: 'PR' },
  '72033': { county: 'CATAÑO MUNICIPIO', state: 'PR' },
  '72035': { county: 'CAYEY MUNICIPIO', state: 'PR' },
  '72037': { county: 'CEIBA MUNICIPIO', state: 'PR' },
  '72039': { county: 'CIALES MUNICIPIO', state: 'PR' },
  '72041': { county: 'CIDRA MUNICIPIO', state: 'PR' },
  '72043': { county: 'COAMO MUNICIPIO', state: 'PR' },
  '72045': { county: 'COMERÍO MUNICIPIO', state: 'PR' },
  '72047': { county: 'COROZAL MUNICIPIO', state: 'PR' },
  '72049': { county: 'CULEBRA MUNICIPIO', state: 'PR' },
  '72051': { county: 'DORADO MUNICIPIO', state: 'PR' },
  '72053': { county: 'FAJARDO MUNICIPIO', state: 'PR' },
  '72054': { county: 'FLORIDA MUNICIPIO', state: 'PR' },
  '72055': { county: 'GUÁNICA MUNICIPIO', state: 'PR' },
  '72057': { county: 'GUAYAMA MUNICIPIO', state: 'PR' },
  '72059': { county: 'GUAYANILLA MUNICIPIO', state: 'PR' },
  '72061': { county: 'GUAYNABO MUNICIPIO', state: 'PR' },
  '72063': { county: 'GURABO MUNICIPIO', state: 'PR' },
  '72065': { county: 'HATILLO MUNICIPIO', state: 'PR' },
  '72067': { county: 'HORMIGUEROS MUNICIPIO', state: 'PR' },
  '72069': { county: 'HUMACAO MUNICIPIO', state: 'PR' },
  '72071': { county: 'ISABELA MUNICIPIO', state: 'PR' },
  '72073': { county: 'JAYUYA MUNICIPIO', state: 'PR' },
  '72075': { county: 'JUANA DÍAZ MUNICIPIO', state: 'PR' },
  '72077': { county: 'JUNCOS MUNICIPIO', state: 'PR' },
  '72079': { county: 'LAJAS MUNICIPIO', state: 'PR' },
  '72081': { county: 'LARES MUNICIPIO', state: 'PR' },
  '72083': { county: 'LAS MARÍAS MUNICIPIO', state: 'PR' },
  '72085': { county: 'LAS PIEDRAS MUNICIPIO', state: 'PR' },
  '72087': { county: 'LOÍZA MUNICIPIO', state: 'PR' },
  '72089': { county: 'LUQUILLO MUNICIPIO', state: 'PR' },
  '72091': { county: 'MANATÍ MUNICIPIO', state: 'PR' },
  '72093': { county: 'MARICAO MUNICIPIO', state: 'PR' },
  '72095': { county: 'MAUNABO MUNICIPIO', state: 'PR' },
  '72097': { county: 'MAYAGÜEZ MUNICIPIO', state: 'PR' },
  '72099': { county: 'MOCA MUNICIPIO', state: 'PR' },
  '72101': { county: 'MOROVIS MUNICIPIO', state: 'PR' },
  '72103': { county: 'NAGUABO MUNICIPIO', state: 'PR' },
  '72105': { county: 'NARANJITO MUNICIPIO', state: 'PR' },
  '72107': { county: 'OROCOVIS MUNICIPIO', state: 'PR' },
  '72109': { county: 'PATILLAS MUNICIPIO', state: 'PR' },
  '72111': { county: 'PEÑUELAS MUNICIPIO', state: 'PR' },
  '72113': { county: 'PONCE MUNICIPIO', state: 'PR' },
  '72115': { county: 'QUEBRADILLAS MUNICIPIO', state: 'PR' },
  '72117': { county: 'RINCÓN MUNICIPIO', state: 'PR' },
  '72119': { county: 'RÍO GRANDE MUNICIPIO', state: 'PR' },
  '72121': { county: 'SABANA GRANDE MUNICIPIO', state: 'PR' },
  '72123': { county: 'SALINAS MUNICIPIO', state: 'PR' },
  '72125': { county: 'SAN GERMÁN MUNICIPIO', state: 'PR' },
  '72127': { county: 'SAN JUAN MUNICIPIO', state: 'PR' },
  '72129': { county: 'SAN LORENZO MUNICIPIO', state: 'PR' },
  '72131': { county: 'SAN SEBASTIÁN MUNICIPIO', state: 'PR' },
  '72133': { county: 'SANTA ISABEL MUNICIPIO', state: 'PR' },
  '72135': { county: 'TOA ALTA MUNICIPIO', state: 'PR' },
  '72137': { county: 'TOA BAJA MUNICIPIO', state: 'PR' },
  '72139': { county: 'TRUJILLO ALTO MUNICIPIO', state: 'PR' },
  '72141': { county: 'UTUADO MUNICIPIO', state: 'PR' },
  '72143': { county: 'VEGA ALTA MUNICIPIO', state: 'PR' },
  '72145': { county: 'VEGA BAJA MUNICIPIO', state: 'PR' },
  '72147': { county: 'VIEQUES MUNICIPIO', state: 'PR' },
  '72149': { county: 'VILLALBA MUNICIPIO', state: 'PR' },
  '72151': { county: 'YABUCOA MUNICIPIO', state: 'PR' },
  '72153': { county: 'YAUCO MUNICIPIO', state: 'PR' },
  '99999': { county: 'UNKNOWN', state: 'NA' },
};
