export const TIMEOUT = 10000;

export const HEADERS_DELETE = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

export const HEADERS_PATCH = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

export const HEADERS_POST = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Request-Headers': 'access-control-allow-origin'
};

export const HEADERS_PUT = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

export const HEADERS_GET = {
  Accept: 'application/json'
};
