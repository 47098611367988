import { SnackbarProvider } from 'notistack';
import React, { StrictMode } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from '../../routes';
import { AuthProvider } from '../../utils/auth';

const App = () => {
  return (
    <StrictMode>
      <SnackbarProvider maxSnack={7}>
        <Router>
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </Router>
      </SnackbarProvider>
    </StrictMode>
  );
}

export default App;
