import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const Item = ({ id, isSelected, name, address, onClick }) => {
  return (
    <div class="min_li">
    <ListItem button selected={isSelected} key={id} onClick={onClick}>
      <ListItemText primary={name} secondary={address} />
    </ListItem>
    </div>
  );
}

export default Item;
