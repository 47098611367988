import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import CompanyDeleteDialog from "./CompanyDeleteDialog";

const CompanyDeleteButton = ({ onDelete, recordId, name }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (isDeleted = false) => {
    if (isDeleted) {
      onDelete();
    }
    setOpen(false);
  };

  return (
    <div>
      <div class="btn_box delt mgmt-company">
        <Button color="secondary" onClick={handleClickOpen} variant="contained">
          Delete The Management Company
        </Button>
        <CompanyDeleteDialog recordId={recordId} name={name} open={open} onClose={handleClose} />
      </div>
    </div>
  );
}

export default CompanyDeleteButton;
