import { Button, Icon } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from 'react';
import styled from "styled-components";
import { hoa } from "../../api";
import LoadingOverlay from "../Loading/LoadingOverlay";

const { subdivisionDisconnect } = hoa;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ActionButtons = ({ hoaId, onDisconnect, subdivisionId }) => {
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleOnDisconnect = async () => {
    setLoading(true);

    try {
      await subdivisionDisconnect(hoaId, subdivisionId);
      onDisconnect();
    } catch (e) {
      console.error(e);
      const message = e.response.data.message;
      enqueueSnackbar(`Error while deleting the Subdivision connection: ${ message ?? 'Unknown error' }`, { variant: 'error' });
    }

    setLoading(false);
  }

  return <Wrapper>
    {loading && <LoadingOverlay />}
    <Button color="primary" onClick={handleOnDisconnect}>
      <Icon>link_off</Icon>
    </Button>
  </Wrapper>
}

export default ActionButtons;
