import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { mgmtCompany } from "../../../api/endpoints";
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";
import { CONTACT_TYPES } from "../../../constants";
import Contacts from "../../Contacts";
import { useSnackbar } from "notistack";

const { get: mgmtCompanyGet } = mgmtCompany;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledContacts = styled(Contacts)`
  height: 100%;
`;

const TypeWrapper = styled.div`
  flex: 1;
  position: relative;
`;

const ContactsTab = ({
  mgmtCompanyRecordId,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [mgmtCompanyPrimaryContactId, setMgmtCompanyPrimaryContactId] = useState();

  useEffect(() => {
    if (!mgmtCompanyRecordId) {
      setMgmtCompanyPrimaryContactId(null);
      return;
    }

    setLoading(true);

    const request = mgmtCompanyGet(mgmtCompanyRecordId);

    (async () => {
      try {
        const { primaryContactId } = await request;
        setMgmtCompanyPrimaryContactId(primaryContactId);
        setLoading(false);
      }
      catch (e) {
        enqueueSnackbar('Could not load primary contact for this management company', { variant: 'warning' })
      }
    })();

    return () => { request.cancel(); setLoading(false) };
  }, []);

  return <Wrapper>
    <TypeWrapper>
      {loading && <LoadingOverlay />}
      <StyledContacts
        onChangePrimary={setMgmtCompanyPrimaryContactId}
        primaryContactId={mgmtCompanyPrimaryContactId}
        recordId={mgmtCompanyRecordId}
        type={CONTACT_TYPES.mgmtCompany}
      />
    </TypeWrapper>
  </Wrapper>
}

export default ContactsTab;
