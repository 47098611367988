import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { hoa } from "../../../api/endpoints";
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";
import Subdivisions from "../../../components/Subdivisions";
import SubdivisionSearch from "../../SubdivisionSearch";
import { useSnackbar } from "notistack";

const { subdivisionsGet } = hoa;

const ListWrapper = styled.div`
  flex: 1;
  position: relative;
`;

const SearchWrapper = ListWrapper;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SubdivisionInfo = ({ hoaRecordId, hoaState }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setItems([]);

    if (!hoaRecordId) {
      return;
    }

    setLoading(true);

    const request = subdivisionsGet(hoaRecordId);
    (async () => {
      try {
        const response = await request;
        setItems(response.items);
        setLoading(false);
      }
      catch (e) {
        console.log(`Error : ${e}`);
        setLoading(false)
      }
    })();
    return () => { request.cancel(); setLoading(false) };

  }, [hoaRecordId]);

  const onAddHandler = (subdivision) => {
    setItems([...items, subdivision]);
  }

  const onDisconnect = (subdivisionId) => {
    setItems(items.filter(({ recordId }) => recordId !== subdivisionId));
  }

  return <Wrapper>
    <ListWrapper>
      {loading && <LoadingOverlay />}
      <Subdivisions items={items} ofHoa={hoaRecordId} onDisconnect={onDisconnect} />
    </ListWrapper>
    <SearchWrapper>
      <SubdivisionSearch
        defaultState={hoaState}
        forHoa={hoaRecordId}
        onAdd={onAddHandler}
        subdivisionsAttached={items}
      />
    </SearchWrapper>
  </Wrapper>;
}

export default SubdivisionInfo;
