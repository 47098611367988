import { useSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { hoa } from "../../api";
import HoaList from "../../components/HoaList";
import LoadingOverlay from "../../components/Loading/LoadingOverlay";
import { ROUTES } from "../../constants";
import Content from "./Content";
import Filter from "./Filter";
import Unauthorized from '../../routes/Unauthorized';
import { useAuth } from '../../utils/auth'
import CreateHoa from './Tabs/CreateHoa'
//latest add
import { Button } from "@material-ui/core";
import { Pagination } from "@mui/material";

const { getHoas } = hoa;

const { HOA_MASTER } = ROUTES;

const Wrapper = styled.div`
  display: flex !important;
  height: 100% !important;
  background-color: #F0F7FF !important;
  padding: 22px 0px !important;
`;
const LeftBar = styled.div`
  flex-basis: 30% !important;
  width:30%;
  display: flex !important;
  flex-direction: column !important;
  // overflow: hidden !important;
  position: relative !important;
`;
const RightBar = styled.div`
  flex-basis: 70% !important;
  overflow: auto;
  width:70%;
  background-color: rgb(235, 243, 252) !important;
`;

const StyledHoaList = styled(HoaList)`
  overflow-y: auto !important;
`;

const HoaMaster = ({ location, match }) => {
  const { userData } = useAuth();
  const [hasChanges, setHasChanges] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [selectedItemId, setSelectedItemId] = useState();
  const [totalResults, setTotalResults] = useState();
  const [showCreateHoa, setShowCreateHoa] = useState(true);
  const [disableCreateHoaButton, setDisableCreateHoaButton] = useState(true)
  const [subdivisionMapping, setSubdivisionMapping] = useState(false)
  const [leftSidebar, setLeftSidebar] = useState(true)
  const [standaloneCreate, setStandaloneCreate] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    setInitialized(true);
  }, [])

  useEffect(() => {
    if (!initialized) {
      return;
    }
    setLoading(true);

    const request = getHoas({ filter });
    (async () => {
      try {
        const hoas = await request;
        setPages(hoas?.meta?.totalItems / 20)
        setTotalResults(hoas?.meta?.totalItems);
        setItems(hoas.items);
        setLoading(false);
      }
      catch (e) {
        enqueueSnackbar(`Could not load HOAs. Error : ${e}`, { variant: 'warning' });
        setLoading(false)
      }
    })();
    return () => { request.cancel(); setLoading(false); }

  }, [filter]);

  useEffect(() => {
    if (items.length == 1 && selectedItemId == null) {
      setSelectedItemId(items[0].recordId)
      onSelect(items[0].recordId);
    }

    if (!selectedItemId) {
      return;
    }
    if (!items.find(({ recordId }) => recordId === selectedItemId)) {
      enqueueSnackbar(`Cannot find the HOA ${selectedItemId} amongst the currently filtered records.`, { variant: 'warning' });
    }
  }, [items]);

  useEffect(() => {
    setSelectedItemId(match.params.recordId ? Number(match.params.recordId) : null);
  }, [match.params.recordId])

  const onChangeFilter = (filter) => {
    setSelectedItemId(null) //bug
    if (!showCreateHoa) {
      setShowCreateHoa(true)
    }
    if (!hasChanges) {
      setFilter(filter);
      setPage(1);
      setDisableCreateHoaButton(false)
      setStandaloneCreate(false)
    } else {
      enqueueSnackbar('You need to save or cancel the changes first.', { variant: 'error' });
    }
  }
  const onChangeMode = (mappingMode) => {
    setSubdivisionMapping(mappingMode)
  }
  const onSelect = (id) => {
    if (!hasChanges) {
      console.log(subdivisionMapping)
      if (!subdivisionMapping) {
        history.push(`${HOA_MASTER}/${id}${location.search}${location.hash}`);
      } else {
        history.push(`${HOA_MASTER}/${id}${location.search}#subdivision-info`);
      }
    } else {
      enqueueSnackbar('You need to save or cancel the changes first.', { variant: 'error' });
    }
  }

  const onDelete = () => {
    if (!subdivisionMapping) {
      history.push(`${HOA_MASTER}${location.search}${location.hash}`);
    } else {
      history.push(`${HOA_MASTER}${location.search}#subdivision-info`);
    }
    setItems(items.filter((item) => item.recordId !== selectedItemId));
  }

  // @todo Use redux for it here instead.
  const onUpdate = (updatedData) => {
    setItems(items.map((item) => {
      if (item.recordId === selectedItemId) {
        return updatedData;
      }
      return item;
    }));
  }

  const handleCreateHoa = () => {
    if (showCreateHoa) {
      setShowCreateHoa(false)
    } else {
      setShowCreateHoa(true)
    }
    if (selectedItemId == null) {
      setStandaloneCreate(!standaloneCreate)
    }
  }
  const changeOffset = (event, value) => {
    setFilter({ ...filter, offset: (value - 1) * 20 })
    setPage(value);
    if (!subdivisionMapping) {
      history.push(`${HOA_MASTER}${location.search}${location.hash}`);
    } else {
      history.push(`${HOA_MASTER}${location.search}#subdivision-info`);
    }
  }
  const handleViewSidebar = () => {
    setLeftSidebar(!leftSidebar);
  };
  useEffect(() => {
    setShowCreateHoa(showCreateHoa)
  }, [showCreateHoa])


  // console.log('items',items)
  if (!userData.group) {
    return <Unauthorized />
  }
  return (
    <Wrapper>
      <LeftBar className={leftSidebar ? 'sidebar open' : 'sidebar'}>
        {loading && <LoadingOverlay />}
        <Filter hasChanges={hasChanges} onSubmit={onChangeFilter} totalItems={totalResults} onChangeMode={onChangeMode} subMapping={subdivisionMapping} />
        <div class="cre_btn">
          <Button disabled={disableCreateHoaButton || hasChanges} variant="contained" color="primary" onClick={handleCreateHoa} >{showCreateHoa ? 'Create new HOA' : 'back'}</Button>
        </div>
        <div class="sec_filt">
          {showCreateHoa ? <StyledHoaList
            items={items.map(i => ({ recordId: i.recordId, name: i.name, address: i.addressFull }))}
            selectedItemId={selectedItemId}
            onSelect={onSelect}
          /> : null}
        </div>
        <Pagination style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 10 }} color='standard' onChange={changeOffset} count={Math.ceil(pages)} page={page} />
        <button onClick={handleViewSidebar} className={leftSidebar? "sidebar-toggle open": "sidebar-toggle"}>
          <i className={leftSidebar ? "arrow left" : 'arrow right'}></i>
        </button>
      </LeftBar>
      <RightBar className={leftSidebar ? 'content open' : 'content'}>
        <Content
          hoa={items.find(({ recordId }) => recordId === selectedItemId)}
          onChangeHasChanges={setHasChanges}
          onDelete={onDelete}
          onUpdate={onUpdate}
          ItemsList={res => {
            items.unshift(res);
            // history.push(`${HOA_MASTER}/${items[0].recordId}${location.search}${location.hash}`);
          }}
          show={showCreateHoa}
          showFunction={res => setShowCreateHoa(res)}
          totalItems={totalResults}
          subdivisionMapping={subdivisionMapping}
          isStandaloneCreate={standaloneCreate}
        />
        {standaloneCreate ?
          <div class="wid_dep">
            <h4 className="hd_box">Create HOA</h4>
            <CreateHoa
              standalone
              onChangeHasChanges={setHasChanges}
              onUpdate={onUpdate}
              changeShow={res => handleCreateHoa(res)}
              hoaItemsList={res => {
                items.unshift(res)
              }}
            />
          </div> : ''}
      </RightBar>
    </Wrapper>
  );
}

export default withRouter(HoaMaster);
