import { Button, Icon } from '@material-ui/core';
import React, { useState } from 'react';
import OrderingInfoDeleteDialog from "./OrderingInfoDeleteDialog";

const OrderingInfoDeleteButton = ({ orderingInfoId, linkedRecordId, onDelete, type }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (isDeleted = false) => {
    if (isDeleted) {
      onDelete();
    }
    setOpen(false);
  };

  return (
    <div class="del">
      <Button color="secondary" onClick={handleClickOpen} variant="contained">
        <Icon>delete</Icon>
      </Button>
      <OrderingInfoDeleteDialog
        orderingInfoId={orderingInfoId}
        linkedRecordId={linkedRecordId}
        onClose={handleClose}
        open={open}
        type={type}
      />
    </div>
  );
}

export default OrderingInfoDeleteButton;
