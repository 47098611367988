import { Button, FormControl, TextField } from "@material-ui/core";
import SubDeleteButton from "../../../components/SubDeleteButton";
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { subdivision } from '../../../api'
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";
import { STATES, FIPS } from "../../../constants";



const { patch: patchSubdivision } = subdivision;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTextField = styled(TextField)`
  flex-basis: ${props => props.width}%
`;

const StyledFormControl = styled(FormControl)`
  flex-basis: ${props => props.width}%;
`;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

const STATE_UNKNOWN = 'NA';
const STATE_ANY = 'ANY';

const SubDetails = ({
  code,
  isValid,
  mgmtCompanyId,
  recordId,
  onChangeHasChanges,
  onDelete,
  onUpdate,
  ...props
}) => {
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [normalizedName, setnormalizedName] = useState('');
  const [fips, setFips] = useState('');
  const [county, setCounty] = useState('');
  const [state, setState] = useState('');
  const [hasChanges, setHasChanges] = useState(true);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    resetFromProps();
  }, [
    props.name,
    props.normalizedName,
    props.fips,
    props.state,
    recordId
  ]);

  useEffect(() => {
    const anythingIsChanged =
      (name !== props.name && (name !== '' || props.name !== null))
      || (fips !== props.fips && (fips !== '' || props.fips !== null))
      || (state !== props.state && (state !== '' || props.state !== null))
    setHasChanges(anythingIsChanged);
  }, [
    name,
    normalizedName,
    id,
    state,
    fips,
    props.name,
    props.normalizedName,
    recordId,
    props.state,
    props.fips,
  ]);

  useEffect(() => {
    onChangeHasChanges(hasChanges);
  }, [hasChanges]);

  useEffect(() => {
    const { county: foundCounty, state: foundState } = FIPS[fips] || { county: '', state: '' };
    setState(foundState);
    setCounty(foundCounty);
  }, [fips]);
  const resetFromProps = () => {
    setnormalizedName(props.normalizedName);
    setName(props.name);
    setId(recordId)
    setState(props.state || STATE_UNKNOWN);
    setFips(props.fips);
  }

  const onChangeName = event => {
    setName(event.target.value.toUpperCase());
  }

  const onChangeState = event => {
    setState(event.target.value);
    setFips('')
    if (event.target.value === 'NA') {
      setFips('99999')
    }
  }

  const handleFipsChange = event => {
    setFips(event.target.value);
  }

  // const CountyList = Object.keys(FIPS).map(function (key) {
  //   if (state === FIPS[key].state)
  //     return <MenuItem key={key} value={key}>{FIPS[key].county}</MenuItem>
  // });
  const onSubmit = async () => {
    setLoading(true);
    if (!fips && !state) {
      enqueueSnackbar(`Cannot update the Subdivision: County and State is required`, { variant: 'error' });
      setLoading(false);
      return;
    }
    if (!fips) {
      enqueueSnackbar(`Cannot update the Subdivision: County is required`, { variant: 'error' });
      setLoading(false);
      return;
    }
    try {
      const entity = await patchSubdivision(recordId, {
        fips,
        name,
      });
      onUpdate(entity);
      enqueueSnackbar('The Subdivision has been updated', { variant: 'success' });
    } catch (e) {
      const message = e.response.data.message;
      enqueueSnackbar(`Cannot update the Subdivision: ${message ?? 'Unknown error'}`, { variant: 'error' });
    }
    setLoading(false);
  }

  return <Wrapper>
    {loading && <LoadingOverlay />}
    <div class="set1_tab">
      <Row>
        <div class="wor mt-3">
          <div class="form_group lf">
            <StyledTextField width={85} size="small" label="Subdivision Name" value={name || ''} />
          </div>
          <div class="form_group rg">
            <StyledTextField width={85} size="small" label="Subdivision ID" value={id || ''} />
          </div>
        </div>
      </Row>
      <Row>
        <div class="wor mt-3">
          <div class="form_group lf">
            <StyledTextField width={85} size="small" label="Normalized Name" value={normalizedName || ''} />
          </div>
          <div class="form_group rg">
            <StyledTextField width={85} size="small" label="FIPS Code" value={fips || ''} />
          </div>
        </div>
      </Row>
      <Row>
        <div class="wor sec mt-3">
          <div class="form_group lf">
            <StyledTextField width={85} size="small" label="State" value={state || ''} />
          </div>
          <div class="form_group rg">
            <StyledTextField width={85} size="small" label="County" value={county || ''} />
          </div>
        </div>
      </Row>
      <Row>
        <div style={{ marginTop: 10, fontSize: 16 }} class="wor sec mt-3">
          <div class="form_group lf">
            <label>Has Hoa: {props.hasHoa ? 'True' : 'False'}</label>
          </div>
          <div class="form_group rg">
            <label>Has Fee: {props.hasFee ? 'True' : 'False'}</label>
          </div>
        </div>
      </Row>
      {/* <Row>
        <div class="btn_box rg">
          <Button variant="contained" color="primary" disabled={!hasChanges} onClick={onSubmit}>Save</Button>
          <Button color="secondary" disabled={!hasChanges} onClick={resetFromProps} class="can">Cancel</Button>
        </div>
      </Row> */}
    </div>
    <SubDeleteButton name={props.name} recordId={recordId} onDelete={onDelete} />
  </Wrapper>;
}

export default SubDetails;
