import { Button, Icon } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from 'react';
import styled from "styled-components";
import { hoa } from "../../api";
import LoadingOverlay from "../../components/Loading/LoadingOverlay";
import { HOA_CATEGORIES } from "../../constants";
import SelectorHoa from "../SelectorHoa";

const { patch } = hoa;

const FormWrapper = styled.div`
  display: flex;
`;

const StyledSelectorHoa = styled(SelectorHoa)`
  width: 100%;
`;

const Add = ({ onSuccess, recordId }) => {
  const [adding, setAdding] = useState(false);
  const [subRecordId, setSubRecordId] = useState(null);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const onClickAddButton = () => {
    setAdding(true);
  }

  const onClickCancel = () => {
    setAdding(false);
  }

  const onClickSave = async () => {
    setLoading(true);
    try {
      const subHoa = await patch(subRecordId, { category: HOA_CATEGORIES.SUB, masterId: recordId });
      enqueueSnackbar('The Sub HOA has been added', { variant: 'success' });
      onSuccess(subHoa);
      setSubRecordId(null);
      setAdding(false);
    } catch (e) {
      const message = e.response.data.message;
      enqueueSnackbar(`Cannot add Sub HOA: ${message ?? 'Unknown error'}`, { variant: 'error' });
    }
    setLoading(false);
  }

  if (!adding) {
    return <Button color="default" onClick={onClickAddButton}><Icon>add_circle</Icon>Add</Button>
  }

  return (
    <FormWrapper>
      {loading && <LoadingOverlay />}
      <StyledSelectorHoa onChange={setSubRecordId} value={subRecordId} type="Sub" label="HOA Name" />
      <Button color="primary" onClick={onClickSave}>Submit</Button>
      <Button color="secondary" onClick={onClickCancel}>Cancel</Button>
    </FormWrapper>
  );
}

export default Add;
